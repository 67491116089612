import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {duplicateRemover} from '../../actions/common/duplicateRemover';
import {toggleModal} from '../../actions/common/toggleModal';
import {getFavouriteTaskSets} from '../../actions/taskSet/fetchFavouriteTaskSets';
import {pickGlobalTaskSet} from '../../actions/common/pickGlobalTaskSet';
import {TaskSelectType, TaskType} from '../../declarations';
import {BottomBarRating} from "../../components/common/modal/bottomBarRating";
import PropTypes from "prop-types";
import {deleteEntity, post} from "../../apiService";

class TaskSetCard extends React.Component {
  constructor() {
    super();
    this.state = {
      selected: false
    };
  }

  favouriteClick = (e) => {
    if (this.props.favouriteTaskSets.includes(this.props.id)) {
      this.removeFavourite(this.props.id).then(() => this.props.getFavouriteTaskSets());
    }
    else {
      this.addToFavourites(this.props.id).then(() => this.props.getFavouriteTaskSets());
    }
    e.stopPropagation();
  };

  async removeFavourite(taskSet) {
    await deleteEntity('/taskset/favourite/' + taskSet);
  }

  async addToFavourites(taskSet) {
    await post('/taskset/favourite/' + taskSet)
  }

  previewClick = () => {
    this.context.router.history.push("/taskset/" + this.props.id);
  };

  /**
   * Adds or removes the taskset to global redux array of picked tasksets
   */
  taskSetClick = (e) => {
    e.stopPropagation();
    !this.props.isSelected(this.props.id, TaskType.TASKSET) ? this.props.pickGlobalTaskSet(this.props, TaskSelectType.ADD) : this.props.pickGlobalTaskSet(this.props, TaskSelectType.REMOVE);
    this.forceUpdate();
  };

  render() {
    return (
      <div>
        <div
          className={'task__container  ' + (this.props.isSelected(this.props.id, TaskType.TASKSET) ? 'task__container--selected' : '')}
          onClick={this.previewClick}
        >
          <i
            className={'task-right-corner--icon  fa  ' + (this.props.isSelected(this.props.id, TaskType.TASKSET) ? 'fa-check-circle' : 'fa-circle-thin')}
            onClick={this.taskSetClick}
          />
          <i className="fa fa-files-o  task-set-title--icon"/>
          <div className="task__container__content">
            <div className="task-set-title">
              <p className="strongText">{this.props.title}</p>
            </div>
            <div className="task-set-categories">
              <div className="task-set-count__container">
                <div className="task-count">
                  <i className="fa fa-file-o" id="taskCountIcon"/>
                  <p className="task-set-count-text">{this.props.tasks.length}</p>
                </div>
              </div>
              {
                duplicateRemover(this.props.tasks, 'topics').map((topic, i) => (
                  <div className="category__container" key={i}>
                    <p className="category-text">{topic}</p>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="bottom-taskbar">
          <span>
            <i
              className={'right-bottom--icon  fa  ' + (this.props.favouriteTaskSets.includes(this.props.id) ? 'fa-bookmark' : 'fa-bookmark-o')}
              onClick={this.favouriteClick}
            />
          </span>
            <BottomBarRating
              rating={this.props.taskSet.totalRating}
              amountOfRatings={this.props.taskSet.amountOfRatings}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleModal: toggleModal,
    pickGlobalTaskSet: pickGlobalTaskSet,
    getFavouriteTaskSets: getFavouriteTaskSets
  }, dispatch);

const mapStateToProps = state => {
  return {
    favouriteTaskSets: state.favouriteTaskSets
  };
};

TaskSetCard.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskSetCard);
