export const toggleReportModalOn = () => {
  return {
    type: "TOGGLE_REPORT_ON"
  }
};

export const toggleReportModalOff = () => {
  return {
    type: "TOGGLE_REPORT_OFF"
  }
};
