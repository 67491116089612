import html2canvas from "html2canvas";
import jsPDF from "jspdf";

/**
 * Creates a pdf for content within the supplied div's
 *
 * @param divArray: string[]
 */
export const exportTaskToPdf = (divArray) => {
  createPdf(divArray);

};

function createPdf(divArray) {
  const parentElement = document.getElementById('task-set-pdf');
  parentElement.style.display = 'block';
  let pdf = new jsPDF();
  const element = document.getElementById(divArray[0]);
  html2canvas(element, {scale: 1, logger: false}).then((firstPageCanvas) => {
    const imgData = firstPageCanvas.toDataURL('image/png');
    const imgWidth = 170;
    const pageHeight = 295;

    const taskImgHeight = firstPageCanvas.height * imgWidth / firstPageCanvas.width;
    let taskHeightLeft = taskImgHeight;
    let position = 20;
    pdf.addImage(imgData, 'PNG', position, position, imgWidth, 0);
    taskHeightLeft -= pageHeight;

    while (taskHeightLeft >= 0) {
      position = taskHeightLeft - taskImgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 20, position, imgWidth, taskImgHeight);
      taskHeightLeft -= pageHeight;
    }

    for (let i = 1; i < divArray.length; i++) {
      let el = document.getElementById(divArray[i]);
      html2canvas(el, {scale: 1}).then((additionalPagesCanvas) => {
        const secondPageImageData = additionalPagesCanvas.toDataURL('image/png');
        const secondPageImageHeight = additionalPagesCanvas.height * imgWidth / additionalPagesCanvas.width;
        let secondPagePosition = 20;
        let secondPageHeightLeft = secondPageImageHeight;
        pdf.addPage();
        pdf.addImage(secondPageImageData, 'PNG', secondPagePosition, secondPagePosition, imgWidth, 0);
        secondPageHeightLeft -= pageHeight;
        while (secondPageHeightLeft >= 0) {
          secondPagePosition = secondPageHeightLeft - secondPageImageHeight;
          pdf.addPage();
          pdf.addImage(secondPageImageData, 'PNG', 20, secondPagePosition, imgWidth, secondPageImageHeight);
          secondPageHeightLeft -= pageHeight;
        }
        // Needs to be here to be able to chain multiple pages from different <div> tags
        if (i === divArray.length - 1) {
          pdf.save("eMatteTask.pdf");
          parentElement.style.display = 'none'
        }
      });
    }
    // In case there is only one div supplied in the array
    if (divArray.length === 1) {
      pdf.save("eMatteTask.pdf");
      parentElement.style.display = 'none'
    }
  });
}
