import {get} from "../../apiService";


/**
 * Fetches all topics from API and dispatches data to redux store
 * */
export const getAllTopics = () => {
  return (dispatch, getState) => {
    return get('/topic', getState().authentication.access_token).then((json) => {
      dispatch(fetchAllTopicsSuccess(json))
    });
  };
};

const fetchAllTopicsSuccess = (payload) => {
  return {
    type: 'FETCH_TOPICS_SUCCESS',
    payload
  };
};
