import React from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from "react-confirm-alert";
import { namespace } from "../../util/namespaceNO";

const loggedOut = (props) =>
  confirmAlert({
                 title : 'Logget ut',
                 message : namespace.loggedOut,
                 cancelLabel : '',
               });

loggedOut.propTypes = {};
loggedOut.defaultProps = {};

export default loggedOut;
