import React from 'react';
import './inputFieldWithTitle.css';

export default class InputFieldWithTitleAndDefaultValue extends React.Component {

  componentDidMount() {
    this.handleChange(this.props.defaultValue);
  }

  componentDidUpdate() {
    this.handleChange(this.props.defaultValue);
  }

  handleChange = (value) => {
    this.props.input.onChange(value);
    this.props.callback(value)
  };

  render() {
    return (
      <div>
        <div>
          <label className="title-input-label  title-input-label--modal">{this.props.label}</label>
          {
            <span className="error-message-label">{this.props.meta.error}</span>
          }
        </div>
        <div>
          <input
            {...this.props.input}
            type={this.props.type}
            className="text-input  text-input--title"
            autoComplete={'off'}
            autoFocus={this.props.autoFocus}
            onChange={e => this.handleChange(e.target.value)}
          />
        </div>
      </div>
    )
  }
};


