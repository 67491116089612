import React from 'react';
import { Field } from 'redux-form';

const renderInput = (props) => (
  <div className="checkbox  checkbox-modal  checkbox-modal--type">
    <label className="checkbox--label">
      <span>{props.label}</span>
      <Field
        name={props.input.name}
        type="checkbox"
        component="input"
      />
    </label>
  </div>
);

const pickType = (props) => (
  <div>
    <label>{props.label}</label>
    <div className="type-input__container">
      {props.types.map((type, i) =>
      <div key={i}>
        <Field
          name={`types.${type.type}`}
          component={renderInput}
          label={type.type}
        />
      </div>
    )}
    {
      props.meta.error && props.meta.submitFailed &&
      <span className="error-message-label">{props.meta.error}
        </span>
    }</div>
  </div>
);

export default pickType;
