import * as React from 'react';
import TaskCard from '../../../containers/task/taskCard';
import TaskSetCard from '../../../containers/taskSet/taskSetCard';
import {TaskType} from '../../../declarations';
import {TaskListEmpty} from './taskListEmpty';
import Spinner from 'react-spinkit';

export const TaskList = (props) => {
  const {publishedTasks, publishedTaskSets, tasks, loaded, isTaskPicker, maxItems, showMore, isSelected} = props;

  const renderList = [];

  tasks.forEach((item, i) => {
    const task = publishedTasks.find(task => task.id === item.id);
    const taskSet = publishedTaskSets ? publishedTaskSets.find(taskSet => taskSet.id === item.id) : null;

    if (i < maxItems) {
      renderList.push(
        <div className="task-list__element" key={i}>
          <div key={item.id}>
            {
              item.type === TaskType.TASK &&
              <TaskCard
                {...item}
                isTaskPicker={props.isTaskPicker}
                isSelected={isSelected}
                task={task}
              />
            }
            {
              item.type === TaskType.TASKSET &&
              <TaskSetCard
                {...item}
                isSelected={isSelected}
                taskSet={taskSet}/>
            }
          </div>
        </div>
      );
    }
  });

  return (
    <div>
      {
        !loaded &&
        <div className="task-list-loader">
          <Spinner
            name="circle"
            fadeIn="none"
          />
        </div>
      }
      {
        tasks.length < 1 && loaded &&
        <div className={isTaskPicker ? '' : 'empty-box__wrapper'}>
          <TaskListEmpty
            message={isTaskPicker ? 'Prøv å endre søket ditt ved å justere på filterne over eller søke på noe annet.' : 'Prøv å endre søket ditt ved å ' +
              'justere på filterne til venstre eller søke på noe annet.'}/>
        </div>
      }
      <div className="task-list">
        {renderList}
      </div>
      {
        tasks.length > maxItems &&
        <div className="show-more-button__container" onClick={showMore}>
            <span className="show-more-button">
              Vis flere
            </span>
        </div>
      }
    </div>
  );
};


