import React, {Component} from 'react';
import './options.css'
import {exit} from "../common/alerts/exit";
import {deleteEntity} from "../../apiService";
import PropTypes from 'prop-types'
import {namespace} from "../../util/namespaceNO";

export default class OptionsList extends Component {

  reportTaskConfirmation = () => {
    this.props.props.showReportModal()
  };

  deleteTaskConfirmation = () => {
    exit(this.props.props.confirmationMessage, this.deleteTask, () => {
    });
  };

  deleteTask = () => {
    deleteEntity(this.props.props.deleteUrl)
      .then(() => this.context.router.history.push('/home'));
  };

  shouldDisplayDeleteButton = () => {
    return this.props.props.userAdmin ||
      (this.props.props.taskStatusEnum !==
        'IN_USE' && this.props.props.createdBy === this.props.props.loggedInUserId)
  };

  render() {
    return (
      <div className="modal-options__container">
        <div>
          <ul className="options-list">
            <li onClick={(e) => {
              this.reportTaskConfirmation();
              e.stopPropagation()
            }}>
              {namespace.report}
            </li>
            <li className={this.shouldDisplayDeleteButton() ? '' : 'hidden'}
                onClick={(e) => {
                  this.deleteTaskConfirmation();
                  this.props.props.hideMoreOptionModal();
                  e.stopPropagation();
                }}>
              {namespace.delete}
            </li>

          </ul>
        </div>
      </div>
    )
  }
};

OptionsList.contextTypes = {
  router: PropTypes.object.isRequired
};
