import * as React from 'react';
import ProfileBar from '../../containers/profileInfo/profileBar';

const Header = () => (
  <header className="header  not-selectable">
    <ProfileBar />
    <span style={{ position : 'absolute', right : '80px', top : '9px' }}>
      <div className="skolon-menu-button"  />
    </span>
    <div className="header__title">
      <div className="bigHeader">
        <h1>eMatte</h1>
      </div>
    </div>
  </header>
);

export default Header;
