import { TaskSelectType } from '../../declarations';

/**
 * Adds, removes or reset array of taskset in redux store
 *
 * @param taskSet
 * @param type
 */
export const pickGlobalTaskSet = (taskSet, type) => {
  if (type === TaskSelectType.ADD) {
    return (dispatch) => {
      dispatch(addTaskSet(taskSet));
    };
  }
  else if (type === TaskSelectType.RESET) {
    return (dispatch) => {
      dispatch(resetTaskSet());
    };
  }
  else if (type === TaskSelectType.REMOVE) {
    return (dispatch) => {
      dispatch(removeTaskSet(taskSet));
    };
  }
};

const addTaskSet = (payload) => {
  return {
    type: 'ADD_GLOBAL_TASK_SET',
    payload
  };
};

const removeTaskSet = (payload) => {
  return {
    type: 'REMOVE_GLOBAL_TASK_SET',
    payload
  };
};

const resetTaskSet = () => {
  return {
    type: 'RESET_GLOBAL_TASK_SET'
  };
};
