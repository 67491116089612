import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const favourited = (message, onConfirm) => {

  confirmAlert({
    title: message,
    message: "",
    confirmLabel: 'OK',
    cancelLabel: '',
    onConfirm: onConfirm,
  });
  document.getElementById("react-confirm-alert").classList.add("react-confirm-alert--favouritted");
};
