const userTasksReducer = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_USER_TASKS_SUCCESS':
      return action.payload;
    default:
      return state;
  }
};

export default userTasksReducer;
