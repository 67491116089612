import fetch from "isomorphic-fetch";
import { getUri } from "./oauthConfig";

const ResponseStatus = Object.freeze({ 'UNAUTHORIZED' : 401 });
const ResponseStatusForbidden = Object.freeze({ 'FORBIDDEN' : 403 });

const runRequest = (url, httpMethod, config) => {
  const endpoint = '/api' + url;
  return fetch(endpoint,
               {
                 method : httpMethod,
                 credentials : 'include',
                 ...config
               }).then(handleResponse)
};

const fetchJson = (url, token) => {
  const endpoint = '/api' + url;
  return fetch(endpoint, {
    method : 'GET',
    credentials : 'include',
    headers : new Headers({
                            'Authorization' : 'Bearer ' + token,
                            'Content-Type' : 'application/x-www-form-urlencoded'
                          })
  }).then(handleResponse)
};

const sendJson = (url, httpMethod, body) =>
  runRequest(url, httpMethod, {
    body : JSON.stringify(body),
    headers : {
      'Content-Type' : 'application/json',
    }
  });

export const get = (url, token = '') => fetchJson(url, token);

export const put = (url, body) => sendJson(url, 'PUT', body);

export const post = (url, body) => sendJson(url, 'POST', body);

export const deleteEntity = (url) => runRequest(url, 'DELETE');

export const postFormData = (url, body) => runRequest(url, 'POST', {
  body : body
});

// Handle HTTP errors since fetch won't.
function handleResponse(response) {
  if (response.status === ResponseStatus.UNAUTHORIZED || response.status === ResponseStatusForbidden.FORBIDDEN) {
    redirectToLogin(response)
  }

  if (!response.ok) {
    throw Error(response.statusText);
  }

  if (checkIfJson(response)) {
    return response.json();
  }

  return response;
}

const redirectToLogin = (response) => {
  window.location = window.location.origin + '/login/page'
};

const checkIfJson = (response) => {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return true;
  }
};
