import React from 'react';
import { Field } from 'redux-form';

const pickDifficulty = (props) => (
  <div>
    <div className="difficulty--checkbox">
      <div className="checkbox  checkbox-modal  checkbox-modal--difficulty">
        <label className="checkbox--label">
          <Field
            name={props.input.name}
            type="radio"
            value="1"
            component="input"
          />
          1
        </label>
      </div>
      <div className="checkbox  checkbox-modal  checkbox-modal--difficulty">
        <label className="checkbox--label">
          <Field
            name={props.input.name}
            type="radio"
            value="2"
            component="input"
          />
          2
        </label>
      </div>
      <div className="checkbox  checkbox-modal  checkbox-modal--difficulty">
        <label className="checkbox--label">
          <Field
            name={props.input.name}
            type="radio"
            value="3"
            component="input"
          />
          3
        </label>
      </div>
    </div>
    {
      props.meta.touched && props.meta.submitFailed &&
      <span className="error-message-label">{props.meta.error}</span>
    }
  </div>
);

export default pickDifficulty;
