/**
 * Validate the from data and returns an array of error messages to be rendered
 *
 * @param values
 * @return Array
 */
export const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Obligatorisk';
  }
  else if (values.title.length > 100) {
    errors.title = 'Tittelen kan ikke være lenger enn 100 tegn';
  }

  if (!values.description) {
    errors.description = 'Obligatorisk';
  }
  else if (values.description.length > 1000) {
    errors.description = 'Beskrivelsen kan ikke være lenger enn 1000 tegn';
  }

  if (!values.grade) {
    errors.grade = 'Obligatorisk';
  }

  if (!values.taskSet || values.taskSet === 'Velg oppgavesett') {
    errors.taskSet = 'Du må velge et oppgavesett';
  }
  return errors;
};
