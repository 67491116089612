import * as React from 'react';
import AddComment from "./addComment";
import {Comment} from "./comment";

export const Comments = (props) => {

  const {
    comments,
    addComment
  } = props;

  const sortedComments = comments.sort((a,b) => b.createdDate - a.createdDate);

  return (
    <div className="feedback_container">
      <AddComment
        addComment={addComment}
      />
      <Comment
        comments={sortedComments}
      />
    </div>
  );
};