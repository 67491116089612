import React, {Component} from 'react';
import './newTaskSetFirstPage.css';
import RadioButton from "../../components/common/renderFields/radioButton/radioButton";
import {namespace} from "../../util/namespaceNO";
import {exportTaskToPdf} from "../../actions/task/exportTaskToPdf";
import {TaskSetPdf} from "../../components/pdfgeneration/taskSetPdf";
import {post} from "../../apiService";
import PropTypes from "prop-types";
import {ModalType} from "../../components/common/modal";
import {reduxForm} from "redux-form";
import validate from "./validate";

class NewTaskSetSecondPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showSolution: true,
      title: this.props.taskSetDto.title,
      description: ''
    };
  }

  updateTitle = (title) => {
    this.setState({title: title});
  };

  updateDescription = (description) => {
    this.setState({description: description});
  };

  updateShowSolution = () => {
    this.setState({showSolution: !this.state.showSolution});
  };

  publishTaskSet = () => {
    const taskSetDTO = this.props.taskSetDto;
    taskSetDTO.title = this.state.title;
    taskSetDTO.description = this.state.description;
    post("/taskset/withtasks", taskSetDTO)
      .then((response) => this.context.router.history.push('/taskset/' + response.id));
    this.props.toggleModal('is-active', ModalType.GENERATETASKSET);
  };

  render() {
    const pdfElements = this.state.showSolution === true ? ['task-set-tasks', 'task-set-solution'] : ['task-set-tasks'];
    const radioButtonValues = [
      {name: "Uten fasit", displayName: namespace.withoutSolution},
      {name: "Inkluder fasit", displayName: namespace.includeSolution}
    ];

    return (
      <div className="new-task-set-preview">
        <h1 className="task-set-header">Forhåndsvisning</h1>
        <div className="task-set-container">
          <input className="task-set-title-container"
                 defaultValue={this.state.title}
                 onChange={event => this.updateTitle(event.target.value)}/>
          <textarea className="task-set-preview-description"
                    placeholder={namespace.textAreaPlaceholder}
                    onChange={event => this.updateDescription(event.target.value)}/>
          <div className="tasks-in-taskset-container">
            {this.props.taskSetDto.tasks.map((task, index) => (
              <div key={"container" + index} className="task-container">
                <h1 key={'task-container' + index}>
                  {(index + 1) + ") " + task.description +  ' = ' + (!this.state.showSolution ? '' : task.solution)}
                </h1>
              </div>
            ))}
          </div>
          <div className="tasks-preview-options-container">
            <RadioButton
              values={radioButtonValues}
              minWidth={200}
              callback={this.updateShowSolution}
            />
            <button className="more-options-button"
                    onClick={() => this.props.generateTaskSet()}>{namespace.generateAgain}
            </button>
          </div>
        </div>
        <div className="tasks-preview-options-container">
          <button className="back-button" onClick={() => this.props.previousPage()}>{namespace.previous}</button>
          <div className="tasks-preview-options-container">
            <button className="more-options-button" onClick={() => exportTaskToPdf(pdfElements)}>
              {namespace.saveAsPdf}
            </button>
            <button className="generate-button-button" onClick={this.publishTaskSet}>{namespace.publish}</button>
          </div>
        </div>
        <TaskSetPdf
          taskSetDto={this.props.taskSetDto}
          showSolution={this.state.showSolution}
          title={this.state.title}
          description={this.state.description}
        />
      </div>
    )
  }
}

export default reduxForm({
  form: 'generateTaskSet',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(NewTaskSetSecondPage)

NewTaskSetSecondPage.contextTypes = {
  router: PropTypes.object.isRequired
};
