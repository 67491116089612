/**
 * Toggles which modal that should be active
 *
 * @param className
 * @param type
 */
export const toggleModal = (className, type) => {

  let payload = {
    modal: type,
    class: className
  };
  if(payload.class === "is-active") {
    payload.class = "";
    return (dispatch) => {
      dispatch(hideModal(payload));
    };
  }
  else {
    return (dispatch) => {
      payload.class = "is-active";
      dispatch(showModal(payload));
    };
  }
};

const showModal = (payload) => {
  return {
    type: "TOGGLE_MODAL_OFF",
    payload
  }
};

const hideModal = (payload) => {
  return {
    type:"TOGGLE_MODAL_ON",
    payload
  }
};