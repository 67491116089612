import React from 'react';
import DropdownWithoutBorder from "../dropDownMenu/dropdownMenuWithoutBorder";
import FilterDifficulty from './common/filterDifficulty';
import FilterType from './common/filterType';
import FilterTopic from './common/filterTopic';
import {namespace} from "../../../util/namespaceNO";

const FilterModal = (props) => {
  const {
    hiddenFilters,
    filter,
    onChange,
    toggleDifficultyFilter,
    toggleTypeFilter,
    toggleTopicFilter,
    handleDifficultyChange,
    types,
    topics,
    grades
  } = props;

  return (
    <div>
      <div className={'custom-dropdown-menu-wrapper ' +
      (hiddenFilters ? 'custom-dropdown-menu-wrapper--active' : '')}>
        <div className={'custom-dropdown-menu ' +
        (hiddenFilters ? 'custom-dropdown-menu--active' : '')}>
          <div className="filter-entity  columns">
            <div className="filter-entity-row  filter-entity-row--modal-select  column  is-one-fifth">
              <DropdownWithoutBorder
                dropdownTitle={namespace.filterGrade}
                list={grades}
                onChange={onChange}
                filter={filter}
              />
            </div>
            <div className="filter-entity-row  column  is-four-fifths">
              <FilterDifficulty
                label='Vanskelighetsgrad'
                filter={toggleDifficultyFilter}
                onChange={handleDifficultyChange}
              />
            </div>
          </div>
          <div className="filter-entity-row  filter-entity-row--modal">
            <FilterType
              label='Type oppgaver'
              types={types}
              filter={toggleTypeFilter}
            />
          </div>
          <div className="filter-entity-row  filter-entity-row--modal">
            <FilterTopic
              topics={topics}
              filter={toggleTopicFilter}
              label="Emner"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
