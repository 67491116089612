import React from 'react';
import './radioButton.css';
import {Option} from './option';

export default class RadioButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: null,
      selectedValue: null,
    };
  }

  componentDidMount() {
    const minWidth = this.props.minWidth;
    let radioButtonElement = document.getElementsByClassName('minWidth' + this.props.minWidth);

    radioButtonElement = Array.prototype.slice.call(radioButtonElement);
    radioButtonElement.forEach(function (element) {
      element.style.minWidth = minWidth + 'px';
    });
  }

  handleChange = (index) => {
    this.setState({
        selectedIndex: index,
        selectedValue: this.props.values[index].name
      }, this.callback(index)
    );
  };

  callback = (index) => {
    if (this.props.input != null) {
      this.props.input.onChange(this.props.values[index].name)
    } else if (this.props.callback != null) {
      this.props.callback();
    }
  };

  render() {
    return (
      <div className="options">
        {
          this.props.values.map((value, i) =>
            <Option
              key={value.name}
              handleChange={this.handleChange}
              name={value.name}
              displayName={value.displayName}
              isChecked={(this.state.selectedIndex === i)}
              index={i}
              minWidth={this.props.minWidth}
            />
          )
        }
      </div>
    )
  }
}

