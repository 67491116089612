import React from "react";
import Dropdown from "../common/dropDownMenu/dropDownMenu";
import {GRADE} from "../../util/enums/grade";
import {Field} from "redux-form";
import {namespace} from "../../util/namespaceNO";
import "./generateTaskSet.css"
import ToggleButton from "../common/renderFields/toggleButton/toggleButton";
import {TaskFormatPreview} from "../taskFormatPreview/taskFormatPreview";
import TaskAmount from "../../containers/newTaskSet/taskAmount/taskAmount";
import TaskFormat from "../taskFormat/taskFormat";
import InputFieldWithTitleAndDefaultValue
  from "../common/renderFields/inputFieldWithTitle/inputFieldWithTitleAndDefaultValue";
import {GenerateButton} from "../common/renderFields/generateButton/generateButton";

export class GenerateTaskSet extends React.Component {

  constructor(props) {
    super(props);
    const grades = Object.values(GRADE);
    const defaultGrade = grades[0];
    const defaultDecimals = defaultGrade.decimals();
    const subjects = defaultGrade.subjects();
    const defaultSubject = subjects[0];
    const defaultFormat = defaultSubject.formats();
    const defaultMinimumDigit = defaultGrade.minimumDigit();
    const defaultMaximumDigit = defaultGrade.maximumDigit();
    this.state = {
      grades: grades,
      subjects: subjects,
      decimals: defaultDecimals,
      selectedDecimals: [defaultDecimals[0]],
      mainSubject: defaultSubject,
      additionalSubjects: this.findAdditionalSubjects(subjects, defaultSubject),
      numberOfFormatFields: defaultFormat,
      selectedFormat: [defaultFormat[0]],
      minimumDigit: defaultMinimumDigit,
      maximumDigit: defaultMaximumDigit,
      selectedAdditionalSubjects: [],
      selectedGrades: defaultGrade.grades()
    };
  }

  findAdditionalSubjects(subjects, chosenSubject) {
    return subjects.filter(subject => subject.key() !== chosenSubject.key());
  }

  selectGrades = (selectedGrades) => {
    this.setState({
        subjects: selectedGrades.subjects(),
        decimals: selectedGrades.decimals(),
        selectedGrades: selectedGrades.grades()
      }, () => this.selectMainSubject(selectedGrades.subjects()[0]),
      this.updateMaximumDigit(selectedGrades.maximumDigit()),
      this.updateMinimumDigit(selectedGrades.minimumDigit()));
  };

  selectMainSubject = (mainSubject) => {
    this.setState({mainSubject: mainSubject, numberOfFormatFields: mainSubject.formats()},
      () => this.updateAdditionalSubjects());
  };

  updateAdditionalSubjects = () => {
    this.setState({additionalSubjects: this.findAdditionalSubjects(this.state.subjects, this.state.mainSubject)});
  };

  updateTaskFormat = (selectedFormat) => {
    this.setState({selectedFormat: selectedFormat});
  };

  updateMinimumDigit = (digit) => {
    this.setState({minimumDigit: digit});
  };

  updateMaximumDigit = (digit) => {
    this.setState({maximumDigit: digit});
  };

  updateDecimals = (selectedDecimals) => {
    this.setState({selectedDecimals: selectedDecimals});
  };

  updateAdditionalSubjectsSelected = (selectedAdditionalSubjects) => {
    this.setState({selectedAdditionalSubjects: selectedAdditionalSubjects});
  };

  handleGenerateClick = () => {
    this.props.generateTaskSetDTO(this.state);
  };

  render() {
    return (
      <div className="generate-task-set-container">
        <div className="new-task-set-dropdowns">
          <div className="new-task-set-dropdown-menu">
            <Field
              name="gradesDropdown"
              component={Dropdown}
              dropdownTitle={this.state.grades[0].description()}
              list={this.state.grades}
              header={namespace.grade}
              setGrades={this.isGradeSelected}
              callback={this.selectGrades}
            />
          </div>
          <div className="new-task-set-dropdown-menu">
            <Field
              name="subjectDropdown"
              component={Dropdown}
              dropdownTitle={this.state.subjects[0].description()}
              header={namespace.subSubject}
              list={this.state.subjects}
              callback={this.selectMainSubject}
            />
          </div>
        </div>
        <div>
          <div className="task-format-outer-container">
            <Field
              name="taskFormat"
              component={TaskFormat}
              numberOfFormatFields={this.state.numberOfFormatFields}
              callback={this.updateTaskFormat}
            />
          </div>
        </div>
        <div className="generator-criteria-container">
          <Field
            name="minimumDigit"
            type="number"
            component={InputFieldWithTitleAndDefaultValue}
            label={namespace.minValue}
            defaultValue={this.state.minimumDigit}
            placeholder={namespace.numberPlaceholder}
            callback={this.updateMinimumDigit}
          />
          <Field
            name="maximumDigit"
            type="number"
            component={InputFieldWithTitleAndDefaultValue}
            label={namespace.maxValue}
            defaultValue={this.state.maximumDigit}
            placeholder={namespace.numberPlaceholder}
            callback={this.updateMaximumDigit}
          />
          <Field
            name="selectedDecimals"
            component={ToggleButton}
            {...this.props.input}
            values={this.state.decimals}
            minWidth={50}
            title={namespace.numberOfDecimals}
            selectDefault={true}
            callback={this.updateDecimals}
          />
          <Field
            name="additionalSubjects"
            component={ToggleButton}
            values={this.state.additionalSubjects}
            minWidth={50}
            title={namespace.additionalSubjects}
            selectDefault={true}
            callback={this.updateAdditionalSubjectsSelected}
          />
        </div>
        <TaskFormatPreview
          parentState={this.state}
        />
        <div className="task-amount-container">
          <TaskAmount/>
        </div>
        <GenerateButton
          invalid={this.props.props.invalid}
          handleGenerateClick={this.handleGenerateClick}/>
      </div>
    )
  }
}
