import * as React from 'react';
import {connect} from 'react-redux';
import SearchBar from '../../components/common/searchbar/searchBar';
import {toggleModal} from '../../actions/common/toggleModal';
import {bindActionCreators} from 'redux';
import {ModalType} from '../../components/common/modal';
import {AddToMenu, NewMenu} from '../../components/topBar/menus';
import {SortDropdown} from '../../components/topBar/sortDropdown';
import {favourited} from '../../components/common/alerts/favourited';
import {pickGlobalTask} from '../../actions/common/pickGlobalTask';
import {pickGlobalTaskSet} from '../../actions/common/pickGlobalTaskSet';
import {TaskSelectType} from '../../declarations';
import {getFavouriteTasks} from '../../actions/task/fetchFavouriteTasks';
import {getFavouriteTaskSets} from '../../actions/taskSet/fetchFavouriteTaskSets';
import {post} from "../../apiService";

class Topbar extends React.Component {
  constructor () {
    super();
    this.state = {
      hiddenAdd: true,
      hiddenNew: true,
      hiddenSort: true
    };
    this.openNewTask = this.openNewTask.bind(this);
    this.openNewTaskSet = this.openNewTaskSet.bind(this);
    this.openAddTask = this.openAddTask.bind(this);
  };

  /**
   * OnClick on the 'add-to-button'. Sets state to show or hide the 'add to' dropdown based on the state.
   * Also hides the 'new' dropdown if it is shown
   * Adds event listener to hide the dropdown if you click outside it
   *
   */
  handleAddButtonClick = () => {
    const {hiddenAdd} = this.state;

    this.setState({hiddenAdd: !hiddenAdd, hiddenNew: true});
    if (hiddenAdd) {
      document.addEventListener('click', () => {
        this.setState({hiddenAdd: true});
      }, {once: true});
    }
  };

  /**
   * OnClick on the 'new-button'. Sets state to show or hide the 'new' dropdown based on the state
   * Also hides the 'add-to' drop down if it is shown
   * Adds event listener to hide the dropdown if you click outside it
   *
   */
  handleNewButtonClick = () => {
    const {hiddenNew} = this.state;

    this.setState({hiddenNew: !hiddenNew, hiddenAdd: true});
    if (hiddenNew) {
      document.addEventListener('click', () => {
        this.setState({hiddenNew: true});
      }, {once: true});
    }

  };

  /**
   * OnClick on the 'sort' button. Sets state to show or hide the 'sort' dropdown based on the state
   * Adds event listener to hide the dropdown if you click outside it
   */
  handleSortDropdownClick = () => {
    const {hiddenSort} = this.state;

    this.setState({hiddenSort: !hiddenSort});
    if (hiddenSort) {
      document.addEventListener('click', () => {
        this.setState({hiddenSort: true});
      }, {once: true});
    }
  };

  /**
   * OnClick on 'add to favourites button'. Checks redux store if you have selected any task or task sets, and
   * creates an array of their id's.
   * Calls async method addingToFavourites. Resets picked task and task sets in redux store
   *
   */
  addToFavourites = () => {
    let isFavourited = false;

    if (this.props.pickedGlobalTasks.length !== 0) {
      this.props.pickedGlobalTasks.forEach((task, i) => {
        this.props.pickedGlobalTasks[i] = task.id;
      });
      isFavourited = true;
    }

    if (this.props.pickedGlobalTaskSets.length !== 0) {
      this.props.pickedGlobalTaskSets.forEach((taskSet, i) => {
        this.props.pickedGlobalTaskSets[i] = taskSet.id;
      });
      isFavourited = true;
    }

    isFavourited ? favourited('Lagt til i dine bokmerker') : favourited('Ingenting er valgt');
    this.addingToFavourites(this.props.pickedGlobalTasks, this.props.pickedGlobalTaskSets).then(() => {
      this.props.getFavouriteTasks();
      this.props.getFavouriteTaskSets();
    });
    this.props.pickGlobalTaskSet(null, TaskSelectType.RESET);
    this.props.pickGlobalTask(null, TaskSelectType.RESET);
  };

  /**
   * Async method that calls actions that calls the REST api, that sends tasks and task sets to the backend to
   * be favourited
   *
   * @param tasks:  Arrays of id's of the tasks
   * @param taskSets  Arrays of id's of the task sets
   */
  async addingToFavourites (tasks, taskSets) {
    await post("/task/favourite/tasks", tasks);
    await post("/taskset/favourite/tasksets", taskSets);
  }

  /**
   * Opens modal for new task
   */
  openNewTask () {
    this.props.toggleModal('', ModalType.NEWTASK);
  }

  /**
   * Opens modal for new task set
   */
  openNewTaskSet () {
    this.props.toggleModal('', ModalType.NEWTASKSET);
  }

  openGenerateTaskSet = () => {
    this.props.toggleModal('', ModalType.GENERATETASKSET)
  };

  /**
   * Opens modal for adding task to task set
   */
  openAddTask () {
    this.props.toggleModal('', ModalType.ADDTASK);
  }

  render () {
    return (
      <section>
        <div className="columns  top-bar">
          <div className="column  is-4">
            <div className="top-bar-button  add-to-button" onClick={this.handleAddButtonClick}>
              <img src={require('../../resources/customIcons/addToArrow.svg')} alt="icon"
                   className="add-to-button--icon"/>
              <span className="add-button-text">Legg til i...</span>
              <div className={'custom-dropdown-menu-wrapper ' +
              (this.state.hiddenAdd ? '' : 'custom-dropdown-menu-wrapper--active')}>
                <AddToMenu
                  hidden={this.state.hiddenAdd}
                  openNewTaskSet={this.openAddTask}
                  addToFavourites={this.addToFavourites}
                />
              </div>
            </div>
            <div className="top-bar-button top-bar-button--new" onClick={this.handleNewButtonClick}>
              <img src={require('../../resources/customIcons/Artboard Copy 8.svg')} alt="icon"
                   className="new-button--icon"/>
              <span className="new-button-text">Ny...</span>
              <div className={'custom-dropdown-menu-wrapper ' +
              (this.state.hiddenNew ? '' : 'custom-dropdown-menu-wrapper--active')}>
                <NewMenu
                  hidden={this.state.hiddenNew}
                  openNewTask={this.openNewTask}
                  openNewTaskSet={this.openNewTaskSet}
                  openGenerateTaskSet={this.openGenerateTaskSet}
                />
              </div>
            </div>
          </div>
          <div className="column is-8">
            <div className="top-bar-search">
              <SearchBar searchChange={this.props.searchChange}/>
            </div>
          </div>
          <div className="sort-dropdown--top-bar">
            <label
              className={'dropdown-button  dropdown-button--sort  ' + (this.state.hiddenSort ? 'dropdown-button--up' : 'dropdown-button--down')}
              onClick={this.handleSortDropdownClick}>{this.props.sorting}
            </label>
            <div className={'custom-dropdown-menu-wrapper ' +
            (this.state.hiddenSort ? '' : 'custom-dropdown-menu-wrapper--active')}>
              <SortDropdown
                hidden={this.state.hiddenSort}
                onClick={this.props.handleSortingChange}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleModal: toggleModal,
    pickGlobalTask: pickGlobalTask,
    pickGlobalTaskSet: pickGlobalTaskSet,
    getFavouriteTasks: getFavouriteTasks,
    getFavouriteTaskSets: getFavouriteTaskSets
  }, dispatch);

const mapStateToProps = state => {
  return {
    pickedGlobalTasks: state.pickedGlobalTasks,
    pickedGlobalTaskSets: state.pickedGlobalTaskSets
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
