import React from 'react';
import './pictureForm.css'

export const PictureForm = (props) => {

  const {handleUpload, inputId} = props;
  return (
    <div>
      <input type="file"
             className="is-hidden"
             onChange={handleUpload}
             id={inputId}
      />
      <label htmlFor={inputId} className="fileInputLabel">Legg til bilde</label>
    </div>
  )
};