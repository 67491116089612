import * as React from 'react';

export const Comment = (props) => {
  const {comments} = props;

  if (comments.length === 0) {
    return (
      <div className="modal-row">
        Ingen kommentarer
      </div>
    );
  }

  return (
    <div>
      {comments.map((comment, i) =>
        <div className="modal-row" key={i}>
          <div className="modal-comment">
            <span className="lightText">{comment.username}: </span>
            <span> {comment.comment}</span>
          </div>
          <div className="has-text-right lightText">
            {new Date(comment.createdDate).toLocaleDateString("nb-NO")}
          </div>
        </div>
      )}
    </div>
  );
};
