import * as React from 'react';
import {connect} from 'react-redux';
import {toggleModal} from '../../actions/common/toggleModal';
import {bindActionCreators} from 'redux';
import {gradeCheck} from '../../actions/common/gradeCheck';
import {TaskHover} from '../../components/task/taskHover';
import {pickTask} from '../../actions/common/pickTask';
import {TaskSelectType, TaskType} from '../../declarations';
import {pickSelectedTask} from '../../actions/common/pickSelectedTask';
import {pickGlobalTask} from '../../actions/common/pickGlobalTask';
import {BottomBarRating} from "../../components/common/modal/bottomBarRating";
import {getFavouriteTasks} from "../../actions/task/fetchFavouriteTasks";
import PropTypes from "prop-types";
import {deleteEntity, post} from "../../apiService";

class TaskCard extends React.Component {

  constructor() {
    super();
    this.state = {
      hoverActive: false,
      hoverStyle: {top: 0, left: 0}
    };
  }

  /**
   * Removes a task from favourite if it is favoured and adds it if not favouritted
   *
   * @param e Event
   */
  favouriteClick = (e) => {
    if (this.props.favouriteTasks.includes(this.props.id)) {
      this.removeFromFavourite(this.props.id).then(() => this.props.getFavouriteTasks());
    }
    else {
      this.addTaskToFavourites(this.props.id).then(() => this.props.getFavouriteTasks());
    }
    e.stopPropagation();
  };

  async removeFromFavourite(task) {
    await deleteEntity("/task/favourite/" + task);
  }

  async addTaskToFavourites(task) {
    await post('/task/favourite/' + task)
  }

  /**
   * If the task is not in a TaskPicker component, the task is added to redux store of
   * globally picked tasks
   */
  taskClick = (e) => {
    if (!this.props.isTaskPicker) {
      e.stopPropagation();
      !this.props.isSelected(this.props.id, TaskType.TASK) ?
        this.props.pickGlobalTask(this.props, TaskSelectType.ADD) :
        this.props.pickGlobalTask(this.props, TaskSelectType.REMOVE);
    }
    this.forceUpdate();
  };

  /**
   * Checks if the task is in a TaskPicker component. If it is, the task is added to the redux store array
   * of selected tasks.
   * If not, a preview of the tasks opens
   */
  previewClick = () => {
    if (this.props.isTaskPicker) {
      if (!this.props.isSelected(this.props.id, TaskType.TASK) && this.props.selected) {
        this.props.pickSelectedTask(this.props, TaskSelectType.ADD);
      }
      else if (this.props.isSelected(this.props.id, TaskType.TASK) && this.props.selected) {
        this.props.pickSelectedTask(this.props, TaskSelectType.REMOVE);
      }
      else if (!this.props.isSelected(this.props.id, TaskType.TASK) && !this.props.selected) {
        this.props.pickTask(this.props, TaskSelectType.ADD);
      }
      else {
        this.props.pickTask(this.props, TaskSelectType.REMOVE);
      }
      if (this.props.selected) {
        this.props.toggleIsDisabled();
      }
      this.taskClick();
    }
    else {
      this.context.router.history.push("/task/" + this.props.id);
    }
  };

  /**
   * OnHover on the 'preview button', sets state to show the preview of the task
   */
  taskHover = () => {
    this.setState({hoverActive: true});
  };

  /**
   * Hovers the preview where the mouse is positioned. Takes the X and Y pixel coordinates of the mouse event, adds
   * the scroll amount of the modal to the Y axis
   * If the preview would be outside the screen, this function flips the preview so that it is shown
   *
   * @param event, the mouseMove event on the 'preview button'
   */
  taskMouseMove = (event) => {
    let modal = document.getElementById('modalContent');
    let height = document.getElementById('taskHoverContainer').scrollHeight + 25;
    let x = event.clientX - modal.offsetLeft;
    let y = event.clientY + modal.scrollTop;
    let distance = event.screenY - 50;

    if (x <= 600) {
      this.setState({hoverStyle: {top: y - height, left: x}});
    }
    else if (x > 600) {
      this.setState({hoverStyle: {top: y - height, left: x - 500}});
    }
    if (distance < height && x <= 600) {
      this.setState({hoverStyle: {top: y, left: x}});
    }
    else if (distance < height && x > 600) {
      this.setState({hoverStyle: {top: y, left: x - 500}});
    }
  };

  /**
   * When the mouse leaves the 'preview button', this sets the state to hide the preview
   */
  taskHoverLeave = () => {
    this.setState({hoverActive: false});
  };

  render() {
    return (
      <div>
        <div
          className={'task__container  ' + (this.props.isSelected(this.props.id, TaskType.TASK) ? 'task__container--selected' : '')}
          onClick={this.previewClick}>
          <i
            className={'task-right-corner--icon  fa  ' + (this.props.isSelected(this.props.id, TaskType.TASK) ? 'fa-check-circle' : 'fa-circle-thin')}
            onClick={this.taskClick}
          />
          <span className="task-title--icon">
          <i className="fa fa-file-o" id="taskIcon"/>
        </span>
          <div className="task__container__content">
            <div className="task-title">
              <p className="strongText">{this.props.title}</p>
            </div>
            <div className="task-sub-title">
              <span className="lightText">{this.props.description}</span>
            </div>
            <div className="task-categories">
              {this.props.grades.map(grade =>
                <div className="category__container" key={grade.grade}>
                  <span className="category-text">
                    {gradeCheck(grade.grade)}
                  </span>
                </div>
              )}
              {this.props.topics.map((topic) =>
                <span className="category__container" key={topic.id}>
                  <p className="category-text">{topic.topic}</p>
                </span>
              )}
            </div>
          </div>
          <div className="bottom-taskbar">
            {
              this.props.isTaskPicker &&
              <div className="task-preview-button__container"
                   onMouseMove={this.taskMouseMove}
                   onMouseOver={this.taskHover}
                   onMouseLeave={this.taskHoverLeave}>
                <span className="task-preview-button floatLeft">
                  Forhåndsvisning
                </span>
              </div>
            }
            <span>
            <i
              className={'right-bottom--icon  fa  ' + (this.props.favouriteTasks.includes(this.props.id) ? 'fa-bookmark' : 'fa-bookmark-o')}
              onClick={this.favouriteClick}/>
          </span>
            {!this.props.isTaskPicker &&
            <BottomBarRating
              rating={this.props.task.totalRating}
            />
            }
          </div>
        </div>
        <div>
          {
            this.state.hoverActive &&
            <div id="taskHover" style={this.state.hoverStyle} className="task-preview-task-hover">
              <TaskHover tasks={this.props}/>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleModal: toggleModal,
    pickTask: pickTask,
    pickSelectedTask: pickSelectedTask,
    pickGlobalTask: pickGlobalTask,
    getFavouriteTasks: getFavouriteTasks
  }, dispatch);

const mapStateToProps = state => {
  return {
    favouriteTasks: state.favouriteTasks,
    modalClass: state.modalClass
  };
};

TaskCard.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskCard);
