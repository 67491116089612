import React from 'react';
import './radioButton.css';
import {Option} from './option';

export default class TaskAmountRadioButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: null,
      selectedValue: null,
    };
  }

  componentWillMount() {
    this.props.onRef(this);
  }

  componentDidMount() {
    const minWidth = this.props.minWidth;
    let radioButtonElement = document.getElementsByClassName('minWidth' + this.props.minWidth);

    radioButtonElement = Array.prototype.slice.call(radioButtonElement);
    radioButtonElement.forEach(function (element) {
      element.style.minWidth = minWidth + 'px';
    });
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  handleChange = (index) => {
    this.setState({
        selectedIndex: index,
        selectedValue: this.props.values[index].name
      }, this.props.input.onChange(this.props.values[index].name)
    );
    this.props.clearCustomInput();
  };

  unSelect = () => {
    this.setState({selectedIndex: null, selectedValue: null},
      this.props.input.onChange(null));
  };

  render() {
    return (
      <div>
        <div className="radio-button-title">{this.props.label}</div>
        <div className="options">
          {
            this.props.values.map((value, i) =>
              <Option
                key={value.name}
                handleChange={this.handleChange}
                name={value.name}
                displayName={value.displayName}
                isChecked={(this.state.selectedIndex === i)}
                index={i}
                minWidth={this.props.minWidth}
              />
            )
          }
        </div>
      </div>
    )
  }
}