import React from 'react';
import './toggleButton.css'

export class ToggleButtonOption extends React.Component {

  componentDidMount() {
    if (this.props.index === 0 && this.props.selectDefault) {
      this.check();
    }
  }

  check = () => {
    this.props.handleChange(this.props.index, this.props.name);
  };

  render() {
    return (
      <div
        className={"toggle-button-option " + (this.props.isChecked ? "toggle-button-checked" : "toggle-button-unchecked")}>
        <div onClick={this.check}>
          {this.props.displayName}
        </div>
      </div>
    )
  }
}
