import {get} from "../../apiService";

/**
 * Fetches all types from API and dispatches data to redux store
 * */
export const getAllTypes = () => {
  return (dispatch, getState) => {
    return get('/type', getState().authentication.access_token).then((json) => dispatch(fetchAllTypesSuccess(json)));
  };
};

const fetchAllTypesSuccess = (payload) => {
  return {
    type: 'FETCH_TYPES_SUCCESS',
    payload
  };
};
