import * as React from 'react';

class SearchBar extends React.Component {

  render () {
    return (
      <div className="searchbar__container">
        <input
          type="text"
          name="searchField"
          placeholder="Hva ser du etter?"
          className="searchbar"
          onKeyUp={this.props.searchChange}
        />
      </div>
    );
  }
}

export default SearchBar;