import {get} from "../../apiService";

/**
 * Fetches all published tasks from database and dispatches data to redux store
 *
 */
export const getPublishedTasks = () => {
  return (dispatch, getState) => {
    return get('/task/published', getState().authentication.access_token).then((json) => dispatch(fetchPublishedTasksSuccess(json)));
  };
};

const fetchPublishedTasksSuccess = (payload) => {
  return {
    type: 'FETCH_PUBLISHED_TASKS_SUCCESS',
    payload
  };
};
