export const mapFormToTaskSetDTO = (values, tasks, taskSetDTO) => {
  taskSetDTO.tasks = tasks;
  taskSetDTO.title = values.title;
  taskSetDTO.description = values.description;
  taskSetDTO.createdAt = new Date();
  taskSetDTO.isPublished = false;
  taskSetDTO.img = values.img;
  taskSetDTO.grades = [];

  values.grade.map(grade => (
    taskSetDTO.grades.push({id: grade, grade: grade})
  ));

  return taskSetDTO;
};
