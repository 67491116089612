import React from 'react';
import {TaskType} from '../../../declarations';
import FilterDifficulty from './common/filterDifficulty';
import FilterType from './common/filterType';
import FilterTopic from './common/filterTopic';
import FilterButton from '../../../containers/filter/filterButton';
import {namespace} from "../../../util/namespaceNO";
import DropdownWithoutBorder from "../dropDownMenu/dropdownMenuWithoutBorder";

const FilterSidebar = (props) => {

  const {
    toggleTaskTypeFilter,
    hiddenFilters,
    filter,
    onChange,
    toggleDifficultyFilter,
    toggleTypeFilter,
    toggleTopicFilter,
    handleDifficultyChange,
    types,
    topics,
    grades
  } = props;

  return (
    <div className='filter-menu'>
      <div className="filter-top  filter-top--label">
        <FilterButton
          filter={toggleTaskTypeFilter}
          title={'Oppgaver'}
          icon={'taskIconCorner'}
          filterName={TaskType.TASK}
        />
        <FilterButton
          filter={toggleTaskTypeFilter}
          title={'Oppgavesett'}
          icon={'taskSetIconCorner'}
          filterName={TaskType.TASKSET}
        />
        <FilterButton
          filter={toggleTaskTypeFilter}
          title={namespace.usersBookmarks}
          icon={'favouriteIconCorner'}
          filterName={TaskType.FAVOURITE}
        />
      </div>
      <div className="show-more__filters  show-more__filters--sidebar">
        <label
          className={'dropdown-button  dropdown-button--filter  ' +
          (hiddenFilters ? 'dropdown-button--down' : 'dropdown-button--up')}
          onClick={props.toggleShowMoreFilters}>
          {hiddenFilters ? 'Skjul filtre' : 'Vis flere filtre'}
        </label>
      </div>
      <div className={'custom-dropdown-menu-wrapper ' +
      (hiddenFilters ? 'custom-dropdown-menu-wrapper--active' : '')}>
        <div className={'filters  custom-dropdown-menu  ' +
        (hiddenFilters ? 'custom-dropdown-menu--active' : '')}>
          <div className="filter-entity-row">
            <DropdownWithoutBorder
              dropdownTitle={namespace.filterGrade}
              list={grades}
              onChange={onChange}
              filter={filter}
            />
          </div>
          <div className="filter-entity-row">
            <FilterDifficulty
              label='Vanskelighetsgrad'
              filter={toggleDifficultyFilter}
              onChange={handleDifficultyChange}
            />
          </div>
          <div className="filter-entity-row">
            <FilterType
              label='Type oppgaver'
              types={types}
              filter={toggleTypeFilter}
            />
          </div>
          <div className="filter-entity-row">
            <FilterTopic
              label='Emner'
              topics={topics}
              filter={toggleTopicFilter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;
