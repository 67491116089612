import React from 'react';
import FilterButton from '../../../../containers/filter/filterButton';

const FilterTopic = (props) => {
  const {
    label,
    topics,
    filter
  } = props;

  topics.length > 0  && topics.sort(function (a, b) {
    let textA = a.topic;
    let textB = b.topic;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  return (
    <div>
      <label className='filter-label'>{label}</label>
      <div className="filter-entity">
        {topics.length > 0  && topics.map((topic, i) =>
          <div key={i} className="filter-button--small">
            <FilterButton
              filter={filter}
              filterName={topic.topic}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterTopic;
