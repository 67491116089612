import * as React from 'react';
import {gradeCheck} from '../../../actions/common/gradeCheck';

export const TaskProperties = (props) => {

  return (
    <div className="modal-content__topbar">
      <div className="modal-properties__container">
        <div className="property-row  columns">
          <div className="column  is-one-fifth">
            <span className="lightText">Klassetrinn: </span>
          </div>
          <div className="column  auto">
            {props.grades.map(grade =>
              <div className="modal-category__container" key={grade.grade}>
                <p className="category-text">{gradeCheck(grade.grade)}</p>
              </div>
            )}
          </div>
        </div>
        <div className="property-row  columns">
          <div className="column  is-one-fifth">
            <span className="lightText">Type oppgave: </span>
          </div>
          <div className="column  auto">
            {props.types.map((type, i) =>
              <div className="modal-category__container" key={i}>
                <span className="category-text">{type.type}</span>
              </div>
            )}
          </div>
        </div>
        <div className="property-row  columns">
          <div className="column  is-one-fifth">
            <span className="lightText">Vanskelighetsgrad: </span>
          </div>
          <div className="column  auto">
            <div className="modal-category__container">
              <p className="category-text">{props.difficulty}</p>
            </div>
          </div>
        </div>
        <div className="property-row  columns">
          <div className="column  is-one-fifth">
            <span className="lightText">Tema: </span>
          </div>
          <div className="column  auto">
            {props.topics.map((topic, i) =>
              <div className="modal-category__container" key={i}>
                <span className="category-text">{topic.topic}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
