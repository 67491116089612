import React from 'react';
import {emailRequiredAlert} from "./common/alerts/emailRequiredAlert";


export const emailPrompt = (props) => {

  const onConfirm = () => {
    window.location = window.origin + '/login/page'
  };

  return(
    <div>
      {emailRequiredAlert(onConfirm)}
    </div>
  );
};
