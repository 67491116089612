import React from 'react';
import { Field } from 'redux-form';

const renderInput = (props) => (
  <div className="checkbox  checkbox-modal  checkbox-modal--topic">
    <label className="checkbox--label">
      <span className="lightText">{props.label}</span>
      <Field
        name={props.input.name}
        type="checkbox"
        component="input"
      />
    </label>
  </div>
);

const pickTopics = (props) => {
  const {topics, meta} = props;

  return (
    <div>
      <label>{props.label}</label>
      <div className="topic-input__container">
        {topics.map((topic, i) =>
          <div key={i}>
            <Field
              name={`topics.${topic.topic}`}
              component={renderInput}
              label={topic.topic}
            />
          </div>
        )}
      </div>
      {
        meta.error && meta.submitFailed &&
        <span className="error-message-label">{meta.error}
        </span>
      }
    </div>
  );
};

export default pickTopics;
