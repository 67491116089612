import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {change, formValueSelector, reset} from 'redux-form';
import TaskPage1 from './pages/TaskPage1';
import TaskPage2 from './pages/TaskPage2';
import TaskPage3 from './pages/TaskPage3';
import TaskPage4 from './pages/TaskPage4';
import TaskPage5 from './pages/TaskPage5';
import {mapFormToTaskDTO} from '../../../actions/task/mapFormToTaskDTO';
import {toggleModal} from '../../../actions/common/toggleModal';
import {Pageinator} from '../../../components/common/modal/pageinator';
import {publisher} from '../../../components/common/alerts/publisher';
import {SubTaskGenerator} from './pages/renderFields/subTaskGenerator';
import {ALPHABET} from "../../../declarations";
import {post} from "../../../apiService";

class TaskForm extends Component {

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.publishTask = this.publishTask.bind(this);
    this.addSubTaskRow = this.addSubTaskRow.bind(this);
    this.removeSubTaskRow = this.removeSubTaskRow.bind(this);
    this.handleTaskSubmit = this.handleTaskSubmit.bind(this);
    this.publishTask = this.publishTask.bind(this);
    this.state = {
      page: 1,
      subTaskRows: 0,
      taskDTO: {},
      subTasks: []
    };
  }

  nextPage() {
    this.setState({page: this.state.page + 1});
  }

  previousPage() {
    this.setState({page: this.state.page - 1});
  }

  /**
   * Triggers action which published the task and renders a publisher modal and resets the form.
   * If the task could not be published, the form is reset and an alert is shown
   */
  publishTask() {
    const {taskDTO} = this.state;
    taskDTO.isPublished = true;

    post('/task', this.state.taskDTO).then(() => {
      this.props.toggleModal(this.props.modalClass.class);
      publisher('Din oppgave er publisert!', 'www.ematte.no/eksempellink', () => {
        this.props.updateTaskList();
      });
      this.props.reset('newTask');
    });
  }

  /**
   * Adds a new row of subtask to be rendered
   *
   */
  addSubTaskRow() {
    const {subTasks} = this.state;
    let renderSubTasks = subTasks.slice();
    let i = subTasks.length;
    renderSubTasks.push(
      <div key={i}>
        <SubTaskGenerator
          index={i}
          alphabet={ALPHABET[i]}
          removeSubTaskRow={this.removeSubTaskRow}
          change={this.props.change}
        />
      </div>
    );
    this.setState({
      subTaskRows: this.state.subTaskRows + 1,
      subTasks: renderSubTasks
    });
  }

  /**
   * Removes a row of subtask to be rendered
   */
  removeSubTaskRow() {
    const {subTasks} = this.state;
    let renderSubTasks = subTasks.slice();
    let i = subTasks.length - 1;
    this.props.change('newTask', 'subtask_description' + i, '');
    this.props.change('newTask', 'subtask_solution' + i, '');
    renderSubTasks.splice(i, 1);
    this.setState({
      subTaskRows: this.state.subTaskRows - 1,
      subTasks: renderSubTasks
    });
  }

  /**
   * Inserts all input from the user into an array which are inserted
   * to database when the user publishes the task
   *
   * @param values
   */
  handleTaskSubmit(values) {
    if (this.state.page === 4) {
      this.setState({taskDTO: mapFormToTaskDTO(this.state.taskDTO, values, this.props)});
    }
    this.nextPage();
  }

  render() {
    const {page} = this.state;
    const {title} = this.props;
    const totalPages = 5;
    return (
      <section>
        <div className="modal-content__container  not-selectable">
          <div className="has-text-centered  pageinator">
            <Pageinator page={page} total={totalPages}/>
          </div>
          <div className="new-task-modal__header">
            {
              page === 1 &&
              <span><i className="fa  fa-file-o  padding-right10"/>Ny oppgave</span>
            }
            {
              page === 2 &&
              <div>
                <span>{title}<i className="fa  fa-pencil  padding-left10"/></span>
                <div className="modal-row">
                  <span className='lightText'>Dette vil ikke vises til elevene</span>
                </div>
              </div>
            }
            {
              page === 3 &&
              <span>{title}<i className="fa  fa-pencil  padding-left10"/></span>
            }
            {
              page === 4 &&
              <span>{title}<i className="fa  fa-pencil  padding-left10"/></span>
            }
            {
              page === 5 &&
              <span>Forhåndsvisning</span>
            }
          </div>
          {
            page === 1 &&
            <TaskPage1
              onSubmit={this.nextPage}
              addSubTaskRow={this.addSubTaskRow}
              page={page}
              total={totalPages}
              subTaskRows={this.state.subTaskRows}
              removeSubTaskRow={this.removeSubTaskRow}
              subTasks={this.state.subTasks}
              taskDTO={this.state.taskDTO}
            />
          }
          {
            page === 2 &&
            <TaskPage2
              previousPage={this.previousPage}
              types={this.props.allTypes}
              onSubmit={this.nextPage}
              page={page}
              total={totalPages}
            />
          }
          {
            page === 3 &&
            <TaskPage3
              previousPage={this.previousPage}
              topics={this.props.allTopics}
              onSubmit={this.nextPage}
              page={page}
              total={totalPages}
            />
          }
          {
            page === 4 &&
            <TaskPage4
              previousPage={this.previousPage}
              onSubmit={this.handleTaskSubmit}
              page={page}
              total={totalPages}
            />
          }
          {
            page === 5 &&
            <TaskPage5
              task={this.state.taskDTO}
              previousPage={this.previousPage}
              onSubmit={this.publishTask}
              page={page}
              total={totalPages}
            />
          }
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleModal: toggleModal,
    change: change,
    reset: reset,
  }, dispatch);

const mapStateToProps = (state) => {
  const selector = formValueSelector('newTask');
  return {
    allTopics: state.allTopics,
    allTypes: state.allTypes,
    modalClass: state.modalClass,
    title: selector(state, 'title'),
    descriptionPicture: state.descriptionPicture
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
