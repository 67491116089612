/**
 * Makes a string of grade to ouput grade in component
 *
 * @param grade
 * @return string
 * */
export const gradeCheck = (grade) => {
  switch (grade) {
    case 11:
      return '1. vgs';
    case 12:
      return '2. vgs';
    case 13:
      return '3. vgs';
    default:
      return grade + '.klasse';
  }
};
