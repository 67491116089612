import {FilterTypes, TaskType} from '../../declarations';

/**
 * Filters tasks/tasksets sent in as parameter and filters the elements on each
 * filtertype that is sent in as parameter.
 *
 * @return Array of filtered tasks and tasksets
 * */
export const filterList = (tasks,
                           filteredTypes,
                           taskTypeFilter,
                           gradeFilter,
                           difficultyFilter,
                           typeFilter,
                           topicFilter,
                           searchFilter,
                           userFilter) => {

  filteredTypes.forEach((filterType) => {
    switch (filterType) {
      case FilterTypes.TASKTYPE:
        tasks = filterTaskType(tasks, taskTypeFilter);
        break;
      case FilterTypes.DIFFICULTY:
        tasks = filterDifficulty(tasks, difficultyFilter);
        break;
      case FilterTypes.GRADE:
        tasks = filterGrade(tasks, gradeFilter);
        break;
      case FilterTypes.TYPE:
        tasks = filterTypes(tasks, typeFilter);
        break;
      case FilterTypes.TOPIC:
        tasks = filterTopics(tasks, topicFilter);
        break;
      case FilterTypes.SEARCH:
        tasks = filterSearch(tasks, searchFilter);
        break;
      case FilterTypes.USER:
        tasks = filterUser(tasks, userFilter);
        break;
      default:
        return tasks;
    }
  });
  return tasks;
};

const filterTaskType = (tasks, filters) => {
  tasks = tasks.filter((task) => {
    return filters.includes(task.type) || (filters.includes(TaskType.FAVOURITE) && task.isFavourite);
  });
  return tasks;
};

const filterGrade = (tasks, filters) => {
  if(filters === "noFilter")
  return tasks;
  return tasks.filter(task => task.grades[0].grade === filters[0])
};

const filterDifficulty = (tasks, filters) => {
  tasks = tasks.filter((task) => {
    let persist = false;
    if (task.type === TaskType.TASK) {
      persist = filters.includes(task.difficulty);
    }
    else if (task.type === TaskType.TASKSET) {
      task.tasks.forEach(task => {
        if (filters.includes(task.difficulty)) {
          persist = true;
        }
      });
    }
    return persist;
  });
  return tasks;
};

const filterTypes = (tasks, filters) => {
  tasks = tasks.filter((task) => {
    let persist = false;
    if (task.type === TaskType.TASK) {
      task.types.forEach(type => {
        if (filters.includes(type.type)) {
          persist = true;
        }
      });
    }
    else if (task.type === TaskType.TASKSET) {
      task.tasks.forEach(task => {
        task.types.forEach(type => {
          if (filters.includes(type.type)) {
            persist = true;
          }
        });
      });
    }
    return persist;
  });
  return tasks;
};

const filterTopics = (tasks, filters) => {
  tasks = tasks.filter((task) => {
    let persist = false;
    if (task.type === TaskType.TASK) {
      task.topics.forEach(topic => {
        if (filters.includes(topic.topic)) {
          persist = true;
        }
      });
    }
    else if (task.type === TaskType.TASKSET) {
      task.tasks.forEach(task => {
        task.topics.forEach(topic => {
          if (filters.includes(topic.topic)) {
            persist = true;
          }
        });
      });
    }
    return persist;
  });
  return tasks;
};

const filterUser = (tasks, filters) => {
  tasks = tasks.filter((task) => {
    let persist = false;
    if (task.type === TaskType.TASK) {
      filters.task.forEach((taskFilter) => {
        if (task.id === taskFilter.id) {
          persist = true;
        }
      });
    }
    else if (task.type === TaskType.TASKSET) {
      filters.tasksets.forEach((taskFilter) => {
        if (task.id === taskFilter.id) {
          persist = true;
        }
      });
    }
    return persist;
  });
  return tasks;
};

const filterSearch = (tasks, filters) => {
  tasks = tasks.filter(task => {
    return task.title.toLowerCase().search(filters) !== -1;
  });
  return tasks;
};


