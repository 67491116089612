let pickedTasks = [];

const pickTaskReducer = (state = [], action) => {

  switch (action.type) {
    case 'ADD_TASK':
      pickedTasks.push(action.payload);
      return pickedTasks;
    case 'REMOVE_TASK':
      pickedTasks = pickedTasks.filter((task) => {
        return task.id !== action.payload.id
      });
      return pickedTasks;
    case 'RESET_TASK':
      pickedTasks = [];
      return pickedTasks;
    default:
      return state;
  }
};

export default pickTaskReducer;