import * as React from 'react';
import { connect } from 'react-redux';

class FilterButton extends React.Component {
  constructor () {
    super();
    this.state = {
      check: false,
      checkedBtn: ''
    };
  }

  handleFilterClick = () => {
    const {check} = this.state;
    if (check) {
      this.setState({check: false, checkedBtn: ''});
      this.props.filter(this.props.filterName, false);
    }
    else {
      this.setState({check: true, checkedBtn: 'filter-button-label--checked'});
      this.props.filter(this.props.filterName, true);
    }
  };

  render () {
    return (
      <div className="filter-button" onClick={this.handleFilterClick}>
            <span className={'lightText  filter-button-label  ' + this.state.checkedBtn + '  ' + this.props.icon}>
              {
                !this.props.title &&
                <span>{this.props.filterName}</span>
              }
              {
                this.props.title &&
                <span>{this.props.title}</span>
              }
            </span>
      </div>
    );
  }
}

export default connect()(FilterButton);
