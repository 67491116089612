import ClientOAuth2 from "client-oauth2";
import fetch from "isomorphic-fetch";
import {deleteEntity, get} from "./apiService";

export const getOAuth2Authorization = (clientId, authorizationUri) => new ClientOAuth2({
                                                         clientId : clientId,
                                                         authorizationUri : authorizationUri,
                                                         redirectUri : window.location.origin + '/login/skolon',
                                                         scopes : [ "authenticatedUser.profile.read" ]
                                                       });

export const getUri = () => {
  get("/login/skolon/clientId/authurl").then((json) => {
    window.location = getOAuth2Authorization(json.clientId, json.userAuthorizationUri).code.getUri();
  });

};

export const getOAuth2AuthorizationFacebook = (clientId) => new ClientOAuth2({
  clientId : clientId,
  authorizationUri : 'https://www.facebook.com/v5.0/dialog/oauth',
  redirectUri : window.location.origin + '/login/facebook',
  scopes :  ["public_profile", "email"]
});




export const getFacebookURI = () => {
   get("/login/clientId")
      .then((json) =>{
        json.text().then(res=>{
          window.location = getOAuth2AuthorizationFacebook(res).code.getUri()
        });
      })
};

export const logout = (token) => {
  return fetch("/api/oauth/token",
               {
                 method : 'GET',
                 credentials : 'include',
                 headers : new Headers({
                                         'Authorization' : 'Bearer ' + token,
                                         'Content-Type' : 'application/x-www-form-urlencoded'
                                       })
               }).then(res => window.location = res.url)
};

export const deleteUser = () => {
  return deleteEntity('/deleteuser').then(() => window.location = window.location.origin + '/login/page');
};