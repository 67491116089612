import React from 'react';
import TaskCard from '../../../containers/task/taskCard';

export const ChosenTaskList = (props) => {

  const {tasks, isTaskPicker, toggleIsDisabled} = props;

  let array = [];
  tasks.forEach((task, i) => {
    array.push(
      <div className="chosen-task-list-element" key={i}>
        <TaskCard
          {...task}
          isTaskPicker={isTaskPicker}
          selected={true}
          toggleIsDisabled={toggleIsDisabled}
        />
      </div>
    );
  });

  return array;
};
