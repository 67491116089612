import { TaskSelectType } from '../../declarations';

/**
 * Adds, removes or reset array of tasks in redux store
 *
 * @param task
 * @param type
 * @return {function(*)}
 */
export const pickTask = (task, type) => {

  if(type === TaskSelectType.ADD) {
    return(dispatch) => {
      dispatch(addTask(task));
    }
  }
  else if(type === TaskSelectType.RESET) {
    return(dispatch) => {
      dispatch(resetTask());
    }
  }
  else if (type === TaskSelectType.REMOVE) {
    return (dispatch) => {
      dispatch(removeTask(task));
    }
  }
};


const addTask = (payload) => {
  return {
    type: "ADD_TASK",
    payload
  }
};

const removeTask = (payload) => {
  return {
    type: "REMOVE_TASK",
    payload
  }
};

const resetTask = () => {
  return {
    type: "RESET_TASK"
  }
};