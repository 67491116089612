import React from 'react';
import {Field, reduxForm} from 'redux-form';
import InputFieldWithTitle from '../../../../components/common/renderFields/inputFieldWithTitle/inputFieldWithTitle';
import textAreaInput from './renderFields/textArea';
import validate from '../validate';
import {BottomRowButtons} from '../../../../components/common/modal/bottomRowButtons';
import {Picture} from "../../../picture/picture";
import {FormulaForm} from "../../../../components/task/formula/formulaForm";

const TaskPage1 = (props) => {
  const {
    handleSubmit,
    addSubTaskRow,
    removeSubTaskRow,
    subTaskRows,
    page,
    subTasks,
    total,
    taskDTO
  } = props;

  const onPictureAdded = (imageId) => {
    taskDTO.descriptionPicture = {id: imageId};
  };

  const onFormulaAdded = (formula) => {
    taskDTO.formula = formula;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-row">
        <Field
          name="title"
          type="text"
          component={InputFieldWithTitle}
          label="Navn på oppgaven*"
          placeholder="Skriv navn her"
        />
      </div>
      <div className="modal-row">
        <div className="task-viewer__container">
          <div className="columns">
            <div className="column  is-three-fifths">
              <Field
                name="description"
                component={textAreaInput}
                label="Oppgave*"
                placeholder="Beskriv oppgaven"
              />
              <Picture
                onPictureAdded={onPictureAdded}
                inputId={"task"}
                image={taskDTO.descriptionPicture}
              />
              <FormulaForm
                onFormulaAdded={onFormulaAdded}
                formula={taskDTO.formula}
              />
            </div>
            <div className="column  is-two-fifths">
              <Field
                name="solution_description"
                component={textAreaInput}
                label="Fasit"
                placeholder="Beskriv fasiten"
              />
            </div>
          </div>
          {subTaskRows > 0 &&
          <div className="subtask-rows">
            <div>
              {subTasks}
            </div>
            <div className="subtask-answer--removeicon" onClick={removeSubTaskRow}/>
          </div>
          }
          <div className="modal-row  has-text-right">
            <span onClick={addSubTaskRow} className="add-subtask-row" hidden={subTaskRows > 25}>Legg til deloppgave <i
              className="fa  fa-plus-circle"/></span>
          </div>
        </div>
      </div>
      <BottomRowButtons
        page={page}
        total={total}
      />
    </form>
  );
};

export default reduxForm({
  form: 'newTask',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(TaskPage1);
