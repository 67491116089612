import { getUri } from "../../oauthConfig";
import { get } from '../../apiService';

/**
 * Checks if user is authorized
 */
export const authUser = () => {
  getUri();
};

export const getToken = () =>
  (dispatch, getState) => get('/storeToken', getState().authentication.access_token)
    .then(result => dispatch(storeToken(result.details)));

const storeToken = (token) =>
  ({
    type : "STORE_TOKEN",
    payload : token
  });
