import React from 'react';
import './picture.css';
import {PictureForm} from "../../components/common/picture/pictureForm";
import {PictureViewer} from "../../components/common/picture/pictureViewer";
import {postFormData} from "../../apiService";

export class Picture extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      imageId: null
    };
  }

  componentDidMount() {
    this.props.image &&
      this.setState({imageId: this.props.image.id})
  }

  handleUpload = (event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    postFormData("/document", formData)
      .then((response) => {
        const imageId = parseInt(response.id, 10);
        this.props.onPictureAdded(imageId);
        this.setState({imageId: imageId})
      });
  };

  render() {
    return (
      <div className="picture">
        {
          this.state.imageId === null ?
            <PictureForm
              handleUpload={this.handleUpload}
              inputId={this.props.inputId}/>
            :
            <PictureViewer imageId={this.state.imageId}/>
        }
      </div>
    )
  }
}
