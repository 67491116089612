export const randomNumberGenerator = (minValue, maxValue, numberOfDecimals) => {
  //return Math.floor(Math.random() * parseInt(maxValue, 10)) + parseInt(minValue, 10);
  let desimal = 0;
  switch (numberOfDecimals) {
    case 0:
      desimal = 1;
      break;
    case 1:
      desimal = 10;
      break;
    case 2:
      desimal = 100;
      break;
    case 3:
      desimal = 1000;
      break;
    case 4:
      desimal = 10000;
      break;
    default:
      desimal = 1;
  }

  return Math.floor(Math.random() * maxValue + minValue) / desimal;
};

export const randomNumberWithDecimalsGenerator = (minValue, maxValue, numberOfDecimals) => {
  return Math.floor(Math.random() * parseInt(maxValue, 10)) + parseInt(minValue, 10);
};
