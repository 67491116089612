import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {namespace} from "../../../util/namespaceNO";

export const deletingUserAlert = (onConfirm, onCancel) => {
  confirmAlert({
    title: 'Slett bruker',
    message: namespace.deleteUserPrompt,
    confirmLabel: namespace.acceptDisclaimer,
    cancelLabel: namespace.declineDisclaimer,
    onConfirm: () => onConfirm(),
    onCancel: () => onCancel()
  });
};