import * as React from 'react';

export const TopBarRating = (props) => {

  const {
    amountOfRatings,
    rating
  } = props;
  return (
    <div className="rating__container">
      <p className="topbar-rating"><i className="fa fa-star  icon-margin"/>
        {
          !rating ? "-" : rating.toFixed(1)
        }
        <i className="ratingDivider  fa  fa-circle"/>
        {amountOfRatings} {amountOfRatings === 1 ? "vurdering" : "vurderinger"}</p>
    </div>
  );
};
