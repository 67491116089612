class Decimal {
  constructor(numberOfDecimals) {
    this.numberOfDecimals = numberOfDecimals;
  }

  key = () => this.numberOfDecimals;
  description = () => this.numberOfDecimals;
  value = () => this.numberOfDecimals;
}

function initializeDecimal(numberOfDecimals) {
  return[...Array(numberOfDecimals + 1).keys()].map(i => new Decimal(i))
}

export const DECIMAL = Object.freeze(
  {
    ZERO: initializeDecimal(0),
    ONE: initializeDecimal(1),
    TWO: initializeDecimal(2),
    THREE: initializeDecimal(3),
    FOUR: initializeDecimal(4),
  });

