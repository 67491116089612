import * as React from 'react';
import {gradeCheck} from '../../../actions/common/gradeCheck';
import {duplicateRemover} from '../../../actions/common/duplicateRemover';

export const TaskSetProperties = (props) => {

  return (
    <div className="modal-content__topbar">
      <div className="modal-properties__container">
        <div className="task-properties">
          <div className="property-row  columns">
            <div className="column  is-one-fifth">
              <span className="lightText">Klassetrinn:</span>
            </div>
            <div className="column  auto">
              {props.grades.map(grade =>
                <div className="modal-category__container" key={grade.grade}>
                  <p className="category-text">{gradeCheck(grade.grade)}</p>
                </div>
              )}
            </div>
          </div>
          <div className="property-row  columns">
            <div className="column  is-one-fifth">
              <span className="lightText">Type oppgave: </span>
            </div>
            <div className="column  auto">
              {duplicateRemover(props.tasks, 'types').map((type, i) =>
                <div className="category__container" key={i}>
                  <p className="category-text">{type}</p>
                </div>
              )}
            </div>
          </div>
          <div className="property-row  columns">
            <div className="column  is-one-fifth">
              <span className="lightText">Vanskelighetsgrad: </span>
            </div>
            <div className="column  auto">
              {duplicateRemover(props.tasks, 'difficulty').map((difficulty, i) =>
                <div className="category__container" key={i}>
                  <p className="category-text">{difficulty}</p>
                </div>
              )}
            </div>
          </div>
          <div className="property-row  columns">
            <div className="column  is-one-fifth">
              <span className="lightText">Tema: </span>
            </div>
            <div className="column  auto">
              {duplicateRemover(props.tasks, 'topics').map((topic, i) =>
                <div className="category__container" key={i}>
                  <p className="category-text">{topic}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


