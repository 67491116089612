let pickedTasks = [];

const pickSelectedTaskReducer = (state = [], action) => {

  switch (action.type) {
    case 'ADD_SELECTED_TASK':
      pickedTasks.push(action.payload);
      return pickedTasks;
    case 'REMOVE_SELECTED_TASK':
      pickedTasks = pickedTasks.filter((task) => {
        return task.id !== action.payload.id
      });
      return pickedTasks;
    case 'RESET_SELECTED_TASK':
      pickedTasks = [];
      return pickedTasks;
    default:
      return state;
  }
};

export default pickSelectedTaskReducer;