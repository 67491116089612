import {generateTask} from "../../services/taskGenerator/taskGenerator";
import {randomNumberGenerator} from "../../services/randomNumberGenerator";
import {sortByLength} from "../../services/util/utils";

export const createTaskSetDTO = (formState, values) => {
  let taskAmount;
  values.taskAmount ? taskAmount = values.taskAmount : taskAmount = values.customTaskAmount;

  let taskSetDTO = {};
  let tasks = [];

  const grades = formState.selectedGrades.map(grade => ({id: grade, grade: grade}));
  const type = values.types.filter(type => type.type === "Puggeoppgave");

  const userInput = {
    mainSubject: formState.mainSubject,
    format: 1,
    minimumDigit: formState.minimumDigit,
    maximumDigit: formState.maximumDigit,
    additionalSubjects: formState.selectedAdditionalSubjects,
    numberOfDecimals: 0,
  };

  for (let i = 0; i < taskAmount; i++) {
    userInput.format = formState.selectedFormat[randomNumberGenerator(0, formState.selectedFormat.length)].format;
    userInput.numberOfDecimals =
      formState.selectedDecimals[randomNumberGenerator(0, formState.selectedDecimals.length)].value.numberOfDecimals;
    let generatedTask = generateTask(userInput);

    generatedTask.topics = [];
    values.topics.map(topic =>
      generatedTask.taskTopics.map(taskTopic =>
        taskTopic === topic.topic &&
        generatedTask.topics.push({id: topic.id, topic: topic.topic})
      )
    );

    generatedTask.grades = grades;
    generatedTask.types = type;
    generatedTask.title = generatedTask.types[0].type + " " + generatedTask.topics.map(topic => (
        topic.topic + " "
      ))
      + formState.selectedGrades + ".trinn";

    tasks.push(generatedTask);
  }

  taskSetDTO.title = values.title;
  taskSetDTO.tasks = sortByLength(tasks);
  taskSetDTO.grades = grades;

  return (taskSetDTO);
};
