import Dropdown from "../../common/dropDownMenu/dropDownMenu";
import pickType from '../renderFields/pickType';
import pickDifficulty from '../renderFields/pickDifficulty';
import { Field, FieldArray } from 'redux-form';
import * as React from 'react';
import {GRADE} from "../../../util/enums/grade";

export const FormTypePage = (props) => {
  const grades = Object.values(GRADE);
  const callback = () => {
    return null
  };

  return (
    <div className="task-viewer__container">
      <div className="columns">
        <div className="column  is-one-quarter">
          <Field
            name="grade"
            component={Dropdown}
            label="Velg klassetrinn*"
            list={grades}
            dropdownTitle={grades[0].description()}
            callback={callback}
          />
        </div>
        <div className="column  is-three-quarters  is-offset-1">
          <span>Vanskelighetsgrad*</span>
          <div className="difficulty-input__container">
            <Field
              name="difficulty"
              component={pickDifficulty}
              label="1"
            />
          </div>
        </div>
      </div>
      <div className="type-container">
        <FieldArray name="type" component={pickType} label="Hvilken type?*" types={props.types}/>
      </div>
    </div>
  );
};

