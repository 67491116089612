import React, {Component} from "react";
import './taskFormat.css'

export class TaskFormatElement extends Component {

  componentDidMount() {
    if (this.props.value.index === 0) {
      this.activateCheckBox()
    }
  }

  activateCheckBox = () => {
    const checkbox = document.getElementById("format-checkbox" + this.props.value.format);
    if (checkbox !== null) {
      checkbox.click();
      this.props.handleChange(this.props.value)
    }
  };

  createFormat = () => {
    let taskFormatElements = [
      <input key={'taskFormatCheckbox'}
             onClick={this.activateCheckBox}
             id={"format-checkbox" + this.props.value.format}
             type='checkbox'/>,
      <div key={'taskFormatBigBox'} className='task-format-box-big'/>
    ];

    let firstElement = 1;

    for (let i = 0; i < this.props.value.format - firstElement; i++) {
      taskFormatElements.push(
        <div key={'additionalSmallBox' + i} className="task-format-box-small"/>,
        <div key={'additionalBigBox' + i} className="task-format-box-big"/>)
    }
    return taskFormatElements
  };

  render() {
    return (
      <div className="task-format-container" onClick={this.activateCheckBox}>
        {this.createFormat()}
      </div>
    )
  }
};
