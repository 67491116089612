import React from 'react';
import "./pictureViewer.css";

export const PictureViewer = (props) => {

  const {imageId} = props;
  const url = "/api/document/" + imageId;
  return (
    <div>
      <img src={url} alt="opplastet bilde" className="picture-viewer"/>
    </div>
  )
};