import * as React from 'react';
import pickTopics from '../renderFields/pickTopics';
import { FieldArray } from 'redux-form';

export const FormTopicPage = (props) => {
  const {topics} = props;
  return (
    <div className="task-viewer__container">
      <FieldArray
        name="topic"
        component={pickTopics}
        label="Hvilket tema?*"
        amount={topics.length}
        topics={topics}
      />
    </div>
  );
};