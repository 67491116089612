import * as React from 'react';

class AddComment extends React.Component {

  constructor() {
    super();
    this.state = {
      comment: ''
    };

    this.setComment = e => {
      this.setState({comment: e.target.value});
    };

    /**
     * Adds a comment to a task or task set
     * @param e event
     */
    this.addCommentSubmit = e => {
      e.preventDefault();
      this.props.addComment({comment: this.state.comment});
      this.setState({comment: ''});
    };
  }

  render() {
    return (
      <div>
        <div className="modal-row">
          Kommentarer
        </div>
        <div className="modal-row">
          <form onSubmit={this.addCommentSubmit}>
            <div className="comment-input">
                <textarea
                  className={this.state.comment ? "new-comment-with-comment" : "new-comment-without-comment"}
                  placeholder="Skiv en kommentar..."
                  value={this.state.comment}
                  onChange={this.setComment}
                  autoComplete="off"
                />
              { this.state.comment && <input type="submit" className="comment-button" value="Legg til"/> }
            </div>
          </form>
        </div>
      </div>
    );
  };
}

export default AddComment;