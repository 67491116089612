import * as React from 'react';

export const Rating = (props) => {
  const {
    isTaskSet,
    taskRatingClick,
    taskSetRatingClick,
    userRating
  } = props;

  let stars = [];
  for(let i=5; i>0; i--){
    stars.push(<span className="ratingStar" onClick={() => {isTaskSet ? taskSetRatingClick(i) : taskRatingClick(i)}} key={i}/>)
  }

  return (
    <div className="feedback_container">
      <div className="modal-row">
        <div className="modal-rating">
          { !userRating ?
            isTaskSet ? "Likte du oppgavesettet?" : "Likte du oppgaven?"
            :
            "Du har allerede gitt denne en vurdering på " + userRating + "/5 stjerner, ønsker du å gi en ny vurdering?"
          }
          <div className="modal-row  ratingRow">
            { stars }
          </div>
        </div>
      </div>
    </div>
  );
};
