import React from 'react';
import { TaskViewer } from '../common/modal/taskViewer';

export const TaskHover = (props) => {
  return (
    <div className="task-hover__container" id="taskHoverContainer">
      <TaskViewer {...props.tasks}/>
    </div>
  );
};
