import * as React from 'react';
import { reduxForm } from 'redux-form';
import validate from '../validate';
import { FormTopicPage } from '../../../../components/common/modal/formTopicPage';
import { BottomRowButtons } from '../../../../components/common/modal/bottomRowButtons';

let TaskPage3 = (props) => {
  const {handleSubmit, topics, page, previousPage, total} = props;

  return (
    <form onSubmit={handleSubmit}>
      <FormTopicPage topics={topics}/>
      <BottomRowButtons
        page={page}
        previousPage={previousPage}
        total={total}
      />
    </form>
  );
};

export default reduxForm({
  form: 'newTask',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(TaskPage3);
