import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import Header from '../../components/header/Header';
import Sidebar from '../sidebar/sidebar';
import Topbar from '../topbar/topbar';
import {TaskList} from '../../components/common/taskList/taskList';
import React from 'react';
import { getToken } from '../../actions/user/authUser';
import {Modal, ModalType} from '../../components/common/modal';
import {toggleModal} from '../../actions/common/toggleModal';
import {exit} from '../../components/common/alerts/exit';
import {pickTask} from '../../actions/common/pickTask';
import {FilterTypes, Sortings, TaskSelectType, TaskType} from '../../declarations';
import {getPublishedTaskSets} from '../../actions/taskSet/fetchPublishedTaskSets';
import {getPublishedTasks} from '../../actions/task/fetchPublishedTasks';
import {combineLists} from '../../actions/common/combineLists';
import {filterList} from '../../actions/common/filterList';
import {getAllTypes} from '../../actions/common/fetchAllTypes';
import {getAllTopics} from '../../actions/common/fetchAllTopics';
import {getFavouriteTasks} from '../../actions/task/fetchFavouriteTasks';
import {getFavouriteTaskSets} from '../../actions/taskSet/fetchFavouriteTaskSets';
import {getUserTasks} from '../../actions/task/fetchUserTasks';
import {getUserTaskSets} from '../../actions/taskSet/fetchUserTaskSets';
import {pickSelectedTask} from '../../actions/common/pickSelectedTask';
import {sortList} from '../../actions/common/sortList';
import {getLoggedInUser} from "../../actions/user/fetchLoggedInUserInfo";
import {namespace} from "../../util/namespaceNO";

class Home extends React.Component {
  constructor() {
    super();
    this.componentDidMount = this.componentDidMount.bind(this);
    this.exitModal = this.exitModal.bind(this);
    this.updateTaskList = this.updateTaskList.bind(this);
    this.filter = this.filter.bind(this);
    this.state = {
      filteredTasks: [],
      isFiltered: false,
      filteredTypes: [],
      taskTypeFilter: [],
      gradeFilter: [],
      difficultyFilter: [],
      topicFilter: [],
      typeFilter: [],
      userFilter: [],
      sorting: Sortings.NEWEST,
      loaded: false,
      isTaskPicker: false,
      maxItems: 18,
      searchFilter: []
    };

  }

  componentWillMount() {
    this.props.getAllTopics();
    this.props.getAllTypes();
  }

  /**
   * Waits for init data to be fetched, then redirects the user to login page if not
   * authenticated, else if sets the array of tasks to be rendered to all data
   * that is fetched and sorts it.
   *
   */
  componentDidMount() {
    this.props.getToken().then(() => this.getInitData().then(() => {
      this.setState({
        filteredTasks: combineLists(
          this.props.publishedTasks,
          this.props.publishedTaskSets,
          this.props.favouriteTasks,
          this.props.favouriteTaskSets),
        loaded: true
      });
      this.handleSortingChange();
    }));
  };

  async getInitData() {
    await Promise.all([
      this.props.getFavouriteTasks(),
      this.props.getFavouriteTaskSets(),
      this.props.getPublishedTasks(),
      this.props.getPublishedTaskSets(),
      this.props.getUserTasks(),
      this.props.getUserTaskSets(),
      this.props.getLoggedInUser(),
      this.props.getToken()
    ]);
  }

  /**
   * Awaits data from API and then execute the filter function again to render the correct
   * tasks and tasksets
   */
  updateTaskList() {
    this.updateTaskAndTaskSets().then(() => this.filter());
  }

  async updateTaskAndTaskSets() {
    await this.props.getPublishedTasks();
    await this.props.getPublishedTaskSets();
    await this.props.getUserTasks();
    await this.props.getUserTaskSets();
  }

  /**
   * Checks which modal type that is active and creates an exit component to be
   * rendered based on which modal that is open.
   * Also resets the forms and the arrays of selected tasks in redux store
   *
   */
  exitModal() {
    if (this.props.modalClass.modal === ModalType.NEWTASK || this.props.modalClass.modal === ModalType.NEWTASKSET) {
      let message;
      if (this.props.modalClass.modal === ModalType.NEWTASK) {
        message = namespace.deleteTask;
      }
      else if (this.props.modalClass.modal === ModalType.NEWTASKSET) {
        message = namespace.deleteTaskSet;
      }
      exit(message, () => {
        this.props.toggleModal(this.props.modalClass.class);
        this.props.pickTask(null, TaskSelectType.RESET);
        this.props.pickSelectedTask(null, TaskSelectType.RESET);
        this.props.reset('newTask');
        this.props.reset('newTaskSet');
      });
    }
    else {
      this.props.toggleModal(this.props.modalClass.class);
    }
  }

  removeIndex(array, item) {
    let removeIndex = array.indexOf(item);
    if (removeIndex > -1) {
      array.splice(removeIndex, 1);
    }
    return array;
  }

  /**
   * Pushes the type of filter into an array of chosen filters or removes the type of filter
   * if the filters sent in is empty. Then saves the array of filter into the state of the component
   * based on which type is inputted. Then filters and sorts all tasks with all filters and
   * set the state of the tasks and tasksets to be rendered
   *
   *
   * @param filters
   * @param filterType
   */
  filter(filters, filterType) {
    let {
      filteredTasks,
      filteredTypes,
      taskTypeFilter,
      gradeFilter,
      difficultyFilter,
      topicFilter,
      typeFilter,
      isFiltered,
      searchFilter,
      userFilter,
      sorting
    } = this.state;

    if (!filteredTypes.includes(filterType) && filterType) {
      filteredTypes.push(filterType);
    }
    else if (filters && filters.length < 1) {
      this.removeIndex(filteredTypes, filterType);
    }

    switch (filterType) {
      case FilterTypes.TASKTYPE:
        taskTypeFilter = filters;
        this.setState({taskTypeFilter: filters});
        break;
      case FilterTypes.GRADE:
        gradeFilter = filters;
        this.setState({gradeFilter: filters});
        break;
      case FilterTypes.DIFFICULTY:
        difficultyFilter = filters;
        this.setState({difficultyFilter: filters});
        break;
      case FilterTypes.TOPIC:
        topicFilter = filters;
        this.setState({topicFilter: filters});
        break;
      case FilterTypes.TYPE:
        typeFilter = filters;
        this.setState({typeFilter: filters});
        break;
      case FilterTypes.USER:
        userFilter = filters;
        this.setState({userFilter: filters});
        break;
      case FilterTypes.SEARCH:
        searchFilter = filters;
        this.setState({searchFilter: filters});
        break;
      default:
        break;
    }

    let combinedList = combineLists(this.props.publishedTasks, this.props.publishedTaskSets, this.props.favouriteTasks, this.props.favouriteTaskSets);
    combinedList = sortList(combinedList, sorting);
    filteredTasks = filterList(combinedList, filteredTypes, taskTypeFilter, gradeFilter, difficultyFilter, typeFilter, topicFilter, searchFilter, userFilter);
    this.setState({filteredTasks: filteredTasks, isFiltered: isFiltered});

  }

  handleSortingChange = (newSorting) => {
    const {filteredTasks, sorting} = this.state;
    if (newSorting) {
      this.setState({filteredTasks: sortList(filteredTasks, newSorting), sorting: newSorting});
    }
    else {
      this.setState({filteredTasks: sortList(filteredTasks, sorting)});
    }
  };

  showMore = () => {
    const {maxItems} = this.state;
    this.setState({maxItems: maxItems + 20});
  };

  searchChange = (e) => {
    if (e.target.value === '') {
      this.filter([], FilterTypes.SEARCH);
    }
    else {
      this.filter(e.target.value.toLowerCase(), FilterTypes.SEARCH);
    }
  };

  /**
   * Loops all the array of selected tasks from redux store and returns true
   * if the tasks exsist in one of them
   *
   * @param id
   * @param type
   * @return boolean
   */
  isSelected = (id, type) => {
    let active = false;
    if (type === TaskType.TASK) {
      this.props.pickedGlobalTasks.forEach((task) => {
        if (task.id === id) {
          active = true;
        }
      });
      this.props.pickedTasks.forEach((task) => {
        if (task.id === id) {
          active = true;
        }
      });
      this.props.pickedSelectedTasks.forEach((task) => {
        if (task.id === id) {
          active = true;
        }
      });
    }
    else {
      this.props.pickedGlobalTaskSets.forEach((taskSet) => {
        if (taskSet.id === id) {
          active = true;
        }
      });
    }
    return active;
  };

  render() {
    return (
      <section>
        <Header/>
        <div className="container  not-selectable">
          <div className="columns">
            <div className="column is-2">
              <Sidebar
                filter={this.filter}
                types={this.props.allTypes}
                topics={this.props.allTopics}
                authentication={this.props.authentication}
                userTasks={this.props.userTasks}
                userTaskSets={this.props.userTaskSets}
              />
            </div>
            <div className="column  is-10  main-content">
              <Topbar
                sorting={this.state.sorting}
                handleSortingChange={this.handleSortingChange}
                searchChange={this.searchChange}
              />
              <TaskList
                {...this.props}
                publishedTasks={this.props.publishedTasks}
                tasks={this.state.filteredTasks}
                publishedTaskSets={this.props.publishedTaskSets}
                loaded={this.state.loaded}
                isTaskPicker={this.state.isTaskPicker}
                maxItems={this.state.maxItems}
                showMore={this.showMore}
                isSelected={this.isSelected}
              />
            </div>
          </div>
        </div>
        <Modal
          updateTaskList={this.updateTaskList}
          {...this.props}
          types={this.props.allTypes}
          topics={this.props.allTopics}
          exit={this.exitModal}
          loaded={this.state.loaded}
          isSelected={this.isSelected}
        />
      </section>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    reset: reset,
    toggleModal: toggleModal,
    pickTask: pickTask,
    pickSelectedTask: pickSelectedTask,
    getPublishedTaskSets: getPublishedTaskSets,
    getPublishedTasks: getPublishedTasks,
    getAllTypes: getAllTypes,
    getAllTopics: getAllTopics,
    getFavouriteTasks: getFavouriteTasks,
    getFavouriteTaskSets: getFavouriteTaskSets,
    getUserTasks: getUserTasks,
    getUserTaskSets: getUserTaskSets,
    getLoggedInUser: getLoggedInUser,
    getToken: getToken
  }, dispatch);

const mapStateToProps = state => {
  return {
    allTopics: state.allTopics,
    allTypes: state.allTypes,
    authentication: state.authentication,
    modalClass: state.modalClass,
    publishedTaskSets: state.publishedTaskSets,
    publishedTasks: state.publishedTasks,
    favouriteTasks: state.favouriteTasks,
    favouriteTaskSets: state.favouriteTaskSets,
    userTasks: state.userTasks,
    userTaskSets: state.userTaskSets,
    pickedGlobalTasks: state.pickedGlobalTasks,
    pickedSelectedTasks: state.pickedSelectedTasks,
    pickedTasks: state.pickedTasks,
    pickedGlobalTaskSets: state.pickedGlobalTaskSets,
    loggedInUser: state.loggedInUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
