import * as React from 'react';
import {deleteUser, logout} from "../../oauthConfig";

export const Info = (props) => {
  const {infoPreviewClass, exit, token} = props;

  return (
    <div className={'box  ' + infoPreviewClass}>
      <i className="fa  fa-close  info-exit-icon" onClick={exit}/>
      <p className="strongText">KF</p>
      <div className="info-border"/>
      <div className="info-text__container  columns">
        <div className="info-text-left  column is-half">
          <p className="info-title">
            Besøksadresse
          </p>
          <p className="lightText">
            Haakon VIIs gate 9
            0161 Oslo
          </p>
          <div>
            <a href="mailto:ematte@kf.no" className="info-title  info-title--email">
              Send e-post
            </a>
          </div>
          <div>
            <p className="info-title  info-title--telephone">
              <a href="tel:24132850">24 13 28 50</a>
            </p>
          </div>
        </div>
        <div className="info-text-right  column is-half">
          <p className="info-title">
            Postadresse
          </p>
          <p className="lightText">
            Postboks 1263 Vika
            0111 Oslo
          </p>
          <p className="info-title">
            Org.nr: 910 976 222
          </p>
        </div>
      </div>
      <div className="info-border"/>
      <button className="logout-button" onClick={() => logout(token)}>Logg ut</button>
      <button className="delete-user-button" onClick={() => window.location = window.location.origin + '/deleteuser'}>Slett bruker</button>
    </div>
  );
};
