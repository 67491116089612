import * as React from 'react';

export const BottomBarRating = (props) => {

  const {
    rating
  } = props;
  return (
    <div className="bottom-taskbar__rating">
      <i className="fa fa-star  left-bottom--icon"/>
      {
        !rating ?
        <p className="card-rating"> Ingen vurderinger </p>
        :
          <p className="card-rating"> {rating.toFixed(1)}</p>
      }
    </div>
  );
};