const allTasksReducer = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_TASKS_REQUEST':
      return state;
    case 'FETCH_TASKS_SUCCESS':
      return action.payload;
    default:
      return state;
  }
};

export default allTasksReducer;
