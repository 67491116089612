import * as React from 'react';
import Filters from '../../containers/filter/filters';
import { FilterTypes } from '../../declarations';

class Sidebar extends React.Component {
  constructor () {
    super();
    this.state = {
      isModalFilter: false,
      allIsActive: true,
    };
    this.switchToMine = this.switchToMine.bind(this);
    this.switchToAll = this.switchToAll.bind(this);
  }

  /**
   * Sets all tasks to be rendered
   */
  switchToAll() {
    let {allIsActive} = this.state;

    if(!allIsActive) {
      this.setState({allIsActive: true});
      this.props.filter([], FilterTypes.USER);
    }
  }

  /**
   * Filters on only the tasks and tasksets that is created by the logged in user
   */
  switchToMine() {
    let {allIsActive} = this.state;
    if(allIsActive) {
      const filters = {"task": this.props.userTasks, "tasksets": this.props.userTaskSets};
      this.setState({allIsActive: false});
      this.props.filter(filters, FilterTypes.USER);
    }
  }

  render () {
    return (
      <section>
        <div className="side-bar">
          <div className="tabs">
            <ul>
              <li className={this.state.allIsActive ? "is-active" : ""} onClick={this.switchToAll}><a>Alle</a></li>
              <li className={this.state.allIsActive ? "" : "is-active"} onClick={this.switchToMine}><a>Mine</a></li>
            </ul>
          </div>
          <Filters
            hidden={this.state.hiddenFilters}
            isModalFilter={this.state.isModalFilter}
            types={this.props.types}
            topics={this.props.topics}
            filter={this.props.filter}
            {...this.props}
          />
        </div>
      </section>
    );
  }
}

export default Sidebar;
