/**
 * Toggles the info box popup
 * @param className
 */
export const showInfo = (className) => {
  if (className === 'is-hidden') {
    return (dispatch) => {
      dispatch(showPreview(''));
    };
  }
  else {
    return (dispatch) => {
      dispatch(hidePreview('is-hidden'));
    };
  }
};

const showPreview = (payload) => {
  return {
    type: 'TOGGLE_INFO_PREVIEW_ON',
    payload
  };
};

const hidePreview = (payload) => {
  return {
    type: 'TOGGLE_INFO_PREVIEW_OFF',
    payload
  };
};
