import {get} from "../../apiService";

/**
 * Fetches all tasks that belongs to the logged in user and dispatches to redux store
 */
export const getUserTasks = () => {
  return (dispatch, getState) => {
    return get('/task/user',getState().authentication.access_token).then((json) => dispatch(fetchUserTasksSuccess(json)));
  };
};

const fetchUserTasksSuccess = (payload) => {
  return {
    type: 'FETCH_USER_TASKS_SUCCESS',
    payload
  };
};
