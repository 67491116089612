import * as React from 'react';
import { ModalType } from '../modal';

export const BottomRowButtons = (props) => {
  return (
    <div className="modal-row">
      <div className="modal-bottom-button-row">
        <div className="modal-bottom__button">
          {
            props.page > 1 &&
            <button type="submit" className="modal-bottom__button--previous  floatLeft"
                    onClick={props.previousPage}>
              Tilbake
            </button>
          }
          <div className="floatRight">
            {
              props.hasRemoveTasks && props.showRemoveButton &&
              <button
                className="modal-bottom__button--remove"
                disabled={props.isDisabled}
                onClick={props.removeTasks}>
                Fjern valgte
              </button>
            }
            {
              props.showEmptyTaskSet && props.modalType === ModalType.NEWTASKSET &&
              <button className="modal-bottom__button--save">Publiser tomt oppgavesett</button>
            }
            {
              props.page === props.total &&
              <button type="submit" className="modal-bottom__button--publish">
                <i className="fa  fa-share  publish-icon"/>
                Publiser i eMatte
              </button>
            }
            {
              props.page < props.total && !props.showEmptyTaskSet &&
              <button type="submit" className="modal-bottom__button--next">
                Neste
                <i className="fa  fa-chevron-right  chevron-icon-right"/>
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
