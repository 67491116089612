import React, {Component} from 'react';
import NewTaskSetFirstPage from './newTaskSetFirstPage';
import NewTaskSetSecondPage from './newTaskSetSecondPage';
import {connect} from "react-redux";
import {formValueSelector} from "redux-form";
import {createTaskSetDTO} from "../../actions/generateTaskSet/createTaskSetDTO";
import {toggleModal} from "../../actions/common/toggleModal";
import {bindActionCreators} from "redux";

class NewTaskSetForm extends Component {

  constructor() {
    super();
    this.state = {
      page: 1,
      taskSetDto: null,
      form: null
    }
  }

  nextPage = () => this.setState({page: this.state.page + 1});

  previousPage = () => this.setState({page: this.state.page - 1});

  generateTaskSet = (formState) => {
    this.setState({
      form: formState,
      taskSetDto: createTaskSetDTO(formState, this.props)
    }, () => this.nextPage());
  };

  generateTaskSetFromState = () => {
    this.setState({taskSetDto: createTaskSetDTO(this.state.form, this.props)});
  };

  render() {
    const {page} = this.state;
    return (
      <section>
        <div className="modal-content__container">
          {page === 1 &&
          <NewTaskSetFirstPage
            nextPage={this.nextPage}
            generateOrCreate={this.props.generateOrCreate}
            mainSubject={this.props.mainSubject}
            generateTaskSet={this.generateTaskSet}
            subjects={this.props.grade}/>
          }
          {page === 2 &&
          <NewTaskSetSecondPage
            previousPage={this.previousPage}
            taskSetDto={this.state.taskSetDto}
            generateTaskSet={this.generateTaskSetFromState}
            subjects={this.props.grade}
            publishTaskSet={this.publishTaskSet}
            toggleModal={this.props.toggleModal}/>
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector('generateTaskSet');
  return {
    title: selector(state, 'title'),
    grade: selector(state, 'gradesDropdown'),
    mainSubject: selector(state, 'subjectDropdown'),
    generateOrCreate: selector(state, 'generateOrCreate'),
    taskAmount: selector(state, 'taskAmount'),
    customTaskAmount: selector(state, 'customTaskAmount'),
    topics: state.allTopics,
    types: state.allTypes
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleModal: toggleModal
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(NewTaskSetForm);
