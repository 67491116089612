import React, {Component} from 'react';
import FontAwesome from 'react-fontawesome'
import onClickOutside from "react-onclickoutside";
import './dropdownMenu.css';

class DropdownWithoutBorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      dropdownTitle: this.props.dropdownTitle
    }
  }

  // used by onClickOutside library
  handleClickOutside(e) {
    this.setState({
      listOpen: false,
    })
  }

  handleChange = (title, value) => {
    this.props.onChange(value);
    this.toggleList();
    this.setState({dropdownTitle: title})
  };

  toggleList = () => {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }))
  };

  render() {
    const {list} = this.props;
    const {listOpen, dropdownTitle} = this.state;
    return (
      <div className="dropdown-wrapper">
        <div className="dropdown-without-border-content">
          <div className="dropdown-header" onClick={this.toggleList}>
            <span className="dd-header-title">{dropdownTitle}</span>
            {listOpen
              ? <FontAwesome name="angle-up" className="dropdown-without-border-angle" size="2x"/>
              : <FontAwesome name="angle-down" className="dropdown-without-border-angle" size="2x"/>
            }
          </div>

          {listOpen && <ul className="dropdown-list without-border-list">
            {list.map((item) => (
              <li className="dropdown-list-item without-border-item" key={item.key()}
                  onClick={(e) => {
                    this.handleChange(item.description(), item.value());
                    e.stopPropagation()
                  }}>
                {item.description()}
              </li>
            ))}
          </ul>}
        </div>
      </div>
    )
  }
}

export default onClickOutside(DropdownWithoutBorder)