import React from 'react'
import {namespace} from "../../util/namespaceNO";
import './exportTaskToPdfButton.css';
import {ALPHABET} from "../../declarations";
import {PictureViewer} from "../common/picture/pictureViewer";
import {FormulaViewer} from "../task/formula/formulaViewer";

export const TaskSetPdf = (props) => {
  return (
    <div id="task-set-pdf">
      <div id="task-set-tasks">
        <h4 className="task-set-pdf-header-big">{props.title}</h4>
        <h5 className="task-set-pdf-header-medium">{props.description}</h5>
        {
          props.descriptionPicture &&
          <PictureViewer imageId={props.descriptionPicture.id}/>
        }
        <h5 className="task-set-pdf-header-big">{namespace.tasks + ':'}</h5>
        <div className="task-set-task-container">
          {props.taskSetDto.tasks.map((task, index) => (
            <div key={index} className="task-container pdf-task-container">
              {
                task.title.substr(0, 12) === "Puggeoppgave" ?
                  <div className="task-set-pdf-header-big">{index + 1 + ") "}</div>
                  :
                  <div className="task-set-pdf-header-big"> {index + 1 + ") " + task.title}</div>
              }
              <div className="task-description">{task.description}</div>
              {
                task.descriptionPicture &&
                <PictureViewer imageId={task.descriptionPicture.id}/>
              }
              {
                task.formula &&
                <FormulaViewer formula={task.formula}/>
              }
              {task.subTask &&
              <div>
                {task.subTask.map((subTask, i) =>
                  subTask.description &&
                  <div key={i}>
                    <p className="task-set-pdf-sub-task">
                      {ALPHABET[i] + ") " + subTask.description}</p>
                    {subTask.formula &&
                    <FormulaViewer formula={subTask.formula}/>}
                  </div>
                )}
              </div>
              }
            </div>
          ))}
        </div>
      </div>
      {props.showSolution &&
      <div id="task-set-solution">
        <h4 className="task-set-pdf-header-big">
          {props.title !== undefined ? namespace.solution + ' - ' + props.title : namespace.solution + ':'}
        </h4>
        <div className="tasks-in-taskset-container">
          {props.taskSetDto.tasks.map((task, index) => (
            <div key={"container" + index} className="task-container task-set-task-container">
              {(index + 1) + ") " + task.solution}
              {task.subTask && task.subTask.map((subTask, i) =>
                subTask.solution &&
                <p className="task-set-pdf-sub-task" key={i}>{ALPHABET[i] + ") " + subTask.solution}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      }
    </div>
  )
};
