import * as React from 'react';

export const Pageinator = (props) => {

  const page = [];
  for (let i = 1; i <= props.total; i++) {
    page.push(
      <i
        key={i}
        className={props.page >= i ? 'pager  fa  fa-circle' : 'pager  fa  fa-circle-o'}
      />
    );
  }

  return page;
};
