/**
 * Return an array with error messages to validate the form input data
 *
 * @param values
 * @return Array
 */
const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Obligatorisk*';
  }
  else if (values.title.length > 100) {
    errors.title = 'Tittelen kan ikke være lenger enn 100 tegn';
  }

  if (!values.description) {
    errors.description = 'Obligatorisk';
  }
  else if (!values.description.length > 1000) {
    errors.description = 'Beskrivelsen kan ikke være lenger enn 1000 tegn';
  }

  if (!values.grade) {
    errors.grade = 'Obligatorisk*';
  }

  if (!values.difficulty) {
    errors.difficulty = 'Obligatorisk*';
  }

  if (!values.topic) {
    errors.topic = 'Obligatorisk*';
  }

  if (!values.types) {
    errors.type = {_error: 'Obligatorisk*'};
  }

  if (values.types) {
    let error = true;
    for (let key in values.types) {
      if (values.types[key]) {
        error = false;
      }
    }
    if (error) {
      errors.type = {_error: 'Obligatorisk*'};
    }
  }

  if (!values.topics) {
    errors.topic = {_error: 'Obligatorisk*'};
  }

  if (values.topics) {
    let error = true;
    for (let key in values.topics) {
      if (values.topics[key]) {
        error = false;
      }
    }
    if (error) {
      errors.topic = {_error: 'Obligatorisk*'};
    }
  }

  if (values.hint) {
    if (values.hint.length > 1000)
      errors.hint = 'Må være færre enn 1000 bokstaver';
  }

  return errors;
};

export default validate;
