import React from 'react';
import {disclaimerAlert} from "./common/alerts/disclaimerAlert";

export const Disclaimer = (props) => {

  const onConfirm = () => {
    window.location = "/home";
  };

  return(
    <div>
      {disclaimerAlert(onConfirm)}
    </div>
  );
};
