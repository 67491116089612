import * as React from 'react';
import {TaskViewer} from '../common/modal/taskViewer';
import {TaskSetProperties} from '../common/modal/taskSetProperties';
import {TopBarButtons} from '../common/modal/topBarButtons';
import {TaskListEmpty} from '../common/taskList/taskListEmpty';
import {TopBarRating} from "../common/modal/topBarRating";
import {Rating} from "../common/modal/rating";
import {Comments} from "../common/modal/comments";
import {Description} from "../common/description/description";
import ExportTaskToPdfButton from "../pdfgeneration/exportTaskToPdfButton";
import {OptionsButton} from "../options/optionsButton";
import Report from "../common/modal/report";
import {namespace} from "../../util/namespaceNO";
import {REPORT_TYPE} from "../../util/enums/reportType";
import {TaskSetPdf} from "../pdfgeneration/taskSetPdf";

export default class TaskSetPreview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {solution: false}
  }

  updateShowSolution = (solution) => {
    this.setState({solution: solution});
  };

  render() {

    const {
      taskSet,
      onFavouriteClick,
      exit,
      isFavourite,
      taskSetRatingClick,
      addComment,
      showMoreOptionModal,
      hideMoreOptionModal,
      hideReportModal,
      showReportModal,
      loggedInUserId,
      moreOptionsModal,
      report
    } = this.props;

    const optionProps = {
      showMoreOptionModal,
      hideMoreOptionModal,
      showReportModal,
      moreOptionsModal,
      deleteUrl: '/taskset/' + taskSet.id,
      taskStatusEnum: taskSet.taskStatusEnum,
      userAdmin: taskSet.userAdmin,
      createdBy: taskSet.createdBy,
      taskSet,
      loggedInUserId,
      confirmationMessage: namespace.deleteTask,
    };

    const pdfObject = {
      title: taskSet.title,
      showSolution: false,
      taskSetDto: {title: taskSet.title, tasks: taskSet.tasks},
      description: taskSet.description,
      descriptionPicture: taskSet.descriptionPicture
    };

    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={exit}/>
        <div className="modal-content" id="modal">
          <div className="modal-content__container" onClick={hideMoreOptionModal}>
            <div className="modal-rating__container">
              <TopBarRating
                rating={taskSet.totalRating}
                amountOfRatings={taskSet.amountOfRatings}
              />
            </div>
            <div className="modal-topbar-buttons  not-selectable">
              <OptionsButton
                optionProps={optionProps}
              />
              <TopBarButtons
                onFavouriteClick={onFavouriteClick}
                isFavourite={isFavourite}
              />
              <ExportTaskToPdfButton id="task-set-preview-modal-id" updateShowSolution={this.updateShowSolution}/>
            </div>
            <div className="modal-task-properties__container">
              <TaskSetProperties {...taskSet}/>
            </div>
            {report &&
            <Report
              typeOfReport={REPORT_TYPE.TASK_SET.value}
              id={taskSet.id}
              message={namespace.reportTaskSet}
              hideReportModal={hideReportModal}
            />
            }
            <div id="task-set-preview-modal-id">
              <div className="task-set-modal-title__container">
                <div className="task-set-modal-title">
                  <i className="fa  fa-files-o  task-set-modal-title--icon"/>
                  {taskSet.title}
                </div>
                <div className="task-set-description">
                  <Description
                    description={taskSet.description}
                    image={taskSet.descriptionPicture}
                  />
                </div>
              </div>
              {
                taskSet.tasks.length < 1 &&
                <div className="empty-box__taskset">
                  <TaskListEmpty message="Dette oppgavesettet er tomt."/>
                </div>
              }
              {
                taskSet.tasks.map((task, index) =>
                  <div key={index}>
                    <div className="task-set-task-viewer-row">
                      <p className="task-set-task-viewer-index">Oppgave {index + 1} </p>
                      <div className="modal-task-viewer__container">
                        <TaskViewer task={task}/>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <Rating
              isTaskSet={true}
              taskSetRatingClick={taskSetRatingClick}
              userRating={taskSet.usersRating}
            />
            <Comments
              comments={taskSet.comments}
              addComment={addComment}
            />
            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={exit}
            />
            <TaskSetPdf
              taskSetDto={pdfObject.taskSetDto}
              showSolution={this.state.solution}
              title={pdfObject.title}
              description={pdfObject.description}
              descriptionPicture={pdfObject.descriptionPicture}
            />
          </div>
        </div>
      </div>
    )
  }
};

