import React from 'react';
import './toggleButton.css'
import {ToggleButtonOption} from "./toggleButtonOptions";

export default class ToggleButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: [],
      selectedValue: [],
    };
  }

  componentDidMount() {
    this.setToggleButtonElementsWidth()
  }

  componentDidUpdate() {
    this.setToggleButtonElementsWidth()
  }

  handleChange = (index, value) => {
    let selected = this.state.selectedIndex.slice();

    if (this.checkIfArrayHasValue(index)) {
      const selectedElementIndex = selected.map(e => {
        return e.index;
      }).indexOf(index);
      selected.splice(selectedElementIndex, 1)
    } else {
      selected.push({index, value});
    }
    this.setState({
        selectedIndex: selected,
        selectedValue: value
      },
      this.props.callback(selected),
      this.props.input.onChange(selected)
    );
  };

  checkIfArrayHasValue = (i) => {
    for (let subject of this.state.selectedIndex) {
      if (subject.index === i) {
        return true
      }
    }
    return false
  };

  setToggleButtonElementsWidth = () => {
    const minWidth = this.props.minWidth;
    let toggleButtonElement = document.getElementsByClassName('toggle-button-option');

    toggleButtonElement = Array.prototype.slice.call(toggleButtonElement);
    toggleButtonElement.forEach(function (element) {
      element.style.minWidth = minWidth + 'px';
    });
  };

  render() {
    return (
      <div>
        <div className="toggle-button-header-container">
          <h1>{this.props.title}</h1>
          {
            this.props.meta.touched && this.props.meta.error &&
            <span className="error-message-label">{this.props.meta.error}</span>
          }
        </div>
        <div className="toggle-button">
          {
            this.props.values.map((value) =>
              <ToggleButtonOption
                key={value.description()}
                handleChange={this.handleChange}
                name={value}
                displayName={value.value()}
                isChecked={this.checkIfArrayHasValue(value.key())}
                index={value.key()}
                minWidth={this.props.minWidth}
                selectDefault={this.props.selectDefault}
              />
            )
          }
        </div>
      </div>
    )
  }
}

