import {loggedInUserTypes} from "../../types/user/loggedInUser";

const loggedInUserReducer = (state = {}, action) => {
  switch (action.type) {
    case loggedInUserTypes.success:
      return {...action.payload};
    default:
      return state;
  }
};

export default loggedInUserReducer;
