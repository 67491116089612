import React from 'react';
import {PictureWrapper} from "../picture/pictureWrapper";
import './description.css';
import {FormulaViewer} from "../../task/formula/formulaViewer";

export const Description = (props) => {
  const {description, image, formula} = props;
  return (
    <div className="description-container">
      <div>
        <p>{description}</p>
      </div>
      {
        formula &&
        <FormulaViewer
          formula={formula}
        />
      }
      {
        image &&
        <div>
          <PictureWrapper image={image}/>
        </div>
      }
    </div>
  )
};