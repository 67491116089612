import React from 'react';
import FilterButton from '../../../../containers/filter/filterButton';

const FilterType = (props) => {
  const {
    label,
    types,
    filter
  } = props;

  types.length > 0  && types.sort(function (a, b) {
    let textA = a.type;
    let textB = b.type;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  return (
    <div>
      <label className='filter-label'>{label}</label>
      <div className="filter-entity">
        {types.length > 0  &&  types.map((type, i) =>
          <div key={i} className="filter-button--small">
            <FilterButton filter={filter} filterName={type.type}/>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterType;
