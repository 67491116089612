import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from '../containers/home/home';
import TaskDetails from "../containers/task/taskDetails";
import TaskSetDetails from "../containers/taskSet/taskSetDetails";
import { Disclaimer } from "./disclaimer";
import {emailPrompt} from './emailPropmpt';
import OAuthSpinner from "./oAuthSpinner";
import loggedOut from "../containers/loggedOut/loggedOut";
import LoginPage from '../containers/loginPage/LoginPage';
import {deleteUserPrompt} from './deleteUserPrompt';

const App = props => (
  <span>
    <Switch>
      <Route exact path="/task/:id" component={TaskDetails} />
      <Route exact path="/taskset/:id" component={TaskSetDetails} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/disclaimer" component={Disclaimer} />
      <Route exact path="/noemail" component = {emailPrompt} />
      <Route exact path="/deleteuser" component = {deleteUserPrompt} />
      <Route exact path="/login/skolon" component={OAuthSpinner} />
      <Route exact path="/login/facebook" component={OAuthSpinner} />
      <Route exact path="/loggedOut" component={loggedOut} />
      <Route exact path="/login/page" component={LoginPage} />
      <Route path="/" render={() => (<Redirect to="/home" />)} />
    </Switch>
  </span>
);

export default App;
