const showProfileInfoReducer = (state = "is-hidden", action) => {
  switch (action.type){
    case 'TOGGLE_PREVIEW_ON':
      return action.payload;
    case 'TOGGLE_PREVIEW_OFF':
      return action.payload;
    default:
      return state;
  }
};

export default showProfileInfoReducer;
