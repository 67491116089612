import {namespace} from "../namespaceNO";
import math from 'mathjs'

class Subject {
  formats() {
    return [{index: 0, format: 2}, {index: 1, format: 3}, {index: 2, format: 4}];
  }

  evaluateExpression(expressionArray, subjectsUsed) {
    let expression = expressionArray.join(' ');
    let result = math.eval(expression).toFixed(2);
    let formattedResult = result.substr(result.length - 3) === ".00" ? Number.parseFloat(result).toFixed() : result;
    let formattedExpression = expression.replace(/\//g, ':');
    return {description: formattedExpression, solution: formattedResult, taskTopics: subjectsUsed}
  }
}

class Addition extends Subject {
  key = () => 1;
  description = () => namespace.addition;
  value = () => '+';
  operator = () => '+';
}

class Subtraction extends Subject {
  key = () => 2;
  description = () => namespace.subtraction;
  value = () => '-';
  operator = () => '-';
}

class Multiplication extends Subject {
  key = () => 3;
  description = () => namespace.multiplication;
  value = () => '*';
  operator = () => '*';
}

class Division extends Subject {
  key = () => 4;
  description = () => namespace.division;
  value = () => ':';
  operator = () => '/';
}

export const SUBJECT = Object.freeze(
  {
    ADDITION: new Addition(),
    SUBTRACTION: new Subtraction(),
    DIVISION: new Division(),
    MULTIPLICATION: new Multiplication(),
  });
