import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './report.css'
import {namespace} from "../../../util/namespaceNO";
import {post} from "../../../apiService";

export default class Report extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};
  }

  publishReport = () => {
    const report = {
      id: this.props.id,
      comment: this.state.value,
      reportType: this.props.typeOfReport,
    };
    this.props.hideReportModal();
    return post('/report', report)
  };

  handleChange = (event) => {
    this.setState({value: event.target.value});
  };

  render() {
    return (
      <div>
        <div className="report-modal-background"/>
        <div className='report-modal modal-content__container' onClick={(e) => e.stopPropagation()}>
          <h1 className='report-header'>{this.props.message}</h1>
          <textarea className="report-textarea" value={this.state.value} onChange={this.handleChange}/>
          <br/>
          <div className="react-confirm-alert-button-group">
            <button onClick={this.props.hideReportModule}>{namespace.cancel}</button>
            <button onClick={this.publishReport}>{namespace.report}</button>
          </div>
        </div>
      </div>
    )
  }
}
