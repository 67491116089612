import React from 'react';
import './taskFormatPreview.css'
import {namespace} from "../../util/namespaceNO";
import {generateTask} from "../../services/taskGenerator/taskGenerator";
import {sortByLength} from "../../services/util/utils";
import {randomNumberGenerator} from "../../services/randomNumberGenerator";
import {DECIMAL} from "../../util/enums/decimals";

export const TaskFormatPreview = (parentState) => {
  let tasks = [];
  for (let i = 0; i < parentState.parentState.selectedFormat.length; i++) {
    let numberOfDecimalsList = parentState.parentState.selectedDecimals;
    let randomNumberOfDecimal = randomNumberGenerator(0, numberOfDecimalsList.length);
    if (numberOfDecimalsList === undefined || numberOfDecimalsList.length === 0) {
      numberOfDecimalsList = [{index: 0, value: DECIMAL.ZERO}];
    }
    let userInput = {
      mainSubject: parentState.parentState.mainSubject,
      format: parentState.parentState.selectedFormat[i].format,
      minimumDigit: parentState.parentState.minimumDigit,
      maximumDigit: parentState.parentState.maximumDigit,
      additionalSubjects: parentState.parentState.selectedAdditionalSubjects,
      numberOfDecimals: numberOfDecimalsList[randomNumberOfDecimal].value.numberOfDecimals,
    };
    tasks.push(generateTask(userInput));
  }

  return (
    <div className="task-format-preview-container">
      <h1>{namespace.preview}</h1>
      <div className="task-format-preview">
        {sortByLength(tasks).map((value, index) => (
          <h1 key={'task' + index}>{value.description + ' = ' + value.solution}</h1>
        ))
        }
      </div>
    </div>
  );
};
