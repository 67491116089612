import * as React from 'react';
import { connect } from 'react-redux';
import { showInfo } from '../../actions/user/showInfo';
import { bindActionCreators } from 'redux';
import { Info } from '../../components/header/info';

class ProfileBar extends React.Component {

  constructor () {
    super();
    this.state = {
      hiddenInfo: true
    };
  };

  /**
   * Hides or show the info box based on its state
   */
  showInfoClick = () => {
    const {hiddenInfo} = this.state;

    this.setState({hiddenInfo: !hiddenInfo});
    this.props.showInfo(this.props.infoPreviewClass);
  };

  render () {
    return (
      <div>
        <div className="header-icon__container  header-icon__container--info" onClick={this.showInfoClick.bind(this)}>
          <img className="header-icon  header-icon--info" src={require('../../resources/images/info.svg')}
               alt="Informasjonikon"
          />
        </div>
        <div className="header__overlay-box">
          <Info
            infoPreviewClass={this.props.infoPreviewClass}
            exit={this.showInfoClick.bind(this)}
            token={this.props.token}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    showInfo: showInfo
  }, dispatch);

const mapStateToProps = (state) => {
  return {
    infoPreviewClass: state.infoPreviewClass,
    token: state.authentication.access_token
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBar);
