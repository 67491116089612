import React from 'react';
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import store, { history } from './store';
import App from './components/App';
import './common.css';
import '../src/resources/css/font-awesome/css/font-awesome.min.css';
import {Route} from "react-router-dom";


const target = document.querySelector('#root');

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <section className="fullHeight">
        <Route component={App}/>
      </section>
    </ConnectedRouter>
  </Provider>,
  target
);
