import React from 'react';
import {PictureViewer} from "./pictureViewer";

export const PictureWrapper = (props) => {
  const {image} = props;

  if (!(image && image.id)) {
    return null
  }

  return (
    <PictureViewer imageId={image.id}/>
  )
};
