import React, {Component} from 'react';
import './exportTaskToPdfButton.css';
import {exportTaskToPdf} from "../../actions/task/exportTaskToPdf";
import {namespace} from "../../util/namespaceNO";

/**
 * Adds a button with event to create pdf from content within supplied div id
 */
export default class ExportTaskToPdfButton extends Component {

  constructor(props) {
    super(props);
    this.state = {solution: false}
  }

  handleChange() {
    this.setState({solution: !this.state.solution}, () => this.props.updateShowSolution(this.state.solution))
  }

  render() {
    const pdfElements = this.state.solution === true ? ['task-set-tasks', 'task-set-solution'] : ['task-set-tasks'];
    return (
      <div className="floatRight  toprow-btn">
        <label id="solution-label">{namespace.exportTaskToPdfWithSolution}</label>
        <input type="checkbox"
               name="solution"
               onClick={() => this.handleChange()}
               id="solution-checkbox"
               value={this.state.solution}/>
        <button id="export-pdf-btn" onClick={() => exportTaskToPdf(pdfElements)}>
          {namespace.exportTaskToPdfBtn}
        </button>
      </div>);
  }
}
