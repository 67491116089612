import {get} from "../../apiService";


/**
 * Fetches all ids for favourited tasks and dispatches data to redux store
 */
export const getFavouriteTasks = () => {
  return (dispatch, getState) => {
    return get("/task/favourite", getState().authentication.access_token)
      .then((json) => dispatch(fetchFavouriteTasksSuccess(json)));
  };
};

const fetchFavouriteTasksSuccess = (payload) => {
  return {
    type: 'FETCH_FAVOURITE_TASKS_SUCCESS',
    payload
  };
};
