import * as React from 'react';
import TaskForm from '../../containers/task/form/taskForm';
import TaskSetForm from '../../containers/taskSet/form/taskSetForm';
import NewTaskSetForm from '../../containers/newTaskSet/newTaskSetForm';

export const ModalType = Object.freeze({
  'TASK': 1,
  'TASKSET': 2,
  'NEWTASK': 3,
  'NEWTASKSET': 4,
  'ADDTASK': 5,
  'GENERATETASKSET': 6
});

export const Modal = (props) => {

  const {
    modalClass,
    exit,
    updateTaskList,
    types,
    topics,
    loaded,
    isSelected
  } = props;
  return (
    <div className={'modal  ' + modalClass.class}>
      <div className="modal-background" onClick={exit}/>
      <div className="modal-content" id="modalContent">
        {
          modalClass.modal === ModalType.NEWTASK &&
          <TaskForm updateTaskList={updateTaskList}/>
        }
        {
          modalClass.modal === ModalType.NEWTASKSET &&
          <TaskSetForm
            updateTaskList={updateTaskList}
            types={types}
            topics={topics}
            loaded={loaded}
            isSelected={isSelected}
          />
        }
        {
          modalClass.modal === ModalType.ADDTASK &&
          <TaskSetForm
            updateTaskList={updateTaskList}
            types={types}
            topics={topics}
            loaded={loaded}
            isSelected={isSelected}
          />
        }
        {
          modalClass.modal === ModalType.GENERATETASKSET &&
            <NewTaskSetForm />
        }
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={exit}
      />
    </div>
  );
};
