import { TaskType } from '../../declarations';

/**
 * Combines task and taskSets and sets the task or taskset as favourite based on
 * the favourite task/tasksets id's
 *
 * @param tasks, taskSets taskFavourites, taskSetFavourites
 * @param taskSets
 * @param taskFavourites
 * @param taskSetFavourites
 * @return Array
 */
export const combineLists = (tasks, taskSets, taskFavourites, taskSetFavourites) => {
  if (tasks) {
    tasks.forEach((task) => {
      task.isFavourite = taskFavourites && taskFavourites.includes(task.id);
      task.type = TaskType.TASK;
    });
  }

  if (taskSets) {
    taskSets.forEach((taskSet) => {
      taskSet.isFavourite = taskSetFavourites && taskSetFavourites.includes(taskSet.id);
      taskSet.type = TaskType.TASKSET;
    });
  }

  if (tasks && !taskSets) {
    return tasks;
  }
  else if (!tasks && taskSets) {
    return taskSets;
  }
  else {
    return tasks.concat(taskSets);
  }
};
