import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import authUserReducer from './user/authUserReducer';
import allTasksReducer from './task/allTasksReducer';
import allTaskSetReducer from './taskSet/allTaskSetReducer';
import publishedTaskSetReducer from './taskSet/publishedTaskSetReducer';
import modalReducer from './common/modalReducer';
import showProfileInfoReducer from './user/showProfileInfoReducer';
import showInfoReducer from './user/showInfoReducer';
import {reducer as reduxFormReducer} from 'redux-form';
import allTopicsReducer from './common/allTopicsReducer';
import allTypesReducer from './common/allTypesReducer';
import pickTaskReducer from './common/pickTaskReducer';
import pickSelectedTaskReducer from './common/pickSelectedTaskReducer';
import publishedTaskReducer from './task/publishedTaskReducer';
import pickGlobalTaskReducer from './common/pickGlobalTaskReducer';
import favouriteReducer from './task/favouriteReducer';
import favouriteTaskSetReducer from './taskSet/favouriteTaskSetReducer';
import pickGlobalTaskSetReducer from './common/pickGlobalTaskSetReducer';
import userTasksReducer from './task/userTasksReducer';
import userTaskSetsReducer from './taskSet/userTaskSetsReducer';
import loggedInUserReducer from './user/loggedInUserReducer';
import moreOptionsReducer from "./common/moreOptionsReducer";
import reportReducer from "./common/reportReducer";


export default combineReducers({
  routing: routerReducer,
  authentication: authUserReducer,
  allTasks: allTasksReducer,
  allTaskSets: allTaskSetReducer,
  publishedTasks: publishedTaskReducer,
  publishedTaskSets: publishedTaskSetReducer,
  modalClass: modalReducer,
  profileInfoPreviewClass: showProfileInfoReducer,
  infoPreviewClass: showInfoReducer,
  loggedInUser: loggedInUserReducer,
  form: reduxFormReducer,
  allTopics: allTopicsReducer,
  allTypes: allTypesReducer,
  pickedTasks: pickTaskReducer,
  pickedSelectedTasks: pickSelectedTaskReducer,
  pickedGlobalTasks: pickGlobalTaskReducer,
  pickedGlobalTaskSets: pickGlobalTaskSetReducer,
  favouriteTasks: favouriteReducer,
  favouriteTaskSets: favouriteTaskSetReducer,
  userTasks: userTasksReducer,
  userTaskSets: userTaskSetsReducer,
  moreOptions: moreOptionsReducer,
  report: reportReducer
});
