import React from 'react';

const textAreaInput = (props) => (
  <div>
    <label className="title-input-label  title-input-label--modal">{props.label}</label>
    {props.meta.touched && props.meta.error && <span className="error-message-label">{props.meta.error}</span>}
    <div>
      <textarea {...props.input} placeholder={props.placeholder} className="textarea-input" />
    </div>
  </div>
);

export default textAreaInput;
