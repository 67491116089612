import React from 'react';
import { Sortings } from '../../declarations';

const sortings = [];
for (let sort in Sortings) {
  sortings.push(Sortings[sort]);
}

export const SortDropdown = (props) => {
  const {hidden, onClick} = props;
  return (
    <div className='sort__dropdown'>
      <div className={'sort__dropdown--options  custom-dropdown-menu  ' +
      (hidden ? '' : 'custom-dropdown-menu--active')}>
        {
          sortings.map((sorting, i) => (
            <div
              key={i}
              className="sort__dropdown--option  lightText"
              onClick={() => {
                onClick(sorting);
              }}>
              {sorting}
            </div>
          ))
        }
      </div>
    </div>
  );
};
