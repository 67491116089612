import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Pageinator} from '../../../components/common/modal/pageinator';
import formValueSelector from 'redux-form/es/formValueSelector';
import TaskSetPage1 from './pages/taskSetPage1';
import TaskSetPage2 from './pages/taskSetPage2';
import {mapFormToTaskSetDTO} from '../../../actions/taskSet/mapFormToTaskSetDTO';
import {toggleModal} from '../../../actions/common/toggleModal';
import {reset} from 'redux-form';
import {pickTask} from '../../../actions/common/pickTask';
import {pickSelectedTask} from '../../../actions/common/pickSelectedTask';
import {getPublishedTasks} from '../../../actions/task/fetchPublishedTasks';
import {publisher} from '../../../components/common/alerts/publisher';
import {pickGlobalTask} from '../../../actions/common/pickGlobalTask';
import {ModalType} from '../../../components/common/modal';
import {filterList} from '../../../actions/common/filterList';
import {combineLists} from '../../../actions/common/combineLists';
import {FilterTypes, Sortings, TaskSelectType} from '../../../declarations';
import {sortList} from '../../../actions/common/sortList';
import {addTasksToTaskSet, createUpdatedTaskSet} from "../../../actions/taskSet/addTasksToTaskSet";
import {post} from "../../../apiService";

class TaskSetForm extends Component {

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.resetPages = this.resetPages.bind(this);
    this.chooseTasks = this.chooseTasks.bind(this);
    this.showMore = this.showMore.bind(this);
    this.updateTaskList = this.updateTaskList.bind(this);
    this.publishTaskSet = this.publishTaskSet.bind(this);
    this.toggleIsDisabled = this.toggleIsDisabled.bind(this);
    this.removeTasks = this.removeTasks.bind(this);
    this.switchToNewTaskSet = this.switchToNewTaskSet.bind(this);
    this.handleSortingChange = this.handleSortingChange.bind(this);
    this.handleSortDropdownClick = this.handleSortDropdownClick.bind(this);
    this.filter = this.filter.bind(this);
    this.state = {
      page: 1,
      taskSetDTO: {},
      pickedTasks: [],
      isDisabled: true,
      counter: 0,
      showEmptyTaskSet: true,
      modalType: this.props.modalClass.modal,
      hiddenFilters: false,
      filteredTasks: combineLists(this.props.publishedTasks, null, this.props.favouriteTasks),
      isFiltered: false,
      filteredTypes: [],
      taskTypeFilter: [],
      gradeFilter: [],
      difficultyFilter: [],
      topicFilter: [],
      typeFilter: [],
      searchFilter: [],
      hiddenSort: true,
      sorting: Sortings.NEWEST,
      maxItems: 9,
    };
  }

  componentDidMount() {
    this.chooseTasks();
    this.updateTaskList();
    this.toggleIsDisabled();
    this.toggleRemoveButton();
    this.handleSortingChange();
    this.props.pickGlobalTask(null, TaskSelectType.RESET);
  }

  resetPages() {
    this.setState({page: 1});
  }

  /**
   * Creates a TaskSetEntity of the values from the form. If adding to taskset, the taskset
   * selected by the user is chosen and sent in to the TaskSetEntity parser.
   * Then publishes the taskset
   *
   * @param values
   */
  nextPage(values) {
    if (this.state.modalType === ModalType.NEWTASKSET) {
      this.setState({taskSetDTO: mapFormToTaskSetDTO(values, this.state.pickedTasks, this.state.taskSetDTO)});
    } else {
      // i.e. ModalType.ADDTASK
      this.setState({
        taskSetDTO: createUpdatedTaskSet(this.props.userTaskSets, values.taskSet, this.state.pickedTasks)
      });
    }
    if (this.state.showEmptyTaskSet) {
      setTimeout(() => {
        this.publishTaskSet();
      });
    }
    else {
      this.setState({page: this.state.page + 1});
    }
    this.setState({pickedTasks: []});
  }

  previousPage() {
    this.setState({page: this.state.page - 1});
  }

  /**
   * Puts the selected tasks from global redux array from both landing page and taskPicke component,
   * and adds them to the array of picked tasks to create a taskSet with.
   */
  chooseTasks() {
    let pickedTasks = this.props.pickedTasks.map((task) => {
      return task;
    });
    this.state.pickedTasks.map((task) => {
      return pickedTasks.push(task);
    });
    this.props.pickedGlobalTasks.map((task) => {
      return pickedTasks.push(task);
    });

    this.updateTaskList();
    this.setState({pickedTasks: pickedTasks}, () => {
      this.toggleRemoveButton();
    });
  }

  /**
   * Removes tasks from the array of chosen tasks to be added in a taskset.
   *
   * @param e
   */
  removeTasks(e) {
    e.preventDefault();
    let selectedTasks = this.state.pickedTasks.map((task) => {
      return task;
    });
    this.props.pickedSelectedTasks.forEach((picked) => {
      selectedTasks = selectedTasks.filter((task) => {
        return task.id !== picked.id;
      });
    });
    this.props.pickSelectedTask(null, TaskSelectType.RESET);
    this.setState({pickedTasks: selectedTasks}, () => {
      this.updateTaskList();
      this.toggleRemoveButton();
    });
  }

  /**
   * Updates the tasks to be rendered, filters out the tasks that is selected.
   * Then sets the state of the tasks to be rendered
   */
  updateTaskList() {
    let {
      filteredTypes,
      taskTypeFilter,
      gradeFilter,
      difficultyFilter,
      topicFilter,
      typeFilter,
      searchFilter
    } = this.state;
    let combinedList = combineLists(this.props.publishedTasks, null, this.props.favouriteTasks);

    this.props.pickedTasks.forEach((picked) => {
      combinedList = combinedList.filter((task) => {
        return task.id !== picked.id;
      });
    });

    this.props.pickedGlobalTasks.forEach((picked) => {
      combinedList = combinedList.filter((task) => {
        return task.id !== picked.id;
      });
    });

    this.state.pickedTasks.forEach((picked) => {
      combinedList = combinedList.filter((task) => {
        return task.id !== picked.id;
      });
    });

    this.props.pickTask(null, TaskSelectType.RESET);

    if (filteredTypes.length > 0) {
      combinedList = filterList(combinedList, filteredTypes, taskTypeFilter, gradeFilter, difficultyFilter, typeFilter, topicFilter, searchFilter);
    }

    this.setState({filteredTasks: combinedList}, () => {
      this.toggleIsDisabled();
    });
  }

  /**
   * Action to show more tasks and tasksets
   */
  showMore() {
    this.setState({maxItems: this.state.maxItems + 9});
  }

  toggleIsDisabled() {
    setTimeout(() => {
      this.setState({isDisabled: this.props.pickedSelectedTasks.length < 1});
    });
  }

  toggleRemoveButton() {
    if (this.state.pickedTasks.length > 0) {
      this.setState({showRemoveButton: true, showEmptyTaskSet: false});
    }
    else {
      this.setState({showRemoveButton: false, showEmptyTaskSet: true});
    }
  }

  /**
   * Publishes the taskSet and resets the taskset form
   */
  publishTaskSet() {
    if (this.state.modalType === ModalType.ADDTASK) {
      addTasksToTaskSet(this.state.taskSetDTO).then(() => {
      this.props.toggleModal(this.props.modalClass.class);
        publisher('Oppgavene ble lagt til!', 'www.ematte.no/eksempellink', () => {
          this.props.updateTaskList();
        });
      });
    } else {
      // i.e. ModalType.NEWTASKSET
      const {taskSetDTO} = this.state;
      taskSetDTO.isPublished = true;
      post('/taskset', taskSetDTO).then(() => {
        this.props.toggleModal(this.props.modalClass.class);
        publisher('Ditt oppgavesett er publisert!', 'www.ematte.no/eksempellink', () => {
          this.props.updateTaskList();
        });
      });
    }
    this.props.reset('newTaskSet');
  }

  switchToNewTaskSet() {
    this.setState({modalType: ModalType.NEWTASKSET});
  }

  removeIndex(array, item) {
    let removeIndex = array.indexOf(item);
    if (removeIndex > -1) {
      array.splice(removeIndex, 1);
    }
    return array;
  }

  /**
   * Handles users filter-input and combines them to a list to filter task and tasksets
   *
   * @param filters
   * @param filterType
   */
  filter(filters, filterType) {
    let {
      filteredTasks,
      filteredTypes,
      taskTypeFilter,
      gradeFilter,
      difficultyFilter,
      topicFilter,
      typeFilter,
      isFiltered,
      searchFilter,
      sorting
    } = this.state;

    if (!filteredTypes.includes(filterType) && filterType) {
      filteredTypes.push(filterType);
    }
    else if (filters && filters.length < 1) {
      this.removeIndex(filteredTypes, filterType);
    }

    switch (filterType) {
      case FilterTypes.TASKTYPE:
        taskTypeFilter = filters;
        this.setState({taskTypeFilter: filters});
        break;
      case FilterTypes.GRADE:
        gradeFilter = filters;
        this.setState({gradeFilter: filters});
        break;
      case FilterTypes.DIFFICULTY:
        difficultyFilter = filters;
        this.setState({difficultyFilter: filters});
        break;
      case FilterTypes.TOPIC:
        topicFilter = filters;
        this.setState({topicFilter: filters});
        break;
      case FilterTypes.TYPE:
        typeFilter = filters;
        this.setState({typeFilter: filters});
        break;
      case FilterTypes.SEARCH:
        searchFilter = filters;
        this.setState({searchFilter: filters});
        break;
      default:
        return;
    }

    let combinedList = combineLists(this.props.publishedTasks, null, this.props.favouriteTasks, null);
    combinedList = sortList(combinedList, sorting);
    filteredTasks = filterList(combinedList, filteredTypes, taskTypeFilter, gradeFilter, difficultyFilter, typeFilter, topicFilter, searchFilter);
    this.setState({filteredTasks: filteredTasks, isFiltered: isFiltered}, () => {
      this.updateTaskList();
    });
  }

  /**
   * Shows the sorting dropdown menu and adds eventlistner to remove it
   */
  handleSortDropdownClick = () => {
    const {hiddenSort} = this.state;

    this.setState({hiddenSort: !hiddenSort});
    if (hiddenSort) {
      document.addEventListener('click', () => {
        this.setState({hiddenSort: true});
      }, {once: true});
    }
  };

  handleSortingChange = (newSorting) => {
    const {filteredTasks, sorting} = this.state;
    if (newSorting) {
      this.setState({filteredTasks: sortList(filteredTasks, newSorting), sorting: newSorting});
    }
    else {
      this.setState({filteredTasks: sortList(filteredTasks, sorting)});
    }
  };

  searchChange = (e) => {
    if (e.target.value === '') {
      this.filter([], FilterTypes.SEARCH);
    }
    else {
      this.filter(e.target.value.toLowerCase(), FilterTypes.SEARCH);
    }
  };

  toggleShowMoreFilters = () => {
    this.state.hiddenFilters ? this.setState({hiddenFilters: false}) : this.setState({hiddenFilters: true});
  };

  render() {
    const {page} = this.state;
    const {title, description} = this.props;
    const totalPages = 2;
    return (
      <section>
        <div className="modal-content__container  non-selectable">
          <div className="has-text-centered  pageinator">
            <Pageinator page={page} total={totalPages}/>
          </div>
          <div className="new-task-modal__header">
            {
              page === 1 && this.props.modalClass.modal === ModalType.NEWTASKSET &&
              <span><i className="fa  fa-file-o  padding-right10"/>Nytt oppgavesett</span>
            }
            {
              page === 1 && this.props.modalClass.modal === ModalType.ADDTASK &&
              <span className="lightText">Legg til i oppgavesett</span>
            }
            {
              page === 2 &&
              <span>Forhåndsvisning</span>
            }
          </div>
          {
            page === 1 &&
            <TaskSetPage1
              types={this.props.types}
              topics={this.props.topics}
              onSubmit={this.nextPage}
              total={totalPages}
              page={page}
              tasks={this.state.filteredTasks}
              publishedTasks={this.props.publishedTasks}
              showMore={this.showMore}
              maxItems={this.state.maxItems}
              chooseTasks={this.chooseTasks}
              chosenTasks={this.state.pickedTasks}
              isDisabled={this.state.isDisabled}
              toggleIsDisabled={this.toggleIsDisabled}
              removeTasks={this.removeTasks}
              showRemoveButton={this.state.showRemoveButton}
              showEmptyTaskSet={this.state.showEmptyTaskSet}
              modalType={this.state.modalType}
              taskSets={this.props.userTaskSets}
              switchToNewTaskSet={this.switchToNewTaskSet}
              filter={this.filter}
              sorting={this.state.sorting}
              hiddenSort={this.state.hiddenSort}
              handleSortingChange={this.handleSortingChange}
              handleSortDropdownClick={this.handleSortDropdownClick}
              loaded={this.props.loaded}
              searchChange={this.searchChange}
              isSelected={this.props.isSelected}
              hiddenFilter={this.state.hiddenFilters}
              toggleShowMoreFilters={this.toggleShowMoreFilters}
              taskSetDTO={this.state.taskSetDTO}
            />
          }
          {
            page === 2 &&
            <TaskSetPage2
              title={title}
              description={description}
              onSubmit={this.publishTaskSet}
              total={totalPages}
              page={page}
              taskSet={this.state.taskSetDTO}
              previousPage={this.previousPage}
            />
          }
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleModal: toggleModal,
    reset: reset,
    pickTask: pickTask,
    pickSelectedTask: pickSelectedTask,
    pickGlobalTask: pickGlobalTask,
    getPublishedTasks: getPublishedTasks
  }, dispatch);

const mapStateToProps = (state) => {
  const selector = formValueSelector('newTaskSet');
  return {
    publishedTasks: state.publishedTasks,
    publishedTaskSets: state.publishedTaskSets,
    modalClass: state.modalClass,
    title: selector(state, 'title'),
    description: selector(state, 'description'),
    pickedTasks: state.pickedTasks,
    pickedSelectedTasks: state.pickedSelectedTasks,
    pickedGlobalTasks: state.pickedGlobalTasks,
    favouriteTasks: state.favouriteTasks,
    userTaskSets: state.userTaskSets
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskSetForm);
