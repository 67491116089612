const reportReducer = (state = false, action) => {
  //Types i egen fil
  switch (action.type) {
    case 'TOGGLE_REPORT_ON':
      return true;
    case 'TOGGLE_REPORT_OFF':
      return false;
    default:
      return false;
  }
};

export default reportReducer;
