import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {BottomRowButtons} from '../../../../components/common/modal/bottomRowButtons';
import {validate} from '../validate';
import InputFieldWithTitle from '../../../../components/common/renderFields/inputFieldWithTitle/inputFieldWithTitle';
import Dropdown from "../../../../components/common/dropDownMenu/dropDownMenu";
import selectTaskSet from '../../../../components/common/renderFields/selectTaskSet';
import {ChosenTaskList} from '../../../../components/common/modal/chosenTaskList';
import {TaskPicker} from '../../../../components/common/modal/taskPicker';
import {ModalType} from '../../../../components/common/modal';
import {Picture} from "../../../picture/picture";
import textArea from "../../../task/form/pages/renderFields/textArea";
import {GRADE} from "../../../../util/enums/grade";

const TaskSetPage1 = (props) => {
  const {
    handleSubmit,
    page,
    total,
    tasks,
    publishedTasks,
    chooseTasks,
    chosenTasks,
    showMore,
    maxItems,
    isDisabled,
    toggleIsDisabled,
    removeTasks,
    showRemoveButton,
    showEmptyTaskSet,
    modalType,
    taskSets,
    switchToNewTaskSet,
    topics,
    types,
    filter,
    sorting,
    hiddenSort,
    handleSortingChange,
    handleSortDropdownClick,
    taskAmount,
    loaded,
    searchChange,
    isSelected,
    hiddenFilter,
    toggleShowMoreFilters,
    taskSetDTO
  } = props;

  const onPictureAdded = (imageId) => {
    taskSetDTO.descriptionPicture = {id: imageId};
  };

  const grades = Object.values(GRADE);
  const callback = () => {
    return null
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="task-set-form-top__content">
        {
          modalType === ModalType.NEWTASKSET &&
          <div>
            <div className="modal-row">
              <Field
                name="title"
                type="text"
                component={InputFieldWithTitle}
                label="Gi oppgavesettet et navn*"
                placeholder="Skriv navn her"
              />
            </div>
            <div className='modal-row'>
              <Field
                name='description'
                type='text'
                component={textArea}
                label='Beskrivende tekst*'
                placeholder='Beskriv oppgavesettet'
              />
              <Picture
                onPictureAdded={onPictureAdded}
                inputId={"taskset"}
                image={taskSetDTO.descriptionPicture}
              />
            </div>
            <div className="modal-row  task-set-form-grade--input">
              <Field
                name="grade"
                label="Velg klassetrinn*"
                list={grades}
                dropdownTitle={grades[0].description()}
                component={Dropdown}
                callback={callback}
              />
            </div>
          </div>
        }
        {
          modalType === ModalType.ADDTASK &&
          <div className="modal-row">
            <div className="has-text-centered">
              Velg oppgavesett
            </div>
            <div className="columns  modal-row">
              <div className="column  is-9">
                <Field
                  name="taskSet"
                  component={selectTaskSet}
                  taskSets={taskSets}
                />
              </div>
              <div className="column is-3">
                <div className="task-set-new-task-set__button" onClick={switchToNewTaskSet}>
                  <label className="task-set-new-task-set__button--label">Nytt oppgavesett</label>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="modal-row">
          {
            chosenTasks.length > 0 &&
            <div className="has-text-centered">
              Valgte oppgaver
            </div>
          }
          <div className="modal-row  task-set-form-chosen-list">
            <ChosenTaskList
              tasks={chosenTasks}
              isTaskPicker={true}
              toggleIsDisabled={toggleIsDisabled}
              isSelected={isSelected}
            />
          </div>
        </div>
      </div>
      <div className="modal-row">
        <BottomRowButtons
          page={page}
          total={total}
          hasRemoveTasks={true}
          isDisabled={isDisabled}
          removeTasks={removeTasks}
          showRemoveButton={showRemoveButton}
          showEmptyTaskSet={showEmptyTaskSet}
          modalType={modalType}
        />
      </div>
      <div className="modal-row">
        <TaskPicker
          tasks={tasks}
          publishedTasks={publishedTasks}
          showMore={showMore}
          maxItems={maxItems}
          chooseTasks={chooseTasks}
          types={types}
          topics={topics}
          filter={filter}
          sorting={sorting}
          hiddenSort={hiddenSort}
          handleSortingChange={handleSortingChange}
          handleSortDropdownClick={handleSortDropdownClick}
          taskAmount={taskAmount}
          loaded={loaded}
          searchChange={searchChange}
          isSelected={isSelected}
          hiddenFilter={hiddenFilter}
          toggleShowMoreFilters={toggleShowMoreFilters}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'newTaskSet',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(TaskSetPage1);
