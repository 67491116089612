import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from '../validate';
import textAreaInput from './renderFields/textArea';
import textInput from '../../../../components/common/renderFields/textInput';
import textInputLogo from './renderFields/textInputLogo';
import { BottomRowButtons } from '../../../../components/common/modal/bottomRowButtons';

let TaskPage4 = (props) => {
  const {handleSubmit, page, previousPage, total} = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-row">
        <div className="task-viewer__container">
          <div className="columns">
            <div className="column  is-full">
              <Field
                name="hint"
                component={textAreaInput}
                label="Tips til løsning"
                placeholder="Skriv inn tips til løsning"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column  is-half">
              <Field
                name="link"
                component={textInputLogo}
                placeholder="www.lenke"
                label="Tilhørende lenke/url"
                icon="placeholder-icon--link"
              />
            </div>
            <div className="column  is-half">
              <Field
                name="linkDescription"
                component={textInput}
                label="Beskrivelse av lenke"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column  is-full">
              <Field
                name="tools"
                component={textInputLogo}
                placeholder="Kalkulator, linjal, passer, gradskive, lærebok"
                label="Andre nyttige verktøy:"
                icon="placeholder-icon--calculator"
              />
            </div>
          </div>
        </div>
      </div>
      <BottomRowButtons
        page={page}
        previousPage={previousPage}
        total={total}
      />
    </form>
  );
};

export default reduxForm({
  form: 'newTask',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(TaskPage4);
