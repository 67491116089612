import * as React from 'react';

import './taskViewer.css';
import {ALPHABET} from "../../../declarations";
import {SubTask} from "./subTask";
import {Description} from "../description/description";
import {namespace} from "../../../util/namespaceNO";

export const TaskViewer = (props) => {

  const {task} = props;

  return (
    <div>
      <i className="fa  fa-file-o  task-viewer--icon"/>
      <div className="task-viewer__container">
        <div className="task-viewer-title">
          <span className="strongText">{task.title}</span>
        </div>
        <Description
          description={task.description}
          image={task.descriptionPicture}
          formula={task.formula}
        />
        <SubTask subTasks={task.subTask}/>
        {
          task.link &&
          <div className="task-viewer-link">
            <span>{task.linkDescription} - <a href={task.link}>{task.link}</a></span>
          </div>
        }
        {
          task.tools &&
          <div className="task-viewer-tool">
            <span>{namespace.usefulTools + task.tools}</span>
          </div>
        }
        {
          (task.solution || task.subTask.length !== 0) &&
          <div className="task-viewer-solution__container" id="task-viewer-solution-id">
            <div className="has-text-left">
              {namespace.solutionTaskPreview}<span className="lightText">{task.solution}</span>
            </div>
            <div className="">
              {task.subTask.map((subTask, i) =>
                subTask.solution &&
                <p className="lightText" key={i}>{ALPHABET[i] + ") " + subTask.solution}</p>
              )}
            </div>
          </div>
        }
        {
          task.hint &&
        <div className="task-viewer-hint__container">
          {namespace.suggestionForSolution} <span className="lightText">{task.hint}</span>
        </div>
        }
      </div>
    </div>
  );
};
