import React from 'react';

const textInputLogo = (props) => (
  <div>
    <span className="title-input-label  title-input-label--modal" >{props.label}</span>
    <div className={props.icon}>
      <input {...props.input} placeholder={props.placeholder} type={props.type} className="text-input  text-input--logo" />
      {props.touched && props.error && <span>{props.error}</span>}
    </div>
  </div>
);

export default textInputLogo;
