import React from 'react';
import './inputFieldWithTitle.css';

const inputFieldWithTitle = (props) => (
  <div>
    <div>
      <label className="title-input-label  title-input-label--modal">{props.label}</label>
      {
        props.meta.touched && props.meta.error &&
        <span className="error-message-label">{props.meta.error}</span>
      }
    </div>
    <div>
      <input
        {...props.input}
        placeholder={props.placeholder}
        type={props.type}
        className="text-input  text-input--title"
        autoComplete={'off'}
        autoFocus={props.autoFocus}
      />
    </div>
  </div>
);

export default inputFieldWithTitle;
