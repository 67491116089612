import * as React from 'react';
import {reduxForm} from 'redux-form';
import {BottomRowButtons} from '../../../../components/common/modal/bottomRowButtons';
import {TaskSetProperties} from '../../../../components/common/modal/taskSetProperties';
import {TaskViewer} from '../../../../components/common/modal/taskViewer';
import {validate} from '../validate';
import {Description} from "../../../../components/common/description/description";

let TaskSetPage2 = (props) => {
  const {handleSubmit, taskSet, page, previousPage, total} = props;
  let tasks = true;
  if (taskSet.tasks.length < 1) {
    tasks = false
  }
  return (
    <form onSubmit={handleSubmit}>
      <TaskSetProperties {...taskSet} />
      <div className="task-set-modal-title__container">
          <span className="task-set-modal-title">
            <i className="fa  fa-files-o  task-set-modal-title--icon"/>
            {taskSet.title}
          </span>
        <div className="task-set-description">
          <Description
            description={taskSet.description}
            image={taskSet.descriptionPicture}
          />
        </div>
      </div>
      {
        tasks && taskSet.tasks.map((task, index) =>
          <div className="task-set-task-viewer-row" key={index}>
            <p className="task-set-task-viewer-index">Oppgave {index + 1} </p>
            <div className="modal-task-viewer__container">
              <TaskViewer task={task} />
            </div>
          </div>
        )}
      {
        !tasks &&
        <span className="strongText">NB! Dette oppgavesettet er tomt</span>
      }
      <BottomRowButtons
        page={page}
        previousPage={previousPage}
        total={total}
      />
    </form>
  );
};

export default reduxForm({
  form: 'newTaskSet',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(TaskSetPage2);
