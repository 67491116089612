import React from 'react';

export const NewButton = (props) => {
const {title, icon} = props;

  return (
    <div className="new-button  lightText">
      <label className={'new-button-text-menu  ' + icon}>
        <span>{title}</span>
      </label>
    </div>
  );
};

