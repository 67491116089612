
/**
 * Filters out duplicate properties from each tasks and
 * returns a list of distinct properties
 *
 * @param tasks, property
 * @return Array
 */
export const duplicateRemover = (tasks, property) => {
  let filteredArray = [];
  switch (property) {
    case 'topics':
      tasks.map((task) =>
        task.topics.map((topic) =>
          filteredArray.push(topic.topic)
        )
      );
      filteredArray = Array.from(new Set(filteredArray));
      return filteredArray;
    case 'types':
      tasks.map((task) =>
        task.types.map((type) =>
          filteredArray.push(type.type)
        )
      );
      filteredArray = Array.from(new Set(filteredArray));
      return filteredArray;
    case 'difficulty':
      tasks.map((task) =>
        filteredArray.push(task.difficulty)
      );
      filteredArray = Array.from(new Set(filteredArray));
      return filteredArray;
    default:
      return filteredArray;
  }
};