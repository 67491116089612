import React from 'react';
import {TaskViewer} from '../common/modal/taskViewer';
import {TaskProperties} from '../common/modal/taskProperties';
import {TopBarButtons} from '../common/modal/topBarButtons';
import {TopBarRating} from "../common/modal/topBarRating";
import {Rating} from "../common/modal/rating";
import {Comments} from "../common/modal/comments";
import ExportTaskToPdfButton from "../pdfgeneration/exportTaskToPdfButton";
import {namespace} from "../../util/namespaceNO";
import {OptionsButton} from "../options/optionsButton";
import Report from "../common/modal/report";
import {REPORT_TYPE} from "../../util/enums/reportType";
import {TaskPdf} from "../pdfgeneration/taskPdf";

export default class TaskPreview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {solution: false}
  }

  updateShowSolution = (solution) => {
    this.setState({solution: solution});
  };

  render() {
    const {
      task,
      isFavourite,
      onFavouriteClick,
      exit,
      taskRatingClick,
      addComment,
      hideReportModal,
      showMoreOptionModal,
      hideMoreOptionModal,
      showReportModal,
      loggedInUserId,
      moreOptionsModal,
      report
    } = this.props;

    const optionProps = {
      showMoreOptionModal,
      hideMoreOptionModal,
      showReportModal,
      moreOptionsModal,
      deleteUrl: '/task/' + task.id,
      taskStatusEnum: task.taskStatusEnum,
      userAdmin: task.userAdmin,
      createdBy: task.createdBy,
      loggedInUserId,
      confirmationMessage: namespace.deleteTask,
    };

    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={exit}/>
        <div className="modal-content" id="modal">
          <div className="modal-content__container" onClick={hideMoreOptionModal}>
            <div className="modal-content__topbar">
              <div className="modal-rating__container">
                <TopBarRating
                  rating={task.totalRating}
                  amountOfRatings={task.amountOfRatings}/>
              </div>
              <div className="modal-topbar-buttons  not-selectable">
                <OptionsButton
                  optionProps={optionProps}
                />
                <TopBarButtons
                  onFavouriteClick={onFavouriteClick}
                  isFavourite={isFavourite}
                />
                <ExportTaskToPdfButton
                  id="task-preview-modal-id"
                  updateShowSolution={this.updateShowSolution}
                />
              </div>
              <div className="modal-row  not-selectable">
                <TaskProperties {...task}/>
              </div>
            </div>
            {report &&
            <Report
              typeOfReport={REPORT_TYPE.TASK.value}
              id={task.id}
              message={namespace.reportTask}
              hideReportModal={hideReportModal}
            />
            }
            <div className="modal-row" id="task-preview-modal-id">
              <TaskViewer task={task}/>
            </div>
            <Rating
              isTaskSet={false}
              taskRatingClick={taskRatingClick}
              userRating={task.usersRating}
            />
            <Comments
              comments={task.comments}
              addComment={addComment}
            />
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={exit}
          />
          <TaskPdf
            {...task}
            showSolution={this.state.solution}
          />
        </div>

      </div>
    )
  }
};
