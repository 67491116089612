export const toggleOptionModalOn = () => {
  return {
    type: "TOGGLE_OPTIONS_ON"
  }
};

export const toggleOptionModalOff = () => {
  return {
    type: "TOGGLE_OPTIONS_OFF"
  }
};
