import React from 'react';
import {FormulaViewer} from "./formulaViewer";
import './formulaForm.css';
import {CalculatorInterface} from "./calculatorInterface";

export class FormulaForm extends React.Component {

  constructor(props) {
    super(props);
    this.formulaInputRef = React.createRef();
    this.state = {
      ascii: null,
      submitted: false
    }
  }

  componentDidMount() {
    !this.state.ascii && this.props.formula &&
    this.setState({ascii: this.props.formula}, this.setValueOfInputField);
  }

  setValueOfInputField = () => {
    this.formulaInputRef.current.value = this.props.formula;
  };

  showInput = () => {
    this.setState({ascii: ''},
      () => this.formulaInputRef.current.focus());
  };

  handleChange = (e) => {
    this.setState({ascii: e.target.value},
      () => this.props.onFormulaAdded(this.state.ascii));
  };

  handleCalculatorInput = (input) => {
    this.setState({ascii: this.state.ascii.concat(input)});
    this.props.onFormulaAdded(this.state.ascii + input);
    this.formulaInputRef.current.value = this.state.ascii + input;
    this.formulaInputRef.current.focus();
  };

  render() {
    if (this.state.ascii === null) {
      return (
        <button onClick={this.showInput} className="show-input-button">Legg til formel</button>
      )
    }

    return (
      <div>
        <div className="form-container">
          <input
            type="text"
            onChange={this.handleChange}
            className="formula-input"
            autoComplete="off"
            ref={this.formulaInputRef}
          />
        </div>
        <CalculatorInterface handleCalculatorInput={this.handleCalculatorInput}/>
        <FormulaViewer formula={this.state.ascii}/>
      </div>
    )
  }
}