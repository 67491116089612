import React from 'react';
import {Node, Context} from 'react-mathjax2';

export const FormulaViewer = (props) => {
  const {formula} = props;
  return (
    <div>
      <Context
        options={{
          menuSettings: {
            zoom: "Click"
          },
          CommonHTML: {
            linebreaks: {
              automatic: true,
              width: "container"
            }
          }
        }}
      >
        <div>
          <Node>{formula}</Node>
        </div>
      </Context>
    </div>
  )
};