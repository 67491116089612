import React from "react";
import "./generateButton.css";
import {namespace} from "../../../../util/namespaceNO";

export const GenerateButton = (props) => {
  return (
    <div className="generate-button-container">
      <button onClick={props.handleGenerateClick}
              disabled={props.invalid}
              type="submit"
              className="generate-button-button">
        {namespace.generateButton}
      </button>
    </div>
  )
};
