import {SUBJECT} from "./subject";
import {namespace} from "../namespaceNO";
import {DECIMAL} from "./decimals";

class Grade {
  decimals() {
    return DECIMAL.FOUR;
  }

  minimumDigit = () => '1';
}

class OneTwo extends Grade {
  key = () => 1;
  description = () => namespace.gradeOneTwo;
  subjects = () => [SUBJECT.ADDITION, SUBJECT.SUBTRACTION];
  value = () => [1, 2];
  maximumDigit = () => '10';
  grades = () => [1, 2]
}

class ThreeFour extends Grade {
  key = () => 2;
  description = () => namespace.gradeThreeFour;
  subjects = () => [SUBJECT.ADDITION, SUBJECT.SUBTRACTION, SUBJECT.MULTIPLICATION, SUBJECT.DIVISION];
  value = () => [3, 4];
  maximumDigit = () => '100';
  grades = () => [3, 4]
}

class FiveSeven extends Grade {
  key = () => 3;
  description = () => namespace.gradeFiveSeven;
  subjects = () => [SUBJECT.ADDITION, SUBJECT.SUBTRACTION, SUBJECT.MULTIPLICATION, SUBJECT.DIVISION];
  value = () => [5, 6, 7];
  minimumDigit = () => '10';
  maximumDigit = () => '100';
  grades = () => [5, 6, 7]
}

class EightTen extends Grade {
  key = () => 4;
  description = () => namespace.gradeEightTen;
  subjects = () => [SUBJECT.ADDITION, SUBJECT.SUBTRACTION, SUBJECT.MULTIPLICATION, SUBJECT.DIVISION];
  value = () => [8, 9, 10];
  minimumDigit = () => '10';
  maximumDigit = () => '100';
  grades = () => [8, 9, 10]
}

class Eleven extends Grade {
  key = () => 5;
  description = () => namespace.gradeEleven;
  subjects = () => [SUBJECT.ADDITION, SUBJECT.SUBTRACTION, SUBJECT.MULTIPLICATION, SUBJECT.DIVISION];
  value = () => [11];
  minimumDigit = () => '10';
  maximumDigit = () => '100';
  grades = () => [11]
}

class Twelve extends Grade {
  key = () => 6;
  description = () => namespace.gradeTwelve;
  subjects = () => [SUBJECT.ADDITION, SUBJECT.SUBTRACTION, SUBJECT.MULTIPLICATION, SUBJECT.DIVISION];
  value = () => [12];
  minimumDigit = () => '10';
  maximumDigit = () => '100';
  grades = () => [12]
}

class Thirteen extends Grade {
  key = () => 7;
  description = () => namespace.gradeThirteen;
  subjects = () => [SUBJECT.ADDITION, SUBJECT.SUBTRACTION, SUBJECT.MULTIPLICATION, SUBJECT.DIVISION];
  value = () => [13];
  minimumDigit = () => '10';
  maximumDigit = () => '100';
  grades = () => [13]
}

export const GRADE = Object.freeze(
  {
    ONE_TWO: new OneTwo(),
    THREE_FOUR: new ThreeFour(),
    FIVE_SEVEN: new FiveSeven(),
    EIGHT_TEN: new EightTen(),
    ELEVEN: new Eleven(),
    TWELVE: new Twelve(),
    THIRTEEN: new Thirteen()
  });