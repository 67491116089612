import React from 'react';
import { Link } from 'react-router-dom';
import {getFacebookURI, getUri} from '../../oauthConfig'
import Header from '../../components/header/Header';
import FontAwesome from 'react-fontawesome'

class LoginPage extends React.Component {

  render() {
    return (
        <div style={{textAlign:'center'}}>
          <Header/>
          <p style = {{marginTop: '48px'}} >Velkommen til eMatte! Vennligst velg et av login-alternativene for å fortsette</p>
          <div style = {{marginTop: '10px'}}>
            <button className = "loginBtn loginBtn--skolon" onClick={() => getUri() }>
              <p style={{fontSize: '16px', color: '#FFF', position: 'absolute', top: '10px', left: '75px'}}>Skolon login</p>
            </button>
          </div>
          <div>
            <button className = "loginBtn loginBtn--facebook" onClick={() => getFacebookURI()}>
              <FontAwesome className="fab fa-facebook-square" size = "2x"/>
                <p style={{fontSize: '16px', color: '#FFF', position: 'absolute', top: '10px', left: '75px'}}>Facebook login</p>
            </button>
          </div>
        </div>
    )
  }
}

export default LoginPage;
