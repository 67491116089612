import React, {Component} from 'react';
import FontAwesome from 'react-fontawesome'
import onClickOutside from "react-onclickoutside";
import './dropdownMenu.css';

/**
 * Creates a dropdown component, active field adds disabled styling and don't have click event.
 */
class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      dropdownTitle: this.props.dropdownTitle,
      header: this.props.header,
      active: this.props.active
    }
  }

  componentDidMount() {
    this.props.input.onChange(this.props.list[0].value());
  }

  // used by onClickOutside library
  handleClickOutside(e) {
    this.setState({
      listOpen: false
    })
  }

  handleChange = (title, value) => {
    this.setState({
      dropdownTitle: title,
      listOpen: false
    });
    this.props.input.onChange(value.value());
    this.props.callback(value);
  };

  toggleList = () => {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }))
  };

  render() {
    const {list} = this.props;
    const {listOpen, dropdownTitle, header} = this.state;
    return (
      <div className="dropdown-wrapper" key={header}>
        <h1 className="dropdown-menu-header">{header}</h1>
        <div className="dropdown-content">
          <div className="dropdown-header" onClick={this.toggleList}>
            <span className="dd-header-title">{dropdownTitle}</span>
            {listOpen
              ? <FontAwesome name="angle-up" className="dropdown-angle" size="2x"/>
              : <FontAwesome name="angle-down" className="dropdown-angle" size="2x"/>
            }
          </div>

          {listOpen && <ul className="dropdown-list">
            {list.map((item) => (
              <li className="dropdown-list-item" key={item.key()}
                  onClick={(e) => {
                    this.handleChange(item.description(), item);
                    e.stopPropagation()
                  }}>
                {item.description()}
              </li>
            ))}
          </ul>}
        </div>
      </div>
    )
  }
}

export default onClickOutside(Dropdown);
