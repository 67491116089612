import * as React from 'react';
import FilterModal from '../../components/common/filter/filterModalMenu';
import FilterSidebar from '../../components/common/filter/filterSidebarMenu';
import {FilterTypes} from '../../declarations';
import {GRADE} from "../../util/enums/grade";
import {namespace} from "../../util/namespaceNO";

class Filters extends React.Component {
  constructor() {
    super();
    this.toggleTaskTypeFilter = this.toggleTaskTypeFilter.bind(this);
    this.toggleGradeFilter = this.toggleGradeFilter.bind(this);
    this.toggleDifficultyFilter = this.toggleDifficultyFilter.bind(this);
    this.handleDifficultyChange = this.handleDifficultyChange.bind(this);
    this.toggleTypeFilter = this.toggleTypeFilter.bind(this);
    this.toggleTopicFilter = this.toggleTopicFilter.bind(this);

    this.state = {
      hiddenFilters: false,
      taskTypeFilter: [],
      difficultyFilter: [],
      gradeFilter: [],
      typeFilter: [],
      topicFilter: [],
    };
  }

  toggleShowMoreFilters = () => {
    this.state.hiddenFilters ? this.setState({hiddenFilters: false}) : this.setState({hiddenFilters: true});
  };

  /**
   * Excecutes function that filters on a grade, based on the grade selected
   * by the user
   *
   * @param grades
   */
  handleGradeChange = (grades) => {
    this.toggleGradeFilter(grades, true);
  };

  /**
   * Receives a value from user event, and checks if the value is already chosen
   * and then execute a function that toggles the difficulty
   *
   * @param e Event
   */
  handleDifficultyChange(e) {
    const {difficultyFilter} = this.state;
    let isOn = true;

    difficultyFilter.forEach((difficulty) => {
      if (difficulty === parseInt(e.target.value, 10)) {
        isOn = false;
      }
    });

    this.toggleDifficultyFilter(parseInt(e.target.value, 10), isOn);
  }

  /**
   * Pushes the filter into the array of filters if filter set to be on,
   * if not, it removes the filter and then saves the array in state
   * and execute the filter function from props.
   *
   * @param filter
   * @param isOn
   */
  toggleTaskTypeFilter(filter, isOn) {
    let {taskTypeFilter} = this.state;
    if (isOn) {
      taskTypeFilter.push(filter);
    }
    else {
      let removeIndex = taskTypeFilter.indexOf(filter);
      if (removeIndex > -1) {
        taskTypeFilter.splice(removeIndex, 1);
      }
    }
    this.setState({taskTypeFilter: taskTypeFilter});
    this.props.filter(taskTypeFilter, FilterTypes.TASKTYPE);
  }

  /**
   * Pushes the filter into the array of filters if filter set to be on,
   * if not, it removes the filter and then saves the array in state
   * and execute the filter function from props.
   *
   * @param filter
   * @param isOn
   */
  toggleGradeFilter(filter, isOn) {
    let gradeFilter;
    if (isOn) {
      gradeFilter = filter;
    }
    this.setState({gradeFilter: gradeFilter});
    this.props.filter(gradeFilter, FilterTypes.GRADE);
  }

  /**
   * Pushes the filter into the array of filters if filter set to be on,
   * if not, it removes the filter and then saves the array in state
   * and execute the filter function from props.
   *
   * @param filter
   * @param isOn
   */
  toggleDifficultyFilter(filter, isOn) {
    let {difficultyFilter} = this.state;
    if (isOn) {
      difficultyFilter.push(filter);
    }
    else {
      let removeIndex = difficultyFilter.indexOf(filter);
      if (removeIndex > -1) {
        difficultyFilter.splice(removeIndex, 1);
      }
    }
    this.setState({difficultyFilter: difficultyFilter});
    this.props.filter(difficultyFilter, FilterTypes.DIFFICULTY);
  }

  /**
   * Pushes the filter into the array of filters if filter set to be on,
   * if not, it removes the filter and then saves the array in state
   * and execute the filter function from props.
   *
   * @param filter
   * @param isOn
   */
  toggleTypeFilter(filter, isOn) {
    let {typeFilter} = this.state;
    if (isOn) {
      typeFilter.push(filter);
    }
    else {
      let removeIndex = typeFilter.indexOf(filter);
      if (removeIndex > -1) {
        typeFilter.splice(removeIndex, 1);
      }
    }
    this.setState({typeFilter: typeFilter});
    this.props.filter(typeFilter, FilterTypes.TYPE);
  }

  /**
   * Pushes the filter into the array of filters if filter set to be on,
   * if not, it removes the filter and then saves the array in state
   * and execute the filter function from props.
   *
   * @param filter
   * @param isOn
   */
  toggleTopicFilter(filter, isOn) {
    let {topicFilter} = this.state;
    if (isOn) {
      topicFilter.push(filter);
    }
    else {
      let removeIndex = topicFilter.indexOf(filter);
      if (removeIndex > -1) {
        topicFilter.splice(removeIndex, 1);
      }
    }
    this.setState({topicFilter: topicFilter});
    this.props.filter(topicFilter, FilterTypes.TOPIC);
  }

  render() {
    const grades = Object.values(GRADE);
    grades.unshift({key: () => "noFilter", value: () => "noFilter", description: () => namespace.allGrades});

    return (
      <div>
        {
          this.props.isModalFilter &&
          <FilterModal
            filter={this.toggleGradeFilter}
            onChange={this.handleGradeChange}
            handleDifficultyChange={this.handleDifficultyChange}
            types={this.props.types}
            topics={this.props.topics}
            hiddenFilters={this.props.hiddenFilters}
            toggleShowMoreFilters={this.toggleShowMoreFilters}
            toggleTaskTypeFilter={this.toggleTaskTypeFilter}
            toggleDifficultyFilter={this.toggleDifficultyFilter}
            toggleGradeFilter={this.toggleGradeFilter}
            toggleTypeFilter={this.toggleTypeFilter}
            toggleTopicFilter={this.toggleTopicFilter}
            isModalFilter={this.props.isModalFilter}
            grades={grades}
          />
        }
        {
          !this.props.isModalFilter &&
          <FilterSidebar
            filter={this.toggleGradeFilter}
            onChange={this.handleGradeChange}
            handleDifficultyChange={this.handleDifficultyChange}
            types={this.props.types}
            topics={this.props.topics}
            hiddenFilters={this.state.hiddenFilters}
            toggleShowMoreFilters={this.toggleShowMoreFilters}
            toggleTaskTypeFilter={this.toggleTaskTypeFilter}
            toggleDifficultyFilter={this.toggleDifficultyFilter}
            toggleGradeFilter={this.toggleGradeFilter}
            toggleTypeFilter={this.toggleTypeFilter}
            toggleTopicFilter={this.toggleTopicFilter}
            grades={grades}
          />
        }
      </div>
    );
  };

}

export default (Filters);

