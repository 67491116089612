import React from 'react';
import './calculatorInterace.css'

export const CalculatorInterface = (props) => {

  const {handleCalculatorInput} = props;

  const operators = [
    {operator: '+', description: '+'},
    {operator: '-', description: '-'},
    {operator: '*', description: '*'},
    {operator: '//', description: '/'},
    {operator: '=', description: '='},
    {operator: '/', description: 'Brøk'},
    {operator: '^', description: 'Potens'},
    {operator: 'sqrt', description: 'Kvadratrot'},
    {operator: '(', description: '('},
    {operator: ')', description: ')'}
  ];

  return (
    <div className="calculator">
      {operators.map((i) =>
        <button type="button" onClick={() => handleCalculatorInput(i.operator)} key={i.operator}>
          {i.description}
        </button>
    )}
    </div>
  )
};