import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


export const exit = (message, onConfirm) => {
  confirmAlert({
    title: 'Advarsel!',
    message: message,
    confirmLabel: 'Bekreft',
    cancelLabel: 'Avbryt',
    onConfirm: () => {
      onConfirm();
    }
  });
};
