export const TaskType = Object.freeze({
  'TASK': 'task',
  'TASKSET': 'taskset',
  'FAVOURITE': 'favourite'
});

export const Grade = Object.freeze({
  'FIRST': 1,
  'SECOND': 2,
  'THIRD': 3,
  'FOURTH': 4,
  'FIFTH': 5,
  'SIXTH': 6,
  'SEVENTH': 7,
  'EIGHT': 8,
  'NINTH': 9,
  'TENTH': 10,
  'ELEVENTH': 11,
  'TWELFTH': 12,
  'THIRTEENTH': 13
});

export const FilterTypes = Object.freeze({
  'TASKTYPE': 1,
  'GRADE': 2,
  'DIFFICULTY': 3,
  'TOPIC': 4,
  'TYPE': 5,
  'USER': 6,
  'SEARCH': 7
});

export const TaskSelectType = Object.freeze({
  "ADD": 1,
  "REMOVE": 2,
  "RESET": 3
});

export const Sortings = Object.freeze({
  'RATED': 'Høyest vurderte',
  'BOOKMARKED': 'Mest bokmerket',
  'NEWEST': 'Nyeste',
  'OLDEST': 'Eldste'

});

export const SSOLink = Object.freeze({
  'LOGOUT': process.env.REACT_APP_EMATTE_CAS_BASE_SERVER_URL + '/logout',
  'LOGIN': process.env.REACT_APP_EMATTE_CAS_BASE_SERVER_URL + '/login?service=' + process.env.REACT_APP_EMATTE_BASE_API_URL + "handleLogin"
});

export const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.split('');
