import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {getPublishedTasks} from "../../actions/task/fetchPublishedTasks";
import TaskPreview from '../../components/task/taskPreview';
import {getFavouriteTasks} from "../../actions/task/fetchFavouriteTasks";
import Swal from "sweetalert2";
import {deleteEntity, get, post} from "../../apiService";
import {getLoggedInUser} from "../../actions/user/fetchLoggedInUserInfo";
import {toggleOptionModalOff, toggleOptionModalOn} from "../../actions/common/showOptions";
import {toggleReportModalOff, toggleReportModalOn} from "../../actions/common/showReport";

class TaskDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      task: null
    }
  }

  componentDidMount() {
    this.getInitData().then(() => {
      this.setState({
        task: this.props.publishedTasks.find(task => task.id === parseInt(this.props.match.params.id, 10))
      });
      if (!this.state.task) {
        window.location = "/home";
        return null;
      }
    });
  }

  async getInitData() {
    await this.props.getFavouriteTasks();
    await this.props.getPublishedTasks();
    await this.props.getLoggedInUser();
  }

  favouriteClick = (e) => {
    if (this.props.favouriteTasks.includes(this.state.task.id)) {
      this.removeFromFavourite(this.state.task.id).then(() => this.props.getFavouriteTasks());
    }
    else {
      this.addTaskToFavourites(this.state.task.id).then(() => this.props.getFavouriteTasks());
    }
    e.stopPropagation();
  };

  async removeFromFavourite(task) {
    await deleteEntity("/task/favourite/" + task);
  }

  async addTaskToFavourites(task) {
    await post('/task/favourite/' + task)
  }

  addComment = (comment) => {
    post('/task/' + this.state.task.id + '/comment', comment)
      .then(() => get('/task/' + this.state.task.id, this.props.token))
      .then((updatedTask) => this.setState({task: updatedTask}));
  };

  taskRatingClick = (rating) => {
    this.addRating(rating, this.state.task.id)
      .then(() => get('/task/' + this.state.task.id, this.props.token))
      .then((updatedTask) => this.setState({task: updatedTask}))
      .then(() => Swal({
        position: 'bottom',
        type: 'success',
        title: 'Din vurdering er registrert',
        showConfirmButton: false,
        timer: 1500,
        toast: true
      }));
  };

  async addRating(rating, id) {
    await post('/task/rating/' + rating + '/' + id);
  };

  exitModal = () => {
    window.location = "/home";
  };

  render() {
    if (!this.state.task) {
      return null;
    }

    return (
      <TaskPreview
        task={this.state.task}
        isFavourite={this.props.favouriteTasks.includes(this.state.task.id)}
        onFavouriteClick={this.favouriteClick}
        exit={this.exitModal}
        taskRatingClick={this.taskRatingClick}
        addComment={this.addComment}
        loggedInUserId={this.props.loggedInUser.id}
        showMoreOptionModal={this.props.showMoreOptionModal}
        hideMoreOptionModal={this.props.hideMoreOptionModal}
        hideReportModal={this.props.hideReportModal}
        moreOptionsModal={this.props.moreOptionsModal}
        showReportModal={this.props.showReportModal}
        report={this.props.report}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    publishedTasks: state.publishedTasks,
    favouriteTasks: state.favouriteTasks,
    loggedInUser: state.loggedInUser,
    moreOptionsModal: state.moreOptions,
    report: state.report,
    token: state.authentication.access_token
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getPublishedTasks: getPublishedTasks,
    getFavouriteTasks: getFavouriteTasks,
    getLoggedInUser: getLoggedInUser,
    showMoreOptionModal: toggleOptionModalOn,
    hideMoreOptionModal: toggleOptionModalOff,
    hideReportModal: toggleReportModalOff,
    showReportModal: toggleReportModalOn
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
