import * as React from 'react';
import { reduxForm } from 'redux-form';
import validate from '../validate';
import { FormTypePage } from '../../../../components/common/modal/formTypePage';
import { BottomRowButtons } from '../../../../components/common/modal/bottomRowButtons';

const TaskPage2 = (props) => {
  const {handleSubmit, types, page, previousPage, total} = props;
  return (

    <form onSubmit={handleSubmit}>
      <FormTypePage types={types}/>
      <BottomRowButtons
        page={page}
        previousPage={previousPage}
        total={total}
      />
    </form>
  );
};

export default reduxForm({
  form: 'newTask',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(TaskPage2);
