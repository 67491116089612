import React from 'react'
import {namespace} from "../../util/namespaceNO";
import './exportTaskToPdfButton.css';
import {ALPHABET} from "../../declarations";
import {PictureViewer} from "../common/picture/pictureViewer";
import {FormulaViewer} from "../task/formula/formulaViewer";

export const TaskPdf = (props) => {
  const task = props;
  return (
    <div id="task-set-pdf">
      <div id="task-set-tasks">
        <h3 className="task-set-pdf-header-medium-bold">{task.title}</h3>
        <div className="tasks-in-taskset-container">
          <div className="task-container pdf-task-container">
            <div>
              <h4>{task.description}</h4>
              <br/>
              {
                task.descriptionPicture &&
                <PictureViewer imageId={task.descriptionPicture.id}/>
              }
              <br/>
              {
                task.formula &&
                <FormulaViewer formula={task.formula}/>
              }
              <br/>
            </div>
            {
              task.subTask &&
              <div>
                {
                  task.subTask.map((subTask, i) =>
                    subTask.description &&
                    <div key={i}>
                      <p className="task-set-pdf-sub-task">
                        {ALPHABET[i] + ") " + subTask.description}</p>
                      {subTask.formula &&
                      <FormulaViewer formula={subTask.formula}/>}
                    </div>
                  )}
              </div>
            }
          </div>
        </div>
        <div className="task-link">
          {
            task.linkDescription &&
            task.linkDescription + " - "
          }
          {
            task.link &&
            task.link
          }
        </div>
        <div className="task-tools">
          {
            task.tools &&
            namespace.usefulTools + task.tools
          }
        </div>
        <div className="task-hint">
          {
            task.hint &&
            namespace.suggestionForSolution + task.hint
          }
        </div>
      </div>
      {props.showSolution &&
      <div id="task-set-solution">
        <h4 className="task-set-pdf-header-big">
          {task.title !== undefined ? namespace.solution + ' - ' + task.title : namespace.solution + ':'}
        </h4>
        <br/>
        <div className="tasks-in-taskset-container">
          <div key="container" className="task-container">
            <h4>
              {task.solution}
            </h4>
            {task.subTask && task.subTask.map((subTask, i) =>
              subTask.solution &&
              <p className="lightText task-set-pdf-sub-task" key={i}>{ALPHABET[i] + ") " + subTask.solution}</p>
            )}
          </div>
        </div>
      </div>
      }
    </div>
  )
};