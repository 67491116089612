import * as React from 'react';
import {ALPHABET} from "../../../declarations";
import {PictureWrapper} from "../picture/pictureWrapper";
import './subTask.css';
import {FormulaViewer} from "../../task/formula/formulaViewer";

export const SubTask = (props) => {
  const {subTasks} = props;
  return (
    <div className="subtasks">
      {
        subTasks.map((subTask, i) =>
          <div key={i}>
            <p>{ALPHABET[i] + ") " + subTask.description}</p>
            <PictureWrapper image={subTask.picture}/>
            {subTask.formula &&
            <FormulaViewer formula={subTask.formula}/>
            }
          </div>
        )
      }
    </div>
  )
};