import * as React from 'react';
import {namespace} from "../../../util/namespaceNO";

export const TopBarButtons = (props) => {
  const {onFavouriteClick, isFavourite} = props;
  return (
      <div className="floatRight  toprow-btn" onClick={onFavouriteClick}>
        <i className={'fa  ' + (isFavourite ? 'fa-bookmark' : 'fa-bookmark-o')}/>
        <div>{namespace.addBookmark}</div>
      </div>
  );
};

