import {put} from "../../apiService";

export const createUpdatedTaskSet = (usersTaskSets, selectedid, pickedTasks) => {
  const chosenTaskSet = usersTaskSets.find(taskset => taskset.id === parseInt(selectedid, 10));
  chosenTaskSet.tasks = Array.from(new Set(chosenTaskSet.tasks.concat(pickedTasks)));
  return chosenTaskSet;
};

export const addTasksToTaskSet = (taskSet) => {
  const taskIds = taskSet.tasks.map(task => task.id);
  return put('/taskset/' + taskSet.id + '/tasks', taskIds)
};
