import React from 'react';
import {NewButton} from './newButton';
import {namespace} from "../../util/namespaceNO";

export const AddToMenu = (props) => {
  const {openNewTaskSet, hidden, addToFavourites} = props;

  return (
    <div className={'top-bar-menu  custom-dropdown-menu ' +
    (hidden ? '' : 'custom-dropdown-menu--active')}>
      <div className="modal-click" onClick={openNewTaskSet}>
        <NewButton title={namespace.taskSet} icon={'taskSetIconCorner'}/>
      </div>
      <div className="modal-click" onClick={addToFavourites}>
        <NewButton title={namespace.usersBookmarks} icon={'favouritesIconCorner'}/>
      </div>
    </div>
  );
};

export const NewMenu = (props) => {
  const {openNewTask, openNewTaskSet, openGenerateTaskSet, hidden} = props;

  return (
    <div className={'top-bar-menu  custom-dropdown-menu ' +
    (hidden ? '' : 'custom-dropdown-menu--active')}>
      <div className="modal-click" onClick={openNewTask}>
        <NewButton title={namespace.task} icon={'taskIconCorner'}/>
      </div>
      <div className="modal-click" onClick={openNewTaskSet}>
        <NewButton title={namespace.taskSet} icon={'taskSetIconCorner'}/>
      </div>
      <div className="modal-click" onClick={openGenerateTaskSet}>
        <NewButton title={namespace.generateTaskSet} icon={''}/>
      </div>
    </div>
  );
};
