import {get} from "../../apiService";


/**
 * Fetches all ids of favouritted tasksets and dispatches data to redux store
 */
export const getFavouriteTaskSets = () => {
  return (dispatch, getState) => {
    return get('/taskset/favourite', getState().authentication.access_token)
      .then((json) => dispatch(fetchFavouriteTaskSetsSuccess(json)));
  };
};

const fetchFavouriteTaskSetsSuccess = (payload) => {
  return {
    type: 'FETCH_FAVOURITE_TASK_SETS_SUCCESS',
    payload
  };
};
