import {namespace} from "../../util/namespaceNO";

const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = namespace.required;
  }

  if (values.taskFormat && values.taskFormat.length === 0) {
    errors.taskFormat = namespace.required;
  }

  if (values.selectedDecimals && values.selectedDecimals.length === 0) {
    errors.selectedDecimals = namespace.required;
  }

  if (!values.minimumDigit) {
    errors.minimumDigit = namespace.required;
  }

  if (!values.maximumDigit) {
    errors.maximumDigit = namespace.required;
  }

  if (values.customTaskAmount && values.customTaskAmount > 200) {
    errors.customTaskAmount = namespace.twoHundredOrLess;
  }

  return errors;
};

export default validate;
