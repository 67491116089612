const initialState = true;

const authUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH_USER_SUCCESS':
      return action.payload;
    case 'STORE_TOKEN':
      return action.payload ? action.payload : state;
    default:
      return state;
  }
};

export default authUserReducer;
