/**
 * File to store all values of text displayed in the application
 */

export const namespace = {
  //Common
  taskSet: 'Oppgavesett',
  task: 'Oppgave',
  tasks: 'Oppgaver',
  generateTask: 'Generer oppgave',
  generateTaskSet: 'Generer oppgavesett',
  solution: 'Fasit',
  solutionTaskPreview: 'Fasit: ',
  deleteTask: 'Din oppgave vil bli slettet!',
  deleteTaskSet: 'Ditt oppgavesett vil bli slettet!',
  cancel: 'Avbryt',
  delete: 'Slett',
  grade: 'Klassetrinn',
  format: 'Format',
  preview: 'Forhåndsvisning',
  previous: 'Tilbake',
  generateAgain: 'Generer igjen',
  textAreaPlaceholder: 'Skriv en beskrivende tekst til oppgavesettet...',
  required: 'Obligatorisk',

  // Main page
  usersBookmarks: 'Dine bokmerker',
  addBookmark: 'Bokmerke',

  // Export to PDF
  exportTaskToPdfBtn: 'Eksporter PDF',
  saveAsPdf: 'Lagre som PDF',

  exportTaskToPdfWithSolution: 'Inkluder fasit',
  // Task Preview
  usefulTools: 'Nyttige verktøy: ',
  suggestionForSolution: 'Tips til løsning: ',

  // GenerateTaskForm
  generateTaskBtn: 'Lag oppgaver',
  taskType: 'Type oppgave:',
  generateTaskDescription: 'Generer en oppgave ved å velge minimum og maks verdier, antall oppgaver og oppgave type:',

  // newTaskSet
  newTaskSet: 'Nytt oppgavesett',
  newTaskSetDescription: 'Vi kan lage mengdetreningsoppgavene for deg, du bestemmer hvor mange. Vi hjelper deg å velge ' +
  'relevante regnearter, basert på nivået du underviser i.',

  // GENERATORS Enum
  addition: 'Addisjon',
  subtraction: 'Subtraksjon',
  multiplication: 'Multiplikasjon',
  division: 'Divisjon',
  simpleEquation: 'Enkel ligning',

  // Rapporter
  reportTask: 'Hvorfor vil du rapportere denne oppgaven?',
  reportTaskSet: 'Hvorfor vil du rapportere dette oppgavesettet?',
  report: 'Rapporter',

  // newTaskSetForm
  createYoureOwn: 'Lag din egen',
  generate: 'Generer',
  taskSetName: 'Navn på oppgavesettet',
  taskSetNamePlaceholder: 'Skriv inn navnet på oppgavesettet',
  additionalSubjects: 'Inkluder andre regnearter',
  numberOfDecimals: 'Antall desimaler',
  numberPlaceholder: 'Skriv inn tall...',
  subSubject: 'Underemne',
  numberOfTasks: 'Antall oppgaver:',
  maxValue: 'Høyeste siffer',
  minValue: 'Laveste siffer',
  gradeOneTwo: '1. - 2. trinn',
  gradeThreeFour: '3. - 4. trinn',
  gradeFiveSeven: '5. - 7. trinn',
  gradeEightTen: '8. - 10. trinn',
  gradeEleven: '1. vgs',
  gradeTwelve: '2.vgs',
  gradeThirteen: '3.vgs',
  taskAmount: 'Velg antall slike oppgaver du ønsker å genere',
  customTaskAmount: 'Tilpasset antall',
  generateButton: 'Generer >',
  twoHundredOrLess: 'Må være 200 eller mindre',

  //newTaskSetSecondPage
  withoutSolution: 'Uten fasit',
  includeSolution: 'Inkluder fasit',
  copyToClipBoard: 'Kopier til utklippstavle',
  publish: 'Publiser',

  //dropdown
  filterGrade: "Velg klassetrinn",
  allGrades: "Alle klassetrinn",

  //disclaimer
  disclaimer: 'Ved å opprette og publisere oppgaver er jeg klar over at andre kan bruke disse oppgavene for å ' +
  'gjenbruke og dele kunnskap videre',
  acceptDisclaimer: "OK",
  declineDisclaimer: "TILBAKE",
  emailPrompt: 'Du valgte å ikke dele epostadressen. eMatte trenger å vite din epost adresse for å opprette en bruker for deg.',
  deleteUserPrompt: 'Du er i ferd med å slette din bruker. Dette innebærer at du mister din kobling til oppgavene du har laget, samt at alle dine kommentarer og vurderinger blir slettet. Vil du fortsette?',

  loggedOut: "Du har nå logget ut av Single Sign-on sesjonen. Vennligst lukk nettleseren.",
};
