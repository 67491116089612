import React from 'react';

const FilterDifficulty = (props) => {
  const {
    label,
    onChange
  } = props;
  return (
    <div>
      <label className="filter-label">{label}</label>
      <div className='filter-checkbox__row'>
        <div className="checkbox  checkbox-modal--difficulty">
          <label className="checkbox--label">
            <input value="1" type="checkbox" onClick={onChange}/>
            <span>1</span>
          </label>
        </div>
        <div className="checkbox  checkbox-modal--difficulty  checkbox-filter__difficulty--paddingright"
        >
          <label className="checkbox--label">
            <input value="2" type="checkbox" onClick={onChange}/>
            <span>2</span>
          </label>
        </div>
        <div className="checkbox  checkbox-modal--difficulty  checkbox-filter__difficulty--paddingright"
        >
          <label className="checkbox--label">
            <input value="3" type="checkbox" onClick={onChange}/>
            <span>3</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default FilterDifficulty;
