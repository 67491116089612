import React from 'react';
import FontAwesome from 'react-fontawesome'

import './options.css'
import OptionsList from "./optionsList";

export const OptionsButton = props => {

  return (
    <div className="modal-topbar-button__container" id="export-pdf-container">
      <div className="floatRight  toprow-btn">
        <a onClick={(e) => {
          props.optionProps.moreOptionsModal ?
            props.optionProps.hideMoreOptionModal() :
            props.optionProps.showMoreOptionModal();
          e.stopPropagation()
        }}>
          <FontAwesome id="tripleDotMenu" name="ellipsis-h"/>
        </a>
      </div>
      {
        props.optionProps.moreOptionsModal &&
        < OptionsList
          props={props.optionProps}
        />
      }
    </div>
  );
};



