import React from 'react';
import './radioButton.css';

export class Option extends React.Component {

  componentDidMount() {
    if (this.props.index === 0) {
      this.check()
    }
  }

  check = () => {
    this.props.handleChange(this.props.index);
  };

  render() {
    return (
      <div className={"option minWidth" + this.props.minWidth + " " + (this.props.isChecked ? "option-checked" : "option-unchecked")}>
        <div onClick={this.check}>
          {this.props.displayName}
        </div>
      </div>
    )
  }
}
