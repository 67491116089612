import React from 'react';
import './customTaskAmount.css';

export default class CustomTaskAmount extends React.Component {
  componentWillMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  handleChange = (value) => {
    this.props.input.onChange(value);
    this.props.unSelectRadioButton();
  };

  clearInput = () => {
    this.props.input.onChange(null)
  };

  render() {
    return (
      <div className="custom-task-amount">
        <input
          {...this.props.input}
          placeholder={this.props.placeholder}
          type={"number"}
          autoComplete={'off'}
          className="custom-task-amount-input"
          defaultValue={this.props.defaultValue}
          onChange={e => this.handleChange(e.target.value)}
        />
        {
          this.props.meta.touched && this.props.meta.error &&
          <span className="error-message-label">{this.props.meta.error}</span>
        }
      </div>
    )
  }
}
