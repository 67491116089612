import React, { Component } from 'react';
import Spinner from "react-spinkit";
import fetch from "isomorphic-fetch";
import queryString from 'query-string';

class OAuthSpinner extends Component {
  componentDidMount() {
    this.getToken()
  }

  getToken = () => {
    const param = queryString.parse(this.props.location.search);
    const pathname = this.props.location.pathname;

    fetch(`/api${pathname}?${queryString.stringify(param)}`, {
      method : 'GET',
      credentials : 'include'
    }).then(res => window.location = res.url)
  };

  render() {
    return (
      <div style={{ margin : "0 auto", height : "100vh" }}>
        <Spinner
          name="circle"
          fadeIn="none"
          style={{ position : 'absolute', top : '1px', left : '1px', right : '1px', bottom : '1px', margin : 'auto' }}
        />
      </div>
    );
  }
}

export default OAuthSpinner;
