import React from 'react';
import {deletingUserAlert} from "./common/alerts/deletingUserAlert";
import {deleteUser} from '../oauthConfig';

export const deleteUserPrompt = (props) => {

  const onConfirm = () => deleteUser();
  const onCancel = () => window.location = window.location.origin +'/home';

  return(
    <div>
      {deletingUserAlert(onConfirm, onCancel)}
    </div>
  );
};
