import * as React from 'react';
import {Field} from 'redux-form';
import textAreaInput from "./textArea";
import {Picture} from "../../../../picture/picture";
import hidden from './hidden'
import {FormulaForm} from "../../../../../components/task/formula/formulaForm";

export const SubTaskGenerator = (props) => {
  const {alphabet, index, change} = props;

  const onPictureAdded = (imageId) => {
    change('newTask', 'subtask_picture' + index, imageId);
  };

  const onFormulaAdded = (formula) => {
    change('newTask', 'subtask_formula' + index, formula)
  };

  return (
    <div className="columns  subtask__container" key={index}>
      <div className="column  is-three-fifths">
        <Field
          name={'subtask_description' + index}
          type="text"
          component={textAreaInput}
          placeholder={alphabet + ') '}
        />
        <Field
          name={'subtask_picture' + index}
          component={hidden}
        />
        <Picture
          onPictureAdded={onPictureAdded}
          inputId={"subtask" + index}
        />
        <Field
          name={'subtask_formula' + index}
          component={hidden}
        />
        <FormulaForm
          onFormulaAdded={onFormulaAdded}
        />
      </div>
      <div className="column  is-two-fifths">
        <Field
          name={'subtask_solution' + index}
          type="text"
          component={textAreaInput}
          placeholder={alphabet + ') '}
        />
      </div>
    </div>
  );
};
