import React from 'react';

const selectTaskSet = (props) => (
  <div>
    <div className="select-input">
      <select{...props.input} className="select-input--task-set">
        <option>Velg oppgavesett</option>
        {
          props.taskSets.map((taskSet, i) =>
            <option key={i} value={taskSet.id}>{taskSet.title}</option>
          )
        }
      </select>
    </div>
    {props.meta.touched && props.meta.submitFailed && <span className="error-message-label">{props.meta.error}</span>}
  </div>
);

export default selectTaskSet;