import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {namespace} from "../../../util/namespaceNO";

export const emailRequiredAlert = (onConfirm) => {
  confirmAlert({
    title: '',
    message: namespace.emailPrompt,
    confirmLabel: namespace.acceptDisclaimer,
    cancelLabel: '',
    onConfirm: () => {
      onConfirm();
    }
  });
};
