/**
 *
 * Creates a TaskEntity to be inserted into database
 *
 * @param taskDTO the task to be posted to the backend
 * @param values the values from the redux form
 * @param props application wide properties
 * @return Object
 */
export const mapFormToTaskDTO = (taskDTO, values, props) => {
  taskDTO.difficulty = values.difficulty;
  taskDTO.description = values.description;
  taskDTO.solution = values.solution_description;
  taskDTO.hint = values.hint;
  taskDTO.isPublished = false;
  taskDTO.isTextTask = true;
  taskDTO.img = values.img;
  taskDTO.createdAt = new Date();
  taskDTO.title = values.title;
  taskDTO.link = values.link;
  taskDTO.linkDescription = values.linkDescription;
  taskDTO.tools = values.tools;
  taskDTO.types = [];
  taskDTO.grades = [];
  taskDTO.topics = [];

  values.grade.map(grade => (
    taskDTO.grades.push({id: grade, grade: grade})
  ));

  for (let key in values.types) {
    props.allTypes.map((type) => {
      if (key === type.type && values.types[key]) {
        return taskDTO.types.push(type);
      }
      else {
        return taskDTO;
      }
    });
  }

  for (let key in values.topics) {
    props.allTopics.map((topic) => {
      if (key === topic.topic && values.topics[key]) {
        return taskDTO.topics.push(topic);
      }
      else {
        return taskDTO;
      }
    });
  }

  taskDTO.subTask = [];
  let index = 0;
  for (let key in values) {
    if (key.includes('subtask_description' + index) ||
      key.includes('subtask_solution' + index) ||
      key.includes('subtask_picture' + index) ||
      key.includes('subtask_formula' + index)) {
      taskDTO.subTask.push({
        description: values['subtask_description' + index] ? values['subtask_description' + index] : 'Tom oppgave',
        solution: values['subtask_solution' + index] ? values['subtask_solution' + index] : 'Ingen fasit',
        picture: values['subtask_picture' + index] ? {id: values['subtask_picture' + index]} : null,
        formula: values['subtask_formula' + index] ? values['subtask_formula' + index] : null
      });
      index++;
    }
  }

  return taskDTO;
};
