import React, {Component} from 'react';
import './newTaskSetFirstPage.css';
import {namespace} from "../../util/namespaceNO";
import icon from '../../resources/customIcons/addToPlusGreen.svg';
import {reduxForm} from "redux-form";
import Field from "redux-form/es/Field";
import InputFieldWithTitle from "../../components/common/renderFields/inputFieldWithTitle/inputFieldWithTitle";
import {GenerateTaskSet} from "../../components/generateTaskSet/generateTaskSet";
import validate from "./validate";

class NewTaskSetFirstPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isGradeSelected: false,
    }
  }

  render() {
    return (
      <div className="new-task-set">
        <div className="new-task-set-text">
          <div className="new-task-set__title-container">
            <img src={icon} alt="add to task set icon"/>
            <h1 className="new-task-set__title">{namespace.newTaskSet}</h1>
          </div>
          <p className="new-task-set__description">{namespace.newTaskSetDescription}</p>
          <div className="new-task-set__title-input">
            <Field
              name="title"
              type="text"
              component={InputFieldWithTitle}
              label="Navn på oppgavesettet"
              placeholder="Skriv inn navnet på oppgavesettet"
              autoFocus={true}
            />
          </div>
        </div>
        <GenerateTaskSet
          props={this.props}
          generateTaskSetDTO={this.props.generateTaskSet}
        />
      </div>
    )
  }
}

export default reduxForm({
  form: 'generateTaskSet',
  destroyOnUnmount: false,
  touchOnChange: true,
  forceUnregisterOnUnmount: true,
  validate
})(NewTaskSetFirstPage)
