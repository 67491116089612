import * as React from 'react';
import { reduxForm } from 'redux-form';
import { TaskViewer } from '../../../../components/common/modal/taskViewer';
import { TaskProperties } from '../../../../components/common/modal/taskProperties';
import { BottomRowButtons } from '../../../../components/common/modal/bottomRowButtons';

let TaskPage5 = (props) => {
  const {handleSubmit, task, type, topic, page, previousPage, total} = props;
  return (
    <form onSubmit={handleSubmit}>
      <TaskProperties {...task} type={type} topic={topic}/>
      <TaskViewer task={task}/>
      <BottomRowButtons
        page={page}
        previousPage={previousPage}
        total={total}
      />
    </form>
  );
};

export default reduxForm({
  form: 'newTask',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(TaskPage5);
