import {randomNumberGenerator} from "../randomNumberGenerator";

export const generateTask = (userInput) => {
  let values = [];
  let topics = [userInput.mainSubject.description()];
  let randomReverseOfExpression = [true, false];
  for (let i = 0; i < userInput.format; i++) {
    let value = randomNumberGenerator(userInput.minimumDigit, userInput.maximumDigit, userInput.numberOfDecimals);
    values.push(value);
  }

  let expression = [values[0], userInput.mainSubject.operator(), values[1]];
  for (let i = 2; i < values.length; i++) {
    let additionalSubject;
    if (userInput.additionalSubjects.length > 0) {
      let randomAdditionalSubject = randomNumberGenerator(0, userInput.additionalSubjects.length);
      additionalSubject = userInput.additionalSubjects[randomAdditionalSubject].value.operator();
      topics.push(userInput.additionalSubjects[randomAdditionalSubject].value.description());
    } else {
      additionalSubject = userInput.mainSubject.operator();
    }
    expression.push(additionalSubject, values[i])
  }

  if (randomReverseOfExpression[randomNumberGenerator(0, randomReverseOfExpression.length)]) {
    expression.reverse()
  }

  let uniqueTopics = removeDuplicatesFromArray(topics);

  return userInput.mainSubject.evaluateExpression(expression, uniqueTopics)
};

function removeDuplicatesFromArray(array) {
  return Array.from(new Set(array));
}
