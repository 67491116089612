import * as React from 'react';
import { TaskList } from '../taskList/taskList';
import SearchBar from '../searchbar/searchBar';
import Filters from '../../../containers/filter/filters';
import { SortDropdown } from '../../topBar/sortDropdown';

export const TaskPicker = (props) => {
  const {
    chooseTasks,
    tasks,
    publishedTasks,
    showMore,
    types,
    topics,
    filter,
    sorting,
    hiddenSort,
    handleSortingChange,
    handleSortDropdownClick,
    loaded,
    maxItems,
    searchChange,
    isSelected,
    hiddenFilter,
    toggleShowMoreFilters
  } = props;
  return (
    <div className="task-viewer__container  task-picker__container">
      <p className="task-picker__title strongText">
        Legg til oppgaver
      </p>
      <div className="columns  task-picker__topbar">
        <div className="task-picker__searchbar  column  is-two-thirds">
          <SearchBar
            searchChange={searchChange}
          />
        </div>
        <div className="column is-one-third">
          <div className="task-picker-button" onClick={chooseTasks}>
            Legg til valgte
          </div>
        </div>
      </div>
      <div className="columns  task-picker__filterbar">
        <div className="task-picker__filter  column  is-full">
          <div className="sort-dropdown  sort-dropdown--task-picker  has-text-right">
            <label
              className={'dropdown-button  dropdown-button--sort  ' +
              (hiddenSort ? 'dropdown-button--up' : 'dropdown-button--down')}
              onClick={handleSortDropdownClick}>{sorting}
            </label>
            <div className="sort__dropdown--options--task-picker">
              <div className={'custom-dropdown-menu-wrapper ' +
              (hiddenSort ? '' : 'custom-dropdown-menu-wrapper--active')}>
                <SortDropdown
                  hidden={hiddenSort}
                  onClick={handleSortingChange}
                />
              </div>
            </div>
          </div>
          <div className="show-more__filters  show-more__filters--modal">
            <label
              className={'dropdown-button  dropdown-button--filter  dropdown-button--filter-modal ' +
              (hiddenFilter ? 'dropdown-button--down' : 'dropdown-button--up')}
              onClick={toggleShowMoreFilters}>
              {hiddenFilter ? 'Skjul filtre' : 'Vis filtre'}
            </label>
          </div>
        </div>
      </div>
      <Filters
        types={types}
        topics={topics}
        isModalFilter={true}
        filter={filter}
        hiddenFilters={hiddenFilter}
      />
      <TaskList
        tasks={tasks}
        publishedTasks={publishedTasks}
        isTaskPicker={true}
        loaded={loaded}
        maxItems={maxItems}
        showMore={showMore}
        isSelected={isSelected}
      />
    </div>
  );

};
