import * as React from 'react';

export const TaskListEmpty = (props) => {
  const {message} = props;
  return (
    <div>
      <div className="empty-box">
        <div className="empty-box__icon  has-text-centered">
          <img className="box-icon"
               src={require('../../../resources/images/emptyBox.svg')}
               alt="Ikon for ingen oppgaver"/>
        </div>
        <div className="empty-box__message  has-text-centered">
          <h1 className="lightText">Her var det ingenting</h1>
          <p className="lightText">{message}</p>
        </div>
      </div>
    </div>
  )
};


