import React from 'react';
import './taskFormat.css'
import {TaskFormatElement} from "./taskFormatElement";
import {namespace} from "../../util/namespaceNO";

export default class TaskFormat extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: [],
    };
  }

  handleChange = (value) => {
    let selected = this.state.selectedIndex.slice();
    if (this.checkIfArrayHasValue(value.index)) {
      const selectedElementIndex = selected.map(e => {
        return e.index;
      }).indexOf(value.index);
      selected.splice(selectedElementIndex, 1)
    } else {
      selected.push(value);
    }
    this.setState({
        selectedIndex: selected,
      }, this.props.input.onChange(selected),
      this.props.callback(selected)
    );
  };

  checkIfArrayHasValue = (i) => {
    for (let subject  of this.state.selectedIndex) {
      if (subject.index === i) {
        return true
      }
    }
    return false
  };

  render() {
    return (
      <div>
        <div className="format-header-container">
        <h1 className="format-header">{namespace.format}</h1>
        {
          this.state.selectedIndex.length === 0 && this.props.meta.error &&
          <span className="error-message-label">{this.props.meta.error}</span>
        }
        </div>
        <div>
        {this.props.numberOfFormatFields.map((value) =>
          <TaskFormatElement
            handleChange={this.handleChange}
            value={value}
            key={value.format}
          />
        )}
        </div>
      </div>
    )
  }
}

