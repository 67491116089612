import { Sortings } from '../../declarations';

/**
 * Sort the list taken in as parameter and sorts it on sorting
 * sent in as parameters
 *
 * @param list
 * @param sorting
 * @return Array
 */
export const sortList = (list, sorting) => {
  switch (sorting) {
    case Sortings.BOOKMARKED:
      return sortBookmarked(list);
    case Sortings.RATED:
      return sortRated(list);
    case Sortings.NEWEST:
      return sortNewest(list);
    case Sortings.OLDEST:
      return sortOldest(list);
    default:
      return list;
  }
};

const sortBookmarked = (list) => {
  return list.sort((a, b) => parseFloat(b.favourites) - parseFloat(a.favourites));
};

const sortRated = (list) => {
  return list.sort((a,b) => parseFloat(b.totalRating) - parseFloat(a.totalRating));
};

const sortNewest = (list) => {
  return list.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
};

const sortOldest = (list) => {
  return list.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
};

