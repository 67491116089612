import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getPublishedTaskSets} from "../../actions/taskSet/fetchPublishedTaskSets";
import TaskSetPreview from '../../components/taskSet/taskSetPreview';
import {deleteEntity, get, post} from "../../apiService";
import Swal from "sweetalert2";
import {getFavouriteTaskSets} from "../../actions/taskSet/fetchFavouriteTaskSets";
import {getLoggedInUser} from "../../actions/user/fetchLoggedInUserInfo";
import {toggleOptionModalOff, toggleOptionModalOn} from "../../actions/common/showOptions";
import {toggleReportModalOff, toggleReportModalOn} from "../../actions/common/showReport";

class TaskSetDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      taskSet: null
    }
  }

  componentDidMount() {
    this.getInitData().then(() => {
      this.setState({
        taskSet: this.props.publishedTaskSets.find(taskSet => taskSet.id === parseInt(this.props.match.params.id, 10))
      });
      if (!this.state.taskSet) {
        this.props.history.push("/home");
        return null;
      }
    })
  }

  async getInitData() {
    await this.props.getPublishedTaskSets();
    await this.props.getFavouriteTaskSets();
    await this.props.getLoggedInUser();
  }

  favouriteClick = (e) => {
    if (this.props.favouriteTaskSets.includes(this.state.taskSet.id)) {
      this.removeFavourite(this.state.taskSet.id).then(() => this.props.getFavouriteTaskSets());
    }
    else {
      this.addToFavourites(this.state.taskSet.id).then(() => this.props.getFavouriteTaskSets());
    }
    e.stopPropagation();
  };

  async removeFavourite(taskSet) {
    await deleteEntity('/taskset/favourite/' + taskSet);
  }

  async addToFavourites(taskSet) {
    await post('/taskset/favourite/' + taskSet)
  }

  addComment = (comment) => {
    post('/taskset/' + this.state.taskSet.id + '/comment', comment)
      .then(() => get('/taskset/' + this.state.taskSet.id, this.props.token))
      .then((updatedTaskSet) => this.setState({taskSet: updatedTaskSet}));
  };

  taskSetRatingClick = (rating) => {
    this.addRating(rating, this.state.taskSet.id)
      .then(() => get('/taskset/' + this.state.taskSet.id, this.props.token))
      .then((updatedTaskSet) => this.setState({taskSet: updatedTaskSet}))
      .then(() => Swal({
        position: 'bottom',
        type: 'success',
        title: 'Din vurdering er registrert',
        showConfirmButton: false,
        timer: 1500,
        toast: true
      }));
  };

  async addRating(rating, id) {
    await post('/taskset/rating/' + rating + '/' + id);
  };

  exitModal = () => {
    this.props.history.push("/home");
  };

  render() {
    if (!this.state.taskSet) {
      return null
    }

    return (
      <TaskSetPreview
        taskSet={this.state.taskSet}
        onFavouriteClick={this.favouriteClick}
        exit={this.exitModal}
        isFavourite={this.props.favouriteTaskSets.includes(this.state.taskSet.id)}
        taskSetRatingClick={this.taskSetRatingClick}
        addComment={this.addComment}
        showMoreOptionModal={this.props.showMoreOptionModal}
        hideMoreOptionModal={this.props.hideMoreOptionModal}
        hideReportModal={this.props.hideReportModal}
        moreOptionsModal={this.props.moreOptionsModal}
        loggedInUserId={this.props.loggedInUser.id}
        showReportModal={this.props.showReportModal}
        report={this.props.report}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    publishedTaskSets: state.publishedTaskSets,
    favouriteTaskSets: state.favouriteTaskSets,
    loggedInUser: state.loggedInUser,
    moreOptionsModal: state.moreOptions,
    report: state.report,
    token: state.authentication.access_token,

  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getPublishedTaskSets: getPublishedTaskSets,
    getFavouriteTaskSets: getFavouriteTaskSets,
    getLoggedInUser: getLoggedInUser,
    showMoreOptionModal: toggleOptionModalOn,
    hideMoreOptionModal: toggleOptionModalOff,
    hideReportModal: toggleReportModalOff,
    showReportModal: toggleReportModalOn
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaskSetDetails);
