import { get } from '../../apiService'
import { loggedInUserTypes } from "../../types/user/loggedInUser";

/**
 * Fetches logged in user information
 */
export const getLoggedInUser = () => {
  return (dispatch, getState) => {
    return get('/user', getState().authentication.access_token)
      .then((response) => dispatch({ type : loggedInUserTypes.success, payload : response }));
  };
};
