import React from "react";
import "./taskAmount.css";
import {Field} from "redux-form";
import CustomTaskAmount from "../../../components/common/renderFields/customTaskAmount/customTaskAmount";
import TaskAmountRadioButton from "../../../components/common/renderFields/radioButton/taskAmountRadioButton";
import {namespace} from "../../../util/namespaceNO";

export default class TaskAmount extends React.Component {
  unSelectRadioButton = () => {
    this.taskAmountRadioButton.unSelect();
  };

  clearCustomInput = () => {
    this.customTaskAmount.clearInput();
  };

  render() {
    const taskAmountValues = [
      {name: 10, displayName: 10},
      {name: 15, displayName: 15},
      {name: 20, displayName: 20},
      {name: 25, displayName: 25},
      {name: 30, displayName: 30},
      {name: 50, displayName: 50},
      {name: 75, displayName: 75},
      {name: 100, displayName: 100},
      {name: 200, displayName: 200}
    ];
    return (
      <div className="task-amount">
        <Field
          name="taskAmount"
          component={TaskAmountRadioButton}
          label={namespace.taskAmount}
          values={taskAmountValues}
          minWidth={80}
          clearCustomInput={this.clearCustomInput}
          onRef={ref => (this.taskAmountRadioButton = ref)}
        />
        <Field
          component={CustomTaskAmount}
          name="customTaskAmount"
          placeholder={namespace.customTaskAmount}
          unSelectRadioButton={this.unSelectRadioButton}
          onRef={ref => (this.customTaskAmount = ref)}
        />
      </div>
    )
  }
}