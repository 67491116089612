import {get} from "../../apiService";

/**
 * Fetches all published tasksets from database and dispatches data to redux store
 */
export const getPublishedTaskSets = () => {
  return (dispatch, getState) => {
    return get('/taskset/published', getState().authentication.access_token).then((json) => dispatch(fetchPublishedTaskSetsSuccess(json)));
  };
};

const fetchPublishedTaskSetsSuccess = (payload) => {
  return {
    type: 'FETCH_PUBLISHED_TASK_SETS_SUCCESS',
    payload
  };
};
