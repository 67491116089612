import React from 'react';

const textInput = (props) => (
  <div>
    {
      props.label &&
      <span className="title-input-label  title-input-label--modal">{props.label}</span>
    }
    <div>
      <input
        {...props.input}
        placeholder={props.placeholder}
        type={props.type}
        className="text-input"
        autoComplete={'off'}
      />
      {props.meta.touched && props.meta.error && <span>{props.meta.error}</span>}
    </div>
  </div>
);

export default textInput;
