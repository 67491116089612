let pickedTaskSets = [];

const pickGlobalTaskSetReducer = (state = [], action) => {

  switch (action.type) {
    case 'ADD_GLOBAL_TASK_SET':
      pickedTaskSets.push(action.payload);
      return pickedTaskSets;
    case 'REMOVE_GLOBAL_TASK_SET':
      pickedTaskSets = pickedTaskSets.filter((taskSet) => {
        return taskSet.id !== action.payload.id;
      });
      return pickedTaskSets;
    case 'RESET_GLOBAL_TASK_SET':
      pickedTaskSets = [];
      return pickedTaskSets;
    default:
      return state;
  }
};

export default pickGlobalTaskSetReducer;
