import {get} from "../../apiService";

/**
 * Fetches all taskSets that belongs to logged in user and dispatches the data to redux store
 */
export const getUserTaskSets = () => {
  return (dispatch, getState) => {
    return get('/taskset/user', getState().authentication.access_token).then((json) => dispatch(fetchUserTaskSetsSuccess(json)));
  };
};

const fetchUserTaskSetsSuccess = (payload) => {
  return {
    type: 'FETCH_USER_TASK_SETS_SUCCESS',
    payload
  };
};
